.bike-feature-outer-container {
  width: 100%;
  overflow: hidden;
  padding: 10px 0 0 0;
}
.bike-feature-outer-container .bike-feature-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% + 115px);
  flex-wrap: wrap;
}
.bike-feature-outer-container .bike-feature-container .bike-feature-outer {
  width: 33.33%;
  padding: 0 0.3% 10px 0.3%;
  transform: skewX(-15deg);
  -webkit-transform: skewX(-15deg);
  -moz-transform: skewX(-15deg);
  -ms-transform: skewX(-15deg);
  -o-transform: skewX(-15deg);
}
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature {
  position: relative;
  padding-bottom: 56.25%;
  display: flex;
  overflow: hidden;
  cursor: pointer;
}
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__bg {
  width: 117%;
  height: auto;
  position: absolute;
  left: -8%;
  transform: skewX(15deg);
  -webkit-transform: skewX(15deg);
  -moz-transform: skewX(15deg);
  -ms-transform: skewX(15deg);
  -o-transform: skewX(15deg);
}
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title {
  color: white;
  position: absolute;
  text-transform: uppercase;
  z-index: 1;
  right: 90px;
  top: 30px;
  bottom: 50px;
  left: 90px;
  display: flex;
}
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title span,
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title h1,
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title h2,
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title h3,
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title h4,
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title h5,
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title h6,
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title p {
  text-transform: uppercase;
}
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title span {
  font-size: 30px;
  font-family: SuzukiHeadline;
  max-width: 300px;
}
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__more {
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__more .more {
  background-color: #005aab;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
  cursor: pointer;
  padding: 7px 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__more .more:hover {
  background-color: #0067c4;
}
.bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature:hover::after {
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(3n + 1) {
  margin-left: -114px;
}
.bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(3n + 1) .bike-feature__more {
  padding-left: 13.5625%;
}
@media screen and (max-width: 1700px) {
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(3n + 1) .bike-feature__more {
    padding-left: 14.9%;
  }
}
@media screen and (max-width: 1500px) {
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(3n + 1) .bike-feature__more {
    padding-left: 16%;
  }
}
.bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(3n) .bike-feature__more {
  padding-right: 0.5%;
}
@media screen and (max-width: 1700px) {
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(3n) .bike-feature__more {
    padding-right: 1.5%;
  }
}
@media screen and (max-width: 1500px) {
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(3n) .bike-feature__more {
    padding-right: 2.5%;
  }
}
@media screen and (max-width: 1400px) {
  .bike-feature-outer-container .bike-feature-container {
    width: calc(100% + 130px);
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer {
    width: 50%;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(3n + 1) {
    margin-left: unset;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(3n + 1) .bike-feature__more {
    padding-left: unset;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(3n) {
    margin-right: unset;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(3n) .bike-feature__more {
    padding-right: unset;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(2n + 1) {
    margin-left: -126px;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(2n + 1) .bike-feature__more {
    padding-left: 14.2%;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(2n) .bike-feature__more {
    padding-right: 1%;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title {
    left: 110px;
    right: 110px;
  }
}
@media screen and (max-width: 1200px) {
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(2n + 1) .bike-feature__more {
    padding-left: 15.3%;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(2n) .bike-feature__more {
    padding-right: 2.5%;
  }
}
@media screen and (max-width: 1024px) {
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(2n + 1) .bike-feature__more {
    padding-left: 16.7%;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(2n) .bike-feature__more {
    padding-right: 4%;
  }
}
@media screen and (max-width: 920px) {
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(2n + 1) .bike-feature__more {
    padding-left: 18%;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(2n) .bike-feature__more {
    padding-right: 5.8%;
  }
}
@media screen and (max-width: 768px) {
  .bike-feature-outer-container .bike-feature-container {
    width: 100%;
    padding: 10px 0;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer {
    width: 100%;
    padding: 0px;
    transform: skewX(0deg);
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(2n + 1) {
    margin-left: 0;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(2n + 1) .bike-feature__more {
    padding-left: 0;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(2n) {
    margin-right: 0;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer:nth-of-type(2n) .bike-feature__more {
    padding-right: 0;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__bg {
    transform: skewX(0deg);
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title {
    top: 30px;
    left: 15px;
    bottom: 40px;
    right: 15px;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title span,
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title h1,
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title h2,
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title h3,
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title h4,
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title h5,
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title h6,
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__title p {
    font-family: SuzukiPROHeadline !important;
    font-size: 40px !important;
    line-height: 45px !important;
  }
  .bike-feature-outer-container .bike-feature-container .bike-feature-outer .bike-feature__more {
    text-align: right !important;
    transform: skewX(-15deg);
    -webkit-transform: skewX(-15deg);
    -moz-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    -o-transform: skewX(-15deg);
    width: calc(100% + 5px);
  }
}
.bike-feature-element-fs {
  background-color: black;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  z-index: 1000002;
  font-family: SuzukiPRORegular, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  overflow-y: auto;
}
.bike-feature-element-fs__close {
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 1;
}
.bike-feature-element-fs__close .close-icon {
  width: 30px;
  height: 30px;
  background-color: white;
  display: block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
}
.bike-feature-element-fs__close .close-icon::after {
  position: relative;
  content: '\d7';
  font-weight: bold;
  color: rgba(0, 49, 69, 0.8);
  font-size: 40px;
  right: -3.5px;
  top: -13px;
}
.bike-feature-element-fs__close .close-icon:hover {
  background-color: #eeeeee;
}
.bike-feature-element-fs__img-container {
  position: absolute;
  top: 40px;
  bottom: 40px;
  right: 40px;
  left: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
}
.bike-feature-element-fs__img-container img {
  max-height: 100%;
  width: auto;
  max-width: 100%;
  height: auto;
}
.bike-feature-element-fs__content-box {
  position: absolute;
  left: 40px;
  bottom: 40px;
  right: 40px;
  background-color: rgba(0, 49, 69, 0.9);
  max-width: 700px;
  padding: 40px;
  max-height: 90vh;
  overflow-y: auto;
}
.bike-feature-element-fs__content-box .__title {
  font-size: 60px;
  font-family: SuzukiHeadline;
  text-transform: uppercase;
  padding-bottom: 20px;
}
.bike-feature-element-fs__content-box .__content {
  font-size: 16px;
}
@media screen and (max-width: 1200px) {
  .bike-feature-element-fs {
    min-height: 90vh;
    height: calc(100vh - 4rem);
    top: 4rem;
  }
}
@media screen and (max-width: 576px) {
  .bike-feature-element-fs {
    flex-direction: column;
    display: flex;
  }
  .bike-feature-element-fs__close {
    right: 10px;
    top: 20px;
  }
  .bike-feature-element-fs__img-container {
    margin-top: 80px;
    min-height: 200px;
    position: unset;
    margin-bottom: 20px;
    align-items: flex-start;
  }
  .bike-feature-element-fs__content-box {
    position: unset;
    padding: 40px 20px;
  }
  .bike-feature-element-fs__content-box .__title {
    font-size: 40px;
    line-height: 45px;
  }
}
