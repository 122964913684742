.finishing-form-view input[type='checkbox'] {
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid white;
  padding: 9px;
  margin: 1rem;
}
.finishing-form-view input[type='checkbox']:checked {
  background-color: #e40012;
  color: #e40012;
}
.finishing-form-view .select-option {
  position: relative;
}
.finishing-form-view .select-option select {
  font-size: 1.111rem;
  padding: 0.5rem 2rem;
}
.finishing-form-view .select-option:after {
  position: absolute;
  right: 2rem;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  bottom: 0;
  content: '\f078';
  width: 1rem;
  color: white;
  z-index: 0;
  font-family: FontAwesome;
  pointer-events: none;
}
.finishing-form-view .buttons {
  display: flex;
  justify-content: space-around;
  max-width: 100%;
  width: 100%;
}
.finishing-form-view .buttons .button {
  width: 30%;
  white-space: nowrap;
  margin: 0 auto;
  padding: 1rem 3rem;
  background: #004d9f;
  border: none !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  position: relative;
  font-size: 1.111rem;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  box-shadow: 5px 5px 0px 1px rgba(218, 41, 28, 0.2);
  transition: linear 0.3s;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: white;
  text-decoration: none;
}
.finishing-form-view .buttons .button:hover {
  background: #e40012;
  top: 5px;
  left: 5px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.finishing-form-view .buttons .button.inverse {
  background: #e40012;
  box-shadow: 5px 5px 0px 1px rgba(0, 77, 159, 0.2);
}
.finishing-form-view .buttons .button.inverse:hover {
  background: #004d9f;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.finishing-form-view label {
  display: flex;
  flex-direction: row-reverse;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.finishing-form-view .checkbox {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.finishing-form-view .checkbox span {
  color: white !important;
  font-family: SuzukiPROHeadline;
  font-size: 1.111rem;
}
.finishing-form-view .checkbox a {
  text-decoration: underline;
  color: white !important;
  font-family: SuzukiPROHeadline;
  font-size: 1.111rem;
}
.finishing-form-view .bike-data {
  margin-top: 2rem;
}
.finishing-form-view .bike-data-logo {
  max-width: 48%;
  margin-bottom: 1rem;
  width: 100%;
}
.finishing-form-view .bike-data-logo img {
  max-width: 100%;
}
.finishing-form-view .bike-data-image-and-price {
  display: flex;
}
.finishing-form-view .bike-data .bike-image {
  max-width: 50%;
  width: 100%;
}
.finishing-form-view .bike-data .bike-image img {
  max-width: 100%;
  width: 100%;
}
.finishing-form-view .bike-data .bike-price {
  display: flex;
  width: 100%;
  max-width: 50%;
  justify-content: center;
  align-items: center;
  font-family: SuzukiPROHeadline;
  font-size: 5rem;
}
.finishing-form-view .separator {
  margin: 2rem 0 1rem 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  margin-bottom: 2rem;
  overflow: hidden;
}
.finishing-form-view .separator:after {
  content: '';
  width: 300%;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  display: block;
  position: relative;
}
.finishing-form-view .form .top-text {
  font-size: 1rem;
  text-align: justify;
  margin-bottom: 2rem;
}
@media screen and (max-width: 600px) {
  .finishing-form-view .bike-data-image-and-price {
    flex-direction: column;
  }
  .finishing-form-view .bike-data-logo {
    max-width: 95%;
    margin: 0 auto;
  }
  .finishing-form-view .bike-data .bike-image {
    max-width: 100%;
  }
  .finishing-form-view .bike-data .bike-price {
    max-width: 100%;
  }
  .finishing-form-view button {
    white-space: initial !important;
  }
}
