.bike-accessories-slider {
  background: #002c48;
  padding: 20px 0 100px 0;
  position: relative;
}
.bike-accessories-slider__title {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.bike-accessories-slider__title p.lead {
  font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 50px;
  margin-right: 40px;
}
@media (max-width: 768px) {
  .bike-accessories-slider__title {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bike-accessories-slider__title p.lead {
    margin-right: 0;
  }
}
.bike-accessories-slider .owl-carousel .owl-stage {
  display: flex;
}
.bike-accessories-slider .eli-shadow {
  content: '';
  height: 60%;
  width: 90%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  position: absolute;
  border-radius: 50%;
  background: #004d9f;
  filter: blur(100px);
}
.bike-accessories-slider .owl-prev:after {
  bottom: -5rem;
  transform: translateX(-50%);
  left: 45%;
  position: absolute;
  content: '\f177';
  display: block;
  color: white;
  font-family: FontAwesome;
  font-size: 1.5rem;
}
.bike-accessories-slider .owl-next:after {
  bottom: -5rem;
  transform: translateX(-50%);
  right: 45%;
  position: absolute;
  content: '\f178 ';
  display: block;
  color: white;
  font-family: FontAwesome;
  font-size: 1.5rem;
}
.bike-accessories-slider__item {
  background: transparent;
  height: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
  margin: 0 15px;
  position: relative;
  margin-bottom: 80px;
}
.bike-accessories-slider__item__text-wrapper {
  padding: 0 15px;
}
.bike-accessories-slider__item .whitefront {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: white;
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 85%);
  box-shadow: 0 0 10px 1px black;
}
.bike-accessories-slider__item .blueback {
  position: absolute;
  width: 100%;
  height: 50%;
  z-index: 2;
  clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
  background: #004d9f;
}
.bike-accessories-slider__item .imagebg {
  width: calc(100% - 10px);
  padding-right: 10px;
  float: right;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 100%;
  position: relative;
  height: fit-content;
  z-index: 4;
  transform: skew(-10deg) rotate(-10deg);
}
.bike-accessories-slider__item .imagebg:after {
  content: '';
  position: absolute;
  background: #004d9f;
  width: calc(100% - 15px);
  bottom: 0;
  right: 0;
  z-index: -1;
  height: 90%;
}
.bike-accessories-slider__item .slide {
  width: 100%;
  z-index: 4;
  position: relative;
  overflow: hidden;
}
.bike-accessories-slider__item__title {
  color: #002c48;
  font-size: 30px;
  font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  line-height: 1.1;
  margin-top: 10px;
}
.bike-accessories-slider__item__id {
  display: flex;
  text-transform: uppercase;
  color: #002c48;
  margin: 10px 0;
}
.bike-accessories-slider__item__id p {
  font-size: 15px;
  margin: 0;
}
.bike-accessories-slider__item__id strong {
  color: #e40012;
  font-weight: normal;
}
.bike-accessories-slider__item__desc {
  font-size: 15px;
  color: #002c48;
  margin-bottom: 40px;
}
.bike-accessories-slider__item__price {
  position: absolute;
  bottom: 0;
  left: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.bike-accessories-slider__item__price span {
  font-size: 15px;
  text-transform: uppercase;
}
.bike-accessories-slider__item__price p {
  font-size: 30px;
  font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  margin: 0;
}
.bike-accessories-slider__item__image {
  max-height: 200px;
  height: 100%;
  margin: 5px;
  position: relative;
  overflow: hidden;
  height: 250px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}
.bike-accessories-slider__item__image img {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  max-width: 100%;
  min-height: 100%;
  border: 2px solid white;
}
