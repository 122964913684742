.footer {
  width: 100%;
  color: #002c48;
}
.footer .hungary-service-text {
  background: #002c48;
  color: white;
  padding: 1rem;
  width: 100%;
  text-align: center;
  mix-blend-mode: multiply;
  border-top: 1px dashed #e40012;
  font-size: 0.6rem;
}
@media screen and (max-width: 992px) {
  .footer .hungary-service-text {
    font-size: 1.2rem;
  }
}
.footer .proud {
  background: #eaeaea;
  display: flex;
  justify-content: center;
  position: relative;
}
.footer .proud .logos {
  max-width: 1170px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 1rem;
}
.footer .proud .logos img {
  padding: 1rem;
}
.footer .big-footer {
  width: 100%;
  min-height: 6rem;
  margin: 0;
  height: auto;
  background-image: url('../../../../Assets/footerbg.png');
  background-size: cover;
  position: relative;
}
.footer .big-footer .socials {
  position: fixed;
  right: 2rem;
  bottom: 1rem;
  z-index: 999999;
}
.footer .big-footer .socials .item {
  padding: 0.6rem 0.7rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
}
.footer .big-footer .socials .item.facebook,
.footer .big-footer .socials .item.youtube,
.footer .big-footer .socials .item.instagram {
  background: #fff;
  border-radius: 50%;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.footer .big-footer .socials .item.instagram:hover {
  background: #712759;
}
.footer .big-footer .socials .item.instagram:hover:after {
  color: white;
}
.footer .big-footer .socials .item.instagram:after {
  content: '\f16d';
  display: flex;
  color: #712759;
  font-family: FontAwesome;
  font-size: 1rem;
  width: 1rem;
  height: 1.2rem;
  justify-content: center;
  align-items: center;
}
.footer .big-footer .socials .item.facebook:hover {
  background: #004d9f;
}
.footer .big-footer .socials .item.facebook:hover:after {
  color: white;
}
.footer .big-footer .socials .item.facebook:after {
  content: '\f09a';
  display: flex;
  color: #004d9f;
  font-family: FontAwesome;
  font-size: 1rem;
  width: 1rem;
  height: 1.1rem;
  justify-content: center;
  align-items: center;
}
.footer .big-footer .socials .item.youtube:hover {
  background: #e40012;
}
.footer .big-footer .socials .item.youtube:hover:after {
  color: white;
}
.footer .big-footer .socials .item.youtube:after {
  content: '\f16a';
  display: flex;
  color: #e40012;
  font-family: FontAwesome;
  font-size: 1rem;
  width: 1rem;
  height: 1.2rem;
  justify-content: center;
  align-items: center;
}
.footer .big-footer .socials .item.to_top {
  cursor: pointer;
  background-color: #004d9f;
  -webkit-transition: 0.25s ease;
  -moz-transition: 0.25s ease;
  -ms-transition: 0.25s ease;
  -o-transition: 0.25s ease;
  transition: 0.25s ease;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 10px 2px #000000;
  -moz-box-shadow: 0 0 10px 2px #000000;
  box-shadow: 0 0 10px 2px #000000;
  opacity: 1;
}
.footer .big-footer .socials .item.to_top:hover {
  background-color: white;
}
.footer .big-footer .socials .item.to_top:hover:after {
  color: #004d9f;
}
.footer .big-footer .socials .item.to_top span {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}
.footer .big-footer .socials .item.to_top.false {
  opacity: 0;
}
.footer .big-footer .socials .item.to_top:after {
  content: '\f176';
  display: block;
  color: white;
  font-family: FontAwesome;
}
.footer .big-footer .container .logo {
  position: relative;
}
.footer .big-footer .container .logo-image {
  width: 100%;
  height: 100%;
  padding: 3rem 0 5rem 0;
}
.footer .big-footer .container .logo-naming {
  color: white;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  position: absolute;
  font-size: 0.7778rem;
  bottom: 1rem;
}
.footer .big-footer .container .left {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
.footer .big-footer .container .right {
  text-transform: uppercase;
  height: 100%;
}
.footer .big-footer .container .right .links {
  align-items: center;
  padding: 2rem 0 5rem 0;
  position: relative;
}
.footer .big-footer .container .right .links__item {
  padding: 0.5rem 0;
}
.footer .big-footer .container .right .links__item--2,
.footer .big-footer .container .right .links__item--3 {
  padding-left: 1rem;
}
.footer .big-footer .container .right .links__item--4,
.footer .big-footer .container .right .links__item--5 {
  padding-left: 2rem;
}
.footer .big-footer .container .right .links__item--6,
.footer .big-footer .container .right .links__item--7 {
  padding-left: 3rem;
}
.footer .big-footer .container .right .links a {
  color: white;
  font-size: 0.8889rem;
}
.footer .big-footer .container .right .links-bottom {
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 1rem;
  right: 0;
}
.footer .big-footer .container .right .links-bottom .link a {
  padding: 0 1rem;
  font-size: 0.7778rem;
  text-transform: capitalize;
  color: white;
}
@media screen and (max-width: 1200px) {
  .footer .big-footer .container .left .logo {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footer .big-footer .container .left .logo img {
    max-width: 20rem;
    height: auto;
  }
  .footer .socials {
    display: none;
  }
  .footer .big-footer .container .right {
    height: fit-content;
  }
  .footer .big-footer .container .right .links-bottom {
    justify-content: flex-start;
    width: 100%;
  }
  .footer .big-footer .container .right .links__item {
    padding: 0;
    text-align: center;
    margin: 0.5rem 0;
  }
}
@media screen and (max-width: 992px) {
  .footer .big-footer .container .right .links-bottom {
    justify-content: center;
  }
  .footer .big-footer .container .right .links a,
  .footer .big-footer .container .right .links-bottom .link a,
  .footer .big-footer .container .logo-naming {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 700px) {
  .footer .big-footer {
    background-size: auto;
  }
}
