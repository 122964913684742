.video-container.container {
  padding-top: 1rem;
}
.video-view__selector {
  list-style: none;
  transform: skew(-30deg);
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  max-width: 1110px;
  margin: 0 auto;
  left: 1rem;
  top: -1rem;
  position: relative;
  width: 100%;
}
.video-view__selector--menu-item-container {
  cursor: pointer;
  padding: 0rem 0rem;
  text-transform: uppercase;
  text-align: center;
  color: #002c48;
  font-size: 1rem;
  padding: 0.5rem 0;
  border-right: 1px solid #004d9f;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-view__selector--menu-item-container.active,
.video-view__selector--menu-item-container:hover {
  color: white;
  font-weight: bold;
  background: #004d9f;
  font-size: 1.3rem;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  border: none;
}
.video-view__selector--menu-item-container.active div,
.video-view__selector--menu-item-container:hover div {
  font-family: SuzukiPROHeadline;
  font-style: italic;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -ms-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}
.video-view__selector--menu-item-container.active:after,
.video-view__selector--menu-item-container:hover:after {
  display: none;
}
.video-view__selector--menu-item-container:after {
  content: '';
  position: absolute;
  height: 1px;
  background: #004d9f;
  top: 99%;
  width: 90%;
  left: 55%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.video-view__selector--menu-item {
  transform: skew(30deg);
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -ms-transform: skew(30deg);
  -o-transform: skew(30deg);
  white-space: nowrap;
  font-family: SuzukiPROHeadline;
}
@media only screen and (max-width: 1200px) {
  .video-view__selector {
    display: flex;
    overflow-x: scroll;
    margin-left: 2rem;
    margin-right: 3rem;
    justify-content: center;
    -ms-overflow-style: none;
    /* IE 11 */
    scrollbar-width: none;
    /* Firefox 64 */
  }
  .video-view__selector::-webkit-scrollbar {
    display: none;
  }
  .video-view__selector--menu-item-container {
    padding: 0.5rem 0rem;
    padding-bottom: 0rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  .video-view__selector--menu-item-container.active,
  .video-view__selector--menu-item-container:hover {
    font-size: 2rem;
    padding-right: 25px;
    top: 0;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
}
@media screen and (max-width: 992px) {
  .video-view__selector {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 600px) {
  .video-view__selector {
    justify-content: flex-start;
  }
}
