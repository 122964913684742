.il-select-wrapper {
  margin-bottom: 2rem;
}
.il-select-wrapper .input-label {
  color: #004d9f;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 3px;
  padding-bottom: 10px;
}
@media (max-width: 992px) {
  .il-select-wrapper .input-label {
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .il-select-wrapper .input-label {
    font-size: 10px;
  }
}
.select-input {
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.select-input .__control {
  font-size: 1.2rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 1px solid #d5d5d5;
  background-color: #f0f0f0;
  height: 50px;
  color: #002c48;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  cursor: pointer;
}
.select-input .__control .__value-container .__single-value {
  color: #002c48;
}
.select-input .__control:hover {
  border-color: #004d9f;
}
.select-input .__control--menu-is-open .__indicator {
  transform: rotate(180deg);
  color: white !important;
}
.select-input .__control--menu-is-open .__indicators {
  background-color: #004d9f;
}
.select-input .__indicators {
  transition: 0.3s all linear;
}
.select-input .__indicator {
  color: #002c48;
  transition: 0.3s all linear;
}
.select-input .__indicator-separator {
  opacity: 0;
}
.select-input .__indicator:hover {
  color: #163040;
}
.select-input .__menu {
  background-color: #f0f0f0;
}
.select-input .__menu .__option {
  background-color: #f0f0f0;
  color: #002c48;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-size: 1.2rem;
  cursor: pointer;
}
.select-input .__menu .__option--is-focused {
  color: #163040;
}
.select-input .__menu .__option--is-focused:hover {
  color: white;
  background-color: #004d9f;
}
.select-input .__menu .__option--is-selected {
  background-color: #004d9f;
  color: white;
}
.select-input:hover {
  background-color: #0062ba;
}
.disabled-select-wrapper {
  cursor: not-allowed;
}
.disabled-select-wrapper .select-input .__control {
  background-color: #000;
  color: white !important;
}
.disabled-select-wrapper .select-input .__control .__indicator {
  display: none;
}
.disabled-select-wrapper .select-input .__control .__indicator-separator {
  display: none;
}
.disabled-select-wrapper .select-input .__control .__value-container .__single-value {
  color: #004d9f;
}
