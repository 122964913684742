p {
  font-size: 1rem;
}
.catalog-wrapper {
  background: black;
  max-width: 100%;
  padding-bottom: 5rem;
}
.catalog-wrapper .container {
  padding-top: 1rem;
}
.catalogs {
  position: relative;
  z-index: 1;
}
.catalogs .container,
.catalogs .col-lg-8 {
  padding: 0;
}
.catalogs .left {
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
  clip-path: polygon(0 100%, 0 0, 100% 0, 100% 90%, 90% 100%);
}
.catalogs .left .catalog-item--image {
  position: relative;
  width: 100%;
}
.catalogs .left .imageborder:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 3;
  border-style: solid;
  border-width: 1rem 1rem 0 0;
  border-color: #003a78 transparent transparent transparent;
}
.catalogs .left .name {
  position: relative;
  bottom: 0;
  text-transform: uppercase;
  z-index: 3;
  left: 1.5rem;
  padding: 0.2rem 0.5rem;
  font-size: 0.75rem;
  letter-spacing: 0.2rem;
  background: #004d9f;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.catalogs .left .name span {
  display: block;
  position: absolute;
  left: -1.5rem;
  top: 0;
  border-color: transparent transparent #004d9f;
  border-style: solid;
  border-width: 0 0 1.45rem 1.5rem;
}
.catalogs .catalog-item {
  margin-top: 3rem;
}
.catalogs .catalog-item .row {
  max-width: 100%;
  margin: 15px;
}
.catalogs .catalog-item .row:before,
.catalogs .catalog-item .row:after {
  display: flex !important;
}
.catalogs .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.catalogs .right .download-button {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.catalogs .right .download-button a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
.catalogs .right .download-button .download-text {
  font-size: 0.8889rem;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-right: 1rem;
}
.catalogs .right .title {
  font-family: SuzukiPROHeadline;
  font-size: 5rem !important;
  text-transform: uppercase;
  line-height: 5.5rem !important;
  text-shadow: 0px 0px 1rem #1a1a1b;
  position: relative;
  left: -5rem;
  width: 100%;
}
.catalog-bg,
.catalog-bg img {
  max-width: 100%;
  width: 100%;
}
.catalog-bg {
  background: red;
  position: relative;
}
.catalog-bg img {
  position: absolute;
  z-index: 0;
  -webkit-filter: brightness(10%);
  filter: brightness(10%);
}
.catalog-view .video-view__selector {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.catalog-view .video-view__selector--menu-item {
  color: white;
}
.catalog-view .video__load-more--container {
  max-width: 1170px;
}
.catalog-view .video__load-more {
  position: relative;
  z-index: 1;
  margin-bottom: 3rem;
  text-align: center;
}
.catalog-view .video__load-more--button--true {
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 20px;
}
.catalog-view .video__load-more--button--true:before,
.catalog-view .video__load-more--button--true:after {
  content: '';
  position: absolute;
  top: 50%;
  width: calc(390px);
  margin-top: -2px;
  border-top: 2px solid #e40012;
  border-bottom: 2px solid #e40012;
  border-radius: 50%;
}
.catalog-view .video__load-more--button--true:before {
  right: 100%;
  margin-right: 15px;
}
.catalog-view .video__load-more--button--true:after {
  left: 100%;
  margin-right: 15px;
}
.catalog-view .video__load-more--button--false {
  display: none;
}
@media screen and (max-width: 992px) {
  .catalogs .right .title {
    width: 150%;
  }
}
@media screen and (max-width: 750px) {
  .catalogs .right .title {
    font-size: 3rem !important;
    line-height: 3.3rem !important;
  }
  .download-button img {
    max-width: 30px;
  }
}
