.second-offers .react-multi-carousel-list {
  max-height: 90vh;
}
.second-offers .more-offers__single {
  pointer-events: all;
}
.second-offers .more-offers__single--container {
  height: 40rem;
  background-size: cover;
  background-position: center center;
}
.second-offers .more-offers__single--row {
  cursor: pointer;
}
.second-offers .more-offers__single--row .desc__short p {
  margin: 0;
  font-size: 1rem !important;
  line-height: 2rem !important;
}
.second-offers .more-offers .offer__container {
  max-height: 40rem !important;
  background-size: cover;
  background-position: center center;
}
.second-offers .more-offers .offer__container img {
  width: 100%;
  height: 100%;
}
.second-offers .more-offers .filter {
  position: absolute;
  z-index: 1;
}
.second-offers .more-offers .row,
.second-offers .more-offers .row > * {
  z-index: 9999;
}
.second-offers .no-image .desc__short > *,
.second-offers .no-image .desc__short span {
  font-family: SuzukiPROHeadline;
  line-height: 4.5rem !important;
  font-size: 4rem !important;
  max-width: 100%;
  margin: 0 auto !important;
}
@media screen and (max-width: 768px) {
  .more-offers__single--row {
    padding: 0;
  }
  .more-offers__single--row .image img {
    max-width: 100%;
    margin: 0 auto;
  }
  .more-offers__single--row .desc {
    max-width: 100%;
    padding: 3rem 0;
  }
}
@media screen and (max-width: 350px) {
  .second-offers .more-offers__single--row .desc__short span,
  .second-offers .more-offers__single--row .desc__short span > *,
  .second-offers .more-offers__single--row .desc__short p > * {
    margin: 0;
    font-size: 1rem !important;
  }
  .second-offers .no-image .more-offers__single--row .desc {
    padding: 0.2rem;
    padding-top: 10vh;
    max-width: 100%;
  }
  .second-offers .no-image .more-offers__single--row .desc__short span,
  .second-offers .no-image .more-offers__single--row .desc__short span > *,
  .second-offers .no-image .more-offers__single--row .desc__short p > * {
    margin: 0;
    font-size: 4rem !important;
    line-height: 4.2rem !important;
  }
}
