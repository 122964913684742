.financing__calculated-view .buttons {
  display: flex;
  justify-content: space-around;
}
.financing__calculated-view .buttons button {
  width: 40%;
  margin: 0 auto;
  padding: 1rem 3rem;
  background: #004d9f;
  border: none !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  position: relative;
  font-size: 1.111rem;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  box-shadow: 5px 5px 0px 1px rgba(218, 41, 28, 0.2);
  transition: linear 0.3s;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.financing__calculated-view .buttons button:hover {
  background: #e40012;
  top: 5px;
  left: 5px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.financing__calculated-view .buttons button.inverse {
  background: #e40012;
  box-shadow: 5px 5px 0px 1px rgba(0, 77, 159, 0.2);
}
.financing__calculated-view .buttons button.inverse:hover {
  background: #004d9f;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.financing__calculated-view .calculation-result {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-items: center;
  max-width: 66.6666%;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .financing__calculated-view .calculation-result {
    max-width: 100%;
  }
}
.financing__calculated-view .calculation-result .item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.financing__calculated-view .calculation-result .item:not(:last-child) {
  border-bottom: 1px solid #e40012;
}
.financing__calculated-view .calculation-result .item p {
  font-size: 1.5rem;
  margin-bottom: 0;
  line-height: 2rem;
  padding: 0.5rem 0;
}
.financing__calculated-view .calculation-result .item p:last-child {
  color: white;
  font-family: SuzukiPROHeadline;
  font-size: 1.8rem;
  text-transform: uppercase;
}
.financing__calculated-view .bike-data {
  margin-top: 2rem;
}
.financing__calculated-view .bike-data-logo {
  max-width: 48%;
  margin-bottom: 1rem;
  width: 100%;
}
.financing__calculated-view .bike-data-logo img {
  max-width: 100%;
}
.financing__calculated-view .bike-data-image-and-price {
  display: flex;
}
.financing__calculated-view .bike-data .bike-image {
  max-width: 50%;
  width: 100%;
}
.financing__calculated-view .bike-data .bike-image img {
  max-width: 100%;
  width: 100%;
}
.financing__calculated-view .bike-data .bike-price {
  display: flex;
  width: 100%;
  max-width: 50%;
  justify-content: center;
  align-items: center;
  font-family: SuzukiPROHeadline;
  font-size: 5rem;
}
@media screen and (max-width: 600px) {
  .financing__calculated-view .bike-data-image-and-price {
    flex-direction: column;
  }
  .financing__calculated-view .bike-data-logo {
    max-width: 95%;
    margin: 0 auto;
  }
  .financing__calculated-view .bike-data .bike-image {
    max-width: 100%;
    order: 2;
  }
  .financing__calculated-view .bike-data .bike-price {
    max-width: 100%;
    order: 1;
  }
}
