.about-us-view .big-image-lead {
  padding: 2rem 0;
  position: relative;
  background-size: cover;
  background-position: 73%;
}
.about-us-view .big-image-lead .filter {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 44, 72, 0.7);
  background-blend-mode: multiply;
  -webkit-clip-path: polygon(0 100%, 60% 100%, 75% 0, 0 0);
  clip-path: polygon(0 100%, 60% 100%, 75% 0, 0 0);
}
.about-us-view .title {
  font-size: 3.333rem;
  font-family: SuzukiPROHeadline;
  line-height: 4rem;
  margin-bottom: 1rem;
}
.about-us-view .description p:last-child i {
  font-family: SuzukiPROHeadLine;
}
.about-us-view .description p {
  font-size: 1.111rem;
}
.about-us-view .slider .row {
  margin: 0;
  display: flex;
  justify-content: center;
}
.about-us-view .slider .image {
  text-align: right;
  background-color: #e0e0e0;
  background-blend-mode: multiply;
  background-image: url('../../../../Assets/BG2.png');
}
.about-us-view .slider .image img {
  position: relative;
  z-index: 1;
  width: 59%;
}
.about-us-view .slider .content {
  width: 100%;
  height: 100%;
  display: flex;
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 0, 0px 0);
  clip-path: polygon(0 100%, 56% 100%, 42% 0%, 0 0);
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  left: 0;
  background-color: #e0e0e0;
  background-blend-mode: multiply;
  background-image: url('../../../../Assets/BG2.png');
  overflow: hidden;
  z-index: 2;
}
.about-us-view .slider .content .text {
  color: #002c48;
  text-align: left;
  padding-top: 3rem;
  width: 55%;
  position: relative;
  z-index: 2;
}
.about-us-view .slider .content .text div {
  width: 40%;
  position: relative;
  right: 13rem;
  float: right;
}
.about-us-view .slider .content .text div h2 {
  font-family: SuzukiPROHeadline;
  font-size: 2.778rem !important;
  margin-bottom: 1rem;
}
.about-us-view .slider .content .text div p {
  font-size: 1.111rem !important;
  line-height: 1.4rem;
}
.about-us-view .information {
  display: flex;
  flex-direction: row;
}
.about-us-view .information .customer-service,
.about-us-view .information .press-contact {
  max-width: 50%;
  width: 100%;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2rem 0;
}
.about-us-view .information .customer-service .title,
.about-us-view .information .press-contact .title {
  font-family: SuzukiPROHeadline;
  text-transform: uppercase;
  font-size: 2.7778rem;
}
.about-us-view .information .customer-service .content,
.about-us-view .information .press-contact .content {
  max-width: 65%;
  margin: 0 auto;
}
.about-us-view .information .customer-service .content p,
.about-us-view .information .press-contact .content p {
  margin: 0;
}
.about-us-view .information .customer-service .title {
  display: flex;
  align-items: center;
}
.about-us-view .information .customer-service .title .icon {
  margin-right: 1rem;
  padding-bottom: 0.5rem;
}
.about-us-view .information .customer-service .title .icon img {
  max-height: 2.5rem;
}
.about-us-view .information .press-contact .content > *,
.about-us-view .information .press-contact .title {
  color: #002c48;
}
.about-us-view .information .press-contact .content > * .icon,
.about-us-view .information .press-contact .title .icon {
  position: relative;
  bottom: -1rem;
}
.about-us-view .history {
  height: 100%;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  text-align: justify;
  -moz-text-align-last: center;
  text-align-last: center;
  padding: 2rem 0 0 0;
  position: relative;
  z-index: 3;
}
.about-us-view .history .title {
  font-size: 8.333rem;
  text-transform: uppercase;
  color: #c0c0c1;
  opacity: 0.9;
  text-shadow: 5px 5px #363636;
  text-align: center;
  line-height: 9rem;
  padding: 1rem 0 0 0;
  margin: 0 !important;
  line-height: 6rem;
}
.about-us-view .history .subtitle {
  font-size: 2.5rem;
  font-family: SuzukiPROHeadline;
  padding-bottom: 2rem;
}
.about-us-view .timeline-filter {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 2;
  font-family: SuzukiPROHeadline;
  text-transform: uppercase;
  font-size: 3rem;
  letter-spacing: 0.2rem;
  text-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.about-us-view .timeline-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 583px;
  -ms-overflow-style: none;
}
.about-us-view .timeline-wrapper::-webkit-scrollbar {
  display: none;
}
.about-us-view .react-multi-carousel-track {
  position: relative;
  z-index: 1;
}
.about-us-view .react-multiple-carousel__arrow {
  background: #004d9f;
  border-radius: 0;
  transform: skewX(-15deg);
  bottom: 0;
}
.about-us-view .react-multiple-carousel__arrow:before {
  font-family: FontAwesome;
  transform: skewX(15deg);
}
.about-us-view .react-multiple-carousel__arrow--left {
  left: 52%;
}
.about-us-view .react-multiple-carousel__arrow--left:before {
  content: '\f177';
}
.about-us-view .react-multiple-carousel__arrow--right {
  right: 43.5%;
}
.about-us-view .react-multiple-carousel__arrow--right:before {
  content: '\f178';
}
@media screen and (max-width: 1890px) {
  .about-us-view .slider .content .text {
    padding: 1rem 0 0 1rem;
  }
  .about-us-view .slider .content .text div {
    right: 0;
    width: 55%;
    float: left;
  }
}
@media screen and (max-width: 1600px) {
  .about-us-view .slider .content .text div {
    width: 70%;
  }
  .about-us-view .react-multiple-carousel__arrow--left {
    left: 52%;
  }
  .about-us-view .react-multiple-carousel__arrow--right {
    right: 42.5%;
  }
}
@media screen and (max-width: 1400px) {
  .about-us-view .slider .content .text div {
    width: 80%;
  }
  .about-us-view .slider .content .text div h2 {
    font-size: 1.5rem;
  }
  .about-us-view .slider .content .text div p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .about-us-view .react-multiple-carousel__arrow--right {
    right: 41%;
  }
  .about-us-view .information {
    flex-direction: column;
  }
  .about-us-view .information .press-contact,
  .about-us-view .information .customer-service {
    max-width: 100%;
  }
}
@media screen and (max-width: 992px) {
  .about-us-view p,
  .about-us-view .slider .content .text div p {
    font-size: 1.5rem !important;
    line-height: 1.8rem;
  }
  .about-us-view .title {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .about-us-view .history .title {
    font-size: 5rem;
    line-height: 5rem;
  }
  .about-us-view .history .subtitle {
    font-size: 2rem;
    line-height: 2rem;
  }
  .about-us-view .slider .image img {
    width: 100%;
  }
  .about-us-view .slider .content {
    justify-content: center;
    align-items: center;
    position: relative;
    clip-path: unset;
    -webkit-clip-path: unset;
  }
  .about-us-view .slider .content .text {
    width: 100%;
    justify-content: center;
    display: flex;
    text-align: center;
  }
  .about-us-view .react-multiple-carousel__arrow--left {
    bottom: auto;
    left: 1%;
  }
  .about-us-view .react-multiple-carousel__arrow--right {
    bottom: auto;
    right: 1%;
  }
}
