.first-offers .first-offers-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8rem 0;
}
.first-offers .first-offers-item .title {
  position: absolute;
  width: 35rem;
  display: flex;
  left: -20rem;
  justify-content: center;
}
.first-offers .first-offers-item .title p {
  background-image: linear-gradient(to right, #004d9f 0%, #e40012 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  width: fit-content;
  font-size: 7.6rem;
  font-family: SuzukiPROHeadline;
  transform: rotate(-60deg);
  position: relative;
  left: 0;
  top: -2rem;
  z-index: 1;
}
.first-offers .first-offers-item .images {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin: 0 auto;
  max-width: 70%;
  width: 100%;
  left: 0;
  right: 0;
}
.first-offers .first-offers-item .images .item {
  max-width: 80%;
  cursor: pointer;
  transform: skew(-30deg);
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: -0.5rem;
  position: relative;
}
.first-offers .first-offers-item .images .item:hover {
  transform: skew(-30deg) scale(1.15);
  -webkit-transform: skew(-30deg) scale(1.15);
  -moz-transform: skew(-30deg) scale(1.15);
  -ms-transform: skew(-30deg) scale(1.15);
  -o-transform: skew(-30deg) scale(1.15);
  -webkit-transition: 0.25s linear;
  -moz-transition: 0.25s linear;
  -ms-transition: 0.25s linear;
  -o-transition: 0.25s linear;
  transition: 0.25s linear;
  z-index: 3;
  -webkit-box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
}
.first-offers .first-offers-item .images .item img {
  transform: skew(30deg);
  width: 250%;
  height: auto;
}
.first-offers .first-offers-item .images .item .text {
  -webkit-transition: 0.25s linear;
  -moz-transition: 0.25s linear;
  -ms-transition: 0.25s linear;
  -o-transition: 0.25s linear;
  transition: 0.25s linear;
  transform: skew(30deg);
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -ms-transform: skew(30deg);
  -o-transform: skew(30deg);
  font-size: 4.1rem;
  line-height: 4rem;
  font-family: SuzukiPROHeadline;
  color: white;
  background-color: #004d9f;
  width: 150%;
  position: absolute;
  bottom: -2px;
  z-index: 3;
  font-style: italic;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#004d9f), to(#e40012));
  background-image: -o-linear-gradient(bottom, #004d9f 0%, #e40012 100%);
  background-image: linear-gradient(to top, #004d9f 0%, #e40012 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
}
.first-offers .first-offers-item .images .item .text__under {
  -webkit-transition: 0.25s linear;
  -moz-transition: 0.25s linear;
  -ms-transition: 0.25s linear;
  -o-transition: 0.25s linear;
  transition: 0.25s linear;
  -webkit-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
  font-size: 4rem;
  line-height: 4rem;
  font-style: italic;
  font-family: SuzukiPROHeadline;
  color: white;
  background-color: #004d9f;
  width: 150%;
  bottom: 0;
  position: absolute;
  z-index: 3;
  display: inline-block;
  text-align: center;
  background-image: -webkit-gradient(linear, right top, left top, from(#ffffff), to(#c1c1c1));
  background-image: -o-linear-gradient(right, #ffffff 0%, #c1c1c1 100%);
  background-image: linear-gradient(to left, #ffffff 0%, #c1c1c1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}
.first-offers .first-offers-item .images .item--too_long .text {
  font-size: 3.1rem;
  line-height: 3rem;
}
.first-offers .first-offers-item .images .item--too_long .text__under {
  font-size: 3rem;
  line-height: 3rem;
}
@media screen and (max-width: 1600px) {
  .first-offers .first-offers-item .title p {
    font-size: 6rem;
  }
}
@media screen and (max-width: 1366px) {
  .first-offers .first-offers-item .images .item .text__under,
  .first-offers .first-offers-item .images .item .text {
    font-size: 2.5rem;
  }
  .first-offers .first-offers-item .title p {
    font-size: 5rem;
  }
}
@media screen and (max-width: 768px) {
  .first-offers .first-offers-item {
    padding: 3rem 0;
  }
  .first-offers .first-offers-item .images {
    flex-direction: column;
    max-width: 100%;
    padding: 0 1rem;
  }
  .first-offers .first-offers-item .images a .text {
    font-size: 2rem;
    line-height: 3.5rem;
  }
  .first-offers .first-offers-item .images .title {
    position: relative;
    width: 100%;
    text-align: center;
    left: 0;
    top: 0;
  }
  .first-offers .first-offers-item .images .title p {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    line-height: 6.5rem;
    margin: 0;
    padding-top: 1rem;
    bottom: -0.7rem;
    top: auto;
    font-size: 6rem;
  }
  .first-offers .first-offers-item .images .item {
    max-width: 100%;
    transform: skew(0deg);
  }
  .first-offers .first-offers-item .images .item:hover {
    transform: skew(0deg) scale(1);
  }
  .first-offers .first-offers-item .images .item .text,
  .first-offers .first-offers-item .images .item .text__under {
    width: 100%;
    transform: skew(0deg);
    bottom: 2rem;
    font-size: 5rem;
    line-height: 5.5rem;
  }
  .first-offers .first-offers-item .images .item .text {
    font-size: 5.1rem;
  }
  .first-offers .first-offers-item .images .item img {
    width: 100%;
    transform: skew(0deg);
    margin-bottom: 1rem;
  }
}
