body {
  background-color: white;
  min-height: 2500px;
}
.cookie-backdrop {
  background-color: #002c48 !important;
}
.cookie-backdrop.show {
  opacity: 0.9 !important;
}
.cookie-container {
  color: #004d9f;
  height: 600px;
  width: 100%;
  background: url("../../../../../Assets/BG.png");
  background-position: left top;
  background-size: auto;
  -webkit-transition: height 0.25s ease-in-out;
  -moz-transition: height 0.25s ease-in-out;
  -ms-transition: height 0.25s ease-in-out;
  -o-transition: height 0.25s ease-in-out;
  transition: height 0.25s ease-in-out;
  overflow: hidden;
}
.cookie-container-white {
  background: white;
  overflow: hidden !important;
}
.cookie-container-large {
  height: 900px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.cookie-preheader {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.cookie-preheader img {
  height: 4rem;
  width: auto;
  padding: 0;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.cookie-preheader .cookie-preheader-close {
  width: 3rem;
  height: 3rem;
  margin-right: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.cookie-preheader .cookie-preheader-close > div {
  width: 3rem;
  height: 4px;
  background-color: #002c48;
  position: absolute;
  transform-origin: center center;
}
.cookie-preheader .cookie-preheader-close > div:first-child {
  transform: rotate(-60deg);
  -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
}
.cookie-preheader .cookie-preheader-close > div:last-child {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  -o-transform: rotate(60deg);
}
.cookie-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
}
.cookie-header .cookie-header-title {
  text-transform: uppercase;
  font-size: 2.22222222rem;
  font-family: SuzukiPROHeadline;
  color: #002c48;
  font-style: italic;
  line-height: 1.7066rem;
}
@media screen and (max-width: 1200px) {
  .cookie-header .cookie-header-title {
    font-size: 4rem;
  }
  .cookie-header .cookie-header-title::before {
    top: -0.5rem;
  }
  .cookie-header .cookie-header-title::after {
    bottom: -0.5rem;
  }
}
@media screen and (max-width: 550px) {
  .cookie-header .cookie-header-title {
    font-size: 3rem;
  }
  .cookie-header .cookie-header-title::before {
    top: 0rem;
  }
  .cookie-header .cookie-header-title::after {
    bottom: 0rem;
  }
}
.cookie-header .cookie-header-title::before {
  height: 1rem;
  left: 1rem;
  margin-bottom: 0.5rem;
  content: "";
  display: block;
  border-left: 2px solid #e40012;
  position: relative;
  transform: skew(-15deg);
  -webkit-transform: skew(-15deg);
  -moz-transform: skew(-15deg);
  -ms-transform: skew(-15deg);
  -o-transform: skew(-15deg);
}
.cookie-header .cookie-header-title::after {
  height: 1rem;
  margin-top: 0.5rem;
  content: "";
  display: block;
  border-left: 2px solid #e40012;
  position: relative;
  transform: skew(-15deg);
  -webkit-transform: skew(-15deg);
  -moz-transform: skew(-15deg);
  -ms-transform: skew(-15deg);
  -o-transform: skew(-15deg);
}
.cookie-spacer {
  padding-bottom: 2rem;
}
.cookie-content {
  padding: 0 5rem;
}
@media screen and (max-width: 1200px) {
  .cookie-content {
    padding: 0 3rem;
  }
}
@media screen and (max-width: 576px) {
  .cookie-content {
    padding: 0 2rem;
  }
}
.cookie-content-text {
  font-family: SuzukiPRORegular;
  text-align: center;
  color: #002c48;
  margin: 0;
}
@media screen and (min-width: 1171px) {
  .cookie-content-text {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1170px) {
  .cookie-content-text {
    font-size: 1.14285714rem;
  }
}
@media screen and (max-width: 992px) {
  .cookie-content-text {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 550px) {
  .cookie-content-text {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 350px) {
  .cookie-content-text {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 300px) {
  .cookie-content-text {
    font-size: 1.8rem;
  }
}
.cookie-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.cookie-button button {
  outline: none;
  border: none;
}
.cookie-button button:focus,
.cookie-button button:active {
  outline: none;
  border: none;
}
@media screen and (max-width: 1200px) {
  .cookie-button {
    margin-bottom: 20px;
  }
}
.simple-cookie-button {
  background-color: transparent;
  font-family: SuzukiPROHeadline;
  color: #002c48;
  background-image: linear-gradient(#e40012, #e40012);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 0% 3px;
  transition: background-size 0.25s linear;
}
@media screen and (min-width: 1171px) {
  .simple-cookie-button {
    font-size: 1.11111111rem;
  }
}
@media screen and (max-width: 1170px) {
  .simple-cookie-button {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 992px) {
  .simple-cookie-button {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 550px) {
  .simple-cookie-button {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 350px) {
  .simple-cookie-button {
    font-size: 2rem;
  }
}
@media screen and (max-width: 300px) {
  .simple-cookie-button {
    font-size: 2rem;
  }
}
@media (hover: hover) {
  .simple-cookie-button:hover {
    background-size: 100% 3px;
  }
}
.filled-cookie-button {
  background-color: #004d9f;
  padding: 15px 30px;
  font-family: SuzukiPROHeadline;
  color: white;
  background-image: linear-gradient(#e40012, #e40012), linear-gradient(#e40012, #e40012), linear-gradient(#e40012, #e40012), linear-gradient(#e40012, #e40012);
  background-position: left bottom, right bottom, right top, left top;
  background-repeat: no-repeat;
  background-size: 0% 3px, 3px 0%, 0% 3px, 3px 0%;
  transition: background-size 0.3s linear, background-color 0.3s linear, color 0.3s linear;
}
@media screen and (min-width: 1171px) {
  .filled-cookie-button {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 1170px) {
  .filled-cookie-button {
    font-size: 1.85714286rem;
  }
}
@media screen and (max-width: 992px) {
  .filled-cookie-button {
    font-size: 2rem;
  }
}
@media screen and (max-width: 550px) {
  .filled-cookie-button {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 350px) {
  .filled-cookie-button {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 300px) {
  .filled-cookie-button {
    font-size: 2.85714286rem;
  }
}
@media (hover: hover) {
  .filled-cookie-button:hover {
    background-size: 100% 3px, 3px 100%, 100% 3px, 3px 100%;
    background-color: transparent;
    color: #002c48;
  }
}
.cookie-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}
.cookie-footer-text {
  color: #002c48;
  font-family: SuzukiPRORegular;
  margin: 0;
}
@media screen and (min-width: 1171px) {
  .cookie-footer-text {
    font-size: 0.77777778rem;
  }
}
@media screen and (max-width: 1170px) {
  .cookie-footer-text {
    font-size: 1rem;
  }
}
@media screen and (max-width: 992px) {
  .cookie-footer-text {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 550px) {
  .cookie-footer-text {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 350px) {
  .cookie-footer-text {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 300px) {
  .cookie-footer-text {
    font-size: 1.5rem;
  }
}
.cookie-footer-text button {
  background-color: transparent;
  color: #002c48;
  font-family: SuzukiPRORegular;
  padding: 0;
  font-style: italic;
  font-weight: bold;
  outline: none;
  border: none;
}
@media screen and (min-width: 1171px) {
  .cookie-footer-text button {
    font-size: 0.77777778rem;
  }
}
@media screen and (max-width: 1170px) {
  .cookie-footer-text button {
    font-size: 1rem;
  }
}
@media screen and (max-width: 992px) {
  .cookie-footer-text button {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 550px) {
  .cookie-footer-text button {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 350px) {
  .cookie-footer-text button {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 300px) {
  .cookie-footer-text button {
    font-size: 1.5rem;
  }
}
.cookie-footer-text button:focus,
.cookie-footer-text button:active {
  outline: none;
  border: none;
}
