.splash-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000005;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  transform: translateY(150vh);
  -webkit-transform: translateY(150vh);
  -moz-transform: translateY(150vh);
  -ms-transform: translateY(150vh);
  -o-transform: translateY(150vh);
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.splash-screen.start {
  transform: translateY(0vh);
  -webkit-transform: translateY(0vh);
  -moz-transform: translateY(0vh);
  -ms-transform: translateY(0vh);
  -o-transform: translateY(0vh);
  -webkit-transition: all 0.05s linear;
  -moz-transition: all 0.05s linear;
  -ms-transition: all 0.05s linear;
  -o-transition: all 0.05s linear;
  transition: all 0.05s linear;
}
