.technology-block-component {
  color: #002c48;
  position: relative;
  min-height: 350px;
  padding-top: 30px;
  overflow: hidden;
  display: flex;
}
.technology-block-component__content-container {
  background-color: #004d9f;
  background-image: url("../../../../Assets/BikePage/bluePatter.png");
  width: 100%;
}
.technology-block-component__content-container .technology-content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid: "center" 1fr;
  position: relative;
  z-index: 3;
}
.technology-block-component__background-text-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 2;
}
.technology-block-component__background-text {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 90px;
  font-family: SuzukiPROHeadline;
  transform: skewX(-15deg);
  color: white;
  opacity: 0.1;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.technology-block-component__right-side {
  background-color: whitesmoke;
  background-image: url("../../../../Assets/BikePage/darkGrayPatter.png");
  position: absolute;
  transform: skewX(-15deg);
  top: 0;
  left: 50%;
  width: 150%;
  height: 100%;
  padding-right: 120%;
  padding-top: 30px;
  padding-left: 20px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.technology-block-component .technology-title {
  color: white;
  font-size: 30px;
  font-weight: 900;
  max-width: 500px;
  padding: 40px 0 30px;
  font-family: SuzukiPROHeadline;
  text-transform: uppercase;
}
.technology-block-component .technology-desc {
  color: white;
  font-size: 18px;
  max-width: 500px;
  padding-bottom: 30px;
  opacity: 0.9;
  line-height: 27px;
}
.technology-block-component .tech-container {
  width: 100%;
}
.technology-block-component .tech-icon-container {
  min-height: 50px;
  width: 15%;
  margin: 2px;
  padding: 10px 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
}
.technology-block-component .tech-icon-container:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.technology-block-component .tech-icon-container--active {
  border-color: #004d9f;
}
.technology-block-component .tech-icon-container__tech-icon {
  transform: skewX(15deg);
  width: 100%;
  height: auto;
}
.technology-block-component .technology-content-slider-item {
  grid-area: 1 / 1;
  transition: all 0.3s ease-out;
}
.technology-block-component .technology-content-slider-item.fade-enter {
  opacity: 0%;
  transform: translateX(100%);
}
.technology-block-component .technology-content-slider-item.fade-enter-active {
  opacity: 100%;
  transform: translateX(0%);
}
.technology-block-component .technology-content-slider-item.fade-exit {
  opacity: 0%;
  transform: translateX(-100%);
}
@media screen and (max-width: 1650px) {
  .technology-block-component__right-side {
    padding-right: 115%;
  }
}
@media screen and (max-width: 1500px) {
  .technology-block-component__right-side {
    padding-right: 105%;
  }
}
@media screen and (max-width: 1250px) and (min-width: 1100px) {
  .technology-block-component .technology-title,
  .technology-block-component .technology-desc {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 500px) {
  .technology-block-component {
    flex-direction: column;
  }
  .technology-block-component__content-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .technology-block-component__right-side {
    position: unset;
    transform: none;
    width: 100%;
    height: 100%;
    padding-right: 0;
    padding-top: 30px;
    padding-left: 0;
  }
  .technology-block-component .tech-icon-container {
    width: 30%;
    border-bottom: 2px solid transparent;
    border-right: 4px solid transparent;
  }
  .technology-block-component .tech-icon-container:hover {
    background-color: transparent;
  }
  .technology-block-component .tech-icon-container--active {
    border-color: #004d9f;
    border-right: 2px solid transparent;
    border-bottom: 4px solid #004d9f;
  }
  .technology-block-component .tech-icon-container__tech-icon {
    transform: skewX(0deg);
  }
}
