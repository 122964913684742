.navbar {
  top: 1rem;
  z-index: 8;
  width: 100%;
  max-width: 1490px;
  padding-left: 15px;
  padding-right: 15px;
  height: 4rem;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 auto;
  justify-content: flex-start;
}
.navbar__logo--image {
  padding-right: 3px;
  height: 4rem;
  cursor: pointer;
}
.navbar__routes {
  display: flex;
  flex-direction: column;
  position: relative;
}
.navbar__routes .navbar-route-line {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: fit-content;
  height: 1.93rem;
}
.navbar__routes .navbar-route-line::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.9rem 1rem 0 0;
  border-color: white transparent transparent transparent;
  left: 1rem;
  position: relative;
}
.navbar__routes .navbar-route-line::before {
  content: "";
  height: 100%;
  min-width: 30px;
  background: url("../../../../Assets/menuflekk.png");
  background-repeat: no-repeat;
  right: -1.5rem;
  position: absolute;
}
.navbar__routes .navbar-route-line .link {
  line-height: 1.3rem;
  cursor: pointer;
  padding: 0 15px;
  font-size: 1.111rem;
  height: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
  background-color: transparent;
  vertical-align: middle;
}
.navbar__routes .navbar-route-line .link a {
  margin: 0;
  text-decoration: none;
  padding: 0 !important;
}
.navbar__routes .navbar-route-line .link--with-icon {
  background-image: none !important;
  background-repeat: no-repeat;
  background-size: 1.1rem 1.1rem;
  background-position: left center;
  min-height: 15px;
  font-size: 0.8889rem;
  padding: 0 10px !important;
  background-color: white;
  padding-right: 0.2rem;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.navbar__routes .navbar-route-line .link--with-icon:hover,
.navbar__routes .navbar-route-line .link--with-icon:hover a,
.navbar__routes .navbar-route-line .link--with-icon.active,
.navbar__routes .navbar-route-line .link--with-icon.active a {
  background-color: #e40012;
  color: white;
}
.navbar__routes .navbar-route-line .link--with-icon:hover a:after {
  background: url("../../../../Assets/small_menuflekk_w.png");
  background-repeat: no-repeat;
  pointer-events: none;
}
.navbar__routes .navbar-route-line .link--with-icon:hover a:before {
  border-color: #860d24 transparent transparent transparent;
  pointer-events: none;
}
.navbar__routes .navbar-route-line .link--with-icon:after {
  pointer-events: none;
}
.navbar__routes .navbar-route-line .link--with-icon a {
  transform: skew(30deg);
  color: #002c48;
  border: none !important;
  padding: 0;
  margin-right: 0 !important;
  font-variant: small-caps;
  font-style: italic;
  letter-spacing: 2px;
  font-size: 14px;
}
.navbar__routes .navbar-route-line .link--with-icon a:hover,
.navbar__routes .navbar-route-line .link--with-icon a.active {
  color: white;
}
.navbar__routes .navbar-route-line .link--with-icon a:hover:after,
.navbar__routes .navbar-route-line .link--with-icon a.active:after {
  background: url("../../../../Assets/small_menuflekk_w.png");
  background-repeat: no-repeat;
  pointer-events: none;
}
.navbar__routes .navbar-route-line .link--with-icon a:hover:before,
.navbar__routes .navbar-route-line .link--with-icon a.active:before {
  border-color: #860d24 transparent transparent transparent;
  pointer-events: none;
}
.navbar__routes .navbar-route-line .link--with-icon a:after {
  content: "";
  transition: 0.2s all linear;
  height: 100%;
  min-width: 30px;
  background: url("../../../../Assets/small_menuflekk.png");
  background-repeat: no-repeat;
  right: -1.8rem;
  position: absolute;
}
.navbar__routes .navbar-route-line .link--with-icon a:before {
  content: "";
  transition: 0.2s all linear;
  position: absolute;
  left: -20px;
  bottom: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 8px 0 0;
  transform: skew(-30deg);
  border-color: #d5d5d5 transparent transparent transparent;
}
.navbar__routes .navbar-route-line .link--with-icon:first-child {
  margin-right: 10px;
}
.navbar__routes--top {
  background-color: white;
  margin-bottom: 3px;
  position: relative;
}
.navbar__routes--top .main-link {
  font-size: 1.111rem;
  color: #002737;
  font-family: SuzukiPROHeadline;
  position: relative;
}
.navbar__routes--top .main-link:before,
.navbar__routes--top .main-link:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #e40012;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}
.navbar__routes--top .main-link:hover:before,
.navbar__routes--top .main-link:focus:before,
.navbar__routes--top .main-link:hover:after,
.navbar__routes--top .main-link:focus:after,
.navbar__routes--top .main-link.active:before,
.navbar__routes--top .main-link.active:after {
  visibility: visible;
  transform: scaleX(1);
}
.navbar__routes--row-wrapper {
  display: flex;
  flex-direction: row;
}
.navbar__routes--side {
  margin-left: 23px;
  height: 1.5rem !important;
  transform: skew(-30deg);
}
.navbar__routes--side:after,
.navbar__routes--side:before {
  display: none !important;
}
.navbar__routes--side div.side-link {
  position: relative;
}
.navbar__routes--side div.side-link:before,
.navbar__routes--side div.side-link:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #e40012;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}
.navbar__routes--side div.side-link:hover:before,
.navbar__routes--side div.side-link:focus:before,
.navbar__routes--side div.side-link:hover:after,
.navbar__routes--side div.side-link:focus:after,
.navbar__routes--side div.side-link.active:before,
.navbar__routes--side div.side-link.active:after {
  visibility: visible;
  transform: scaleX(1);
}
.navbar__routes--side .side-link {
  font-size: 1rem;
}
.navbar__routes--bottom {
  background-color: white;
  justify-content: flex-start;
  position: relative;
}
.navbar__routes--bottom .sub-link {
  font-size: 1rem;
  height: 100%;
  color: #002737;
  font-family: SuzukiPROHeadline;
  position: relative;
}
.navbar__routes--bottom .sub-link:before,
.navbar__routes--bottom .sub-link:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #e40012;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}
.navbar__routes--bottom .sub-link:hover:before,
.navbar__routes--bottom .sub-link:focus:before,
.navbar__routes--bottom .sub-link:hover:after,
.navbar__routes--bottom .sub-link:focus:after,
.navbar__routes--bottom .sub-link.active:before,
.navbar__routes--bottom .sub-link.active:after {
  visibility: visible;
  transform: scaleX(1);
}
.navbar__routes--bottom .sub-link a {
  padding: 0 !important;
  text-decoration: none;
}
.navbar__routes--socials {
  display: none !important;
}
@media screen and (max-width: 1200px) {
  .navbar__routes {
    display: none;
  }
}
.navbar__mobile {
  display: none;
}
@media screen and (max-width: 1200px) {
  .navbar__mobile {
    top: 4rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    height: calc(96vh);
    background: linear-gradient(rgba(0, 44, 72, 0.95), rgba(0, 77, 159, 0.95));
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 2rem;
    font-size: 2.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
  }
  .navbar__mobile--scroll {
    overflow-y: auto;
    width: 100%;
  }
  .navbar__mobile--scroll a {
    font-family: SuzukiPROHeadline;
    color: white !important;
  }
  .navbar__mobile--scroll div.active:before {
    content: "\f178";
    font-family: FontAwesome;
    display: inline-block;
    color: #e40012;
    margin-right: 0.5rem;
  }
  .navbar__mobile--side {
    margin: 2rem 0;
    border-top: 2px dashed white;
    border-bottom: 2px dashed white;
    width: 100%;
    padding: 2rem 0;
  }
  .navbar__mobile--side a {
    font-size: 2.5rem;
    line-height: 3rem;
    color: white !important;
    font-variant: small-caps;
  }
  .navbar__mobile--side .link--with-icon {
    background-image: none !important;
  }
  .navbar__mobile--side .link--with-icon span {
    display: inline-block !important;
    height: 100%;
    width: auto;
    padding-right: 1rem;
  }
  .navbar__mobile--socials {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    font-size: 2.5rem;
    line-height: 3rem;
    font-variant: small-caps;
  }
  .navbar__mobile--socials .left {
    display: contents;
  }
  .navbar__mobile--socials .item {
    background: white;
    padding: 1rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.navbar__threads {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 2.5rem;
  right: 6rem;
  cursor: pointer;
  top: 0;
}
.navbar__threads .thread {
  transition: 0.5s ease;
  margin: 0.2rem auto;
  display: block;
  width: 100%;
  height: 0.2rem;
  background-color: white;
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.navbar__threads:hover .thread {
  background-color: white;
}
.navbar .lang-selector {
  width: 2.5rem;
  position: absolute;
  right: 0;
  top: 0.5rem;
  z-index: 2;
  border: 0.001rem solid white;
  color: white;
  height: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.111rem;
  font-family: SuzukiPROHeadline;
  background-color: transparent;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  height: 1.9rem;
}
.navbar .lang-selector:hover {
  color: #002c48;
  background-color: white;
  border: none;
}
@media screen and (max-width: 1420px) {
  .navbar .navbar__routes {
    position: relative;
  }
  .navbar .lang-selector {
    right: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .navbar {
    position: fixed;
    height: 4rem;
    padding: 0;
    max-width: 100%;
    top: 0;
    background-color: #002c48;
    z-index: 9999999;
  }
  .navbar__logo--image {
    height: 4rem;
    padding: 0;
  }
  .navbar__routes {
    background-color: #005aab;
    height: 6rem;
  }
  .navbar__routes--socials {
    order: 4;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around;
    background: rgba(0, 44, 72, 0.95);
    padding: 2rem 0;
  }
  .navbar__routes--socials .item {
    background: white;
    padding: 1rem;
    border-radius: 50%;
  }
  .navbar__routes--socials::after {
    display: none !important;
  }
  .navbar__routes--top {
    padding-bottom: 1rem;
    order: 2;
  }
  .navbar__routes--top .link.main-link,
  .navbar__routes--top .link.sub-link {
    font-size: 2.5rem !important;
  }
  .navbar__routes--top .link.main-link.active,
  .navbar__routes--top .link.sub-link.active {
    font-size: 2.5rem !important;
  }
  .navbar__routes--bottom {
    padding-top: 1rem;
    order: 1;
  }
  .navbar__routes--side {
    order: 3;
    padding: 2rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  .navbar__routes--top,
  .navbar__routes--bottom,
  .navbar__routes--side {
    background-color: rgba(0, 44, 72, 0.95) !important;
  }
  .navbar__routes--top .main-link,
  .navbar__routes--bottom .main-link,
  .navbar__routes--side .main-link,
  .navbar__routes--top .sub-link,
  .navbar__routes--bottom .sub-link,
  .navbar__routes--side .sub-link {
    color: white !important;
    justify-content: center;
    font-size: 3rem !important;
  }
  .navbar__routes--top .main-link.active,
  .navbar__routes--bottom .main-link.active,
  .navbar__routes--side .main-link.active,
  .navbar__routes--top .sub-link.active,
  .navbar__routes--bottom .sub-link.active,
  .navbar__routes--side .sub-link.active {
    font-size: 3rem !important;
  }
  .navbar__routes--top .side-link,
  .navbar__routes--bottom .side-link,
  .navbar__routes--side .side-link {
    color: white !important;
    justify-content: center;
    font-size: 2rem !important;
  }
  .navbar__routes--top .main-link:before,
  .navbar__routes--bottom .main-link:before,
  .navbar__routes--side .main-link:before,
  .navbar__routes--top .sub-link:before,
  .navbar__routes--bottom .sub-link:before,
  .navbar__routes--side .sub-link:before {
    width: 26vw;
    bottom: auto;
  }
  .navbar__routes--top .main-link:after,
  .navbar__routes--bottom .main-link:after,
  .navbar__routes--side .main-link:after,
  .navbar__routes--top .sub-link:after,
  .navbar__routes--bottom .sub-link:after,
  .navbar__routes--side .sub-link:after {
    right: 0;
    left: auto;
    width: 26vw;
    bottom: auto;
  }
  .navbar__routes .navbar-route-line:before,
  .navbar__routes .navbar-route-line {
    display: none;
  }
  .navbar.navbar-opened {
    transition: 0.5s ease;
  }
  .navbar.navbar-opened .navbar__routes {
    justify-content: space-around;
  }
  .navbar.navbar-opened .navbar__routes--side {
    flex-direction: row !important;
    display: flex !important;
  }
  .navbar.navbar-opened .side-link.link {
    justify-content: center;
    background-image: none !important;
    position: relative;
    align-items: center;
  }
  .navbar.navbar-opened .side-link.link span {
    display: inline-block !important;
    height: 100%;
    width: auto;
    padding: 0 1rem;
  }
  .navbar.navbar-opened .navbar__threads {
    background: white;
  }
  .navbar.navbar-opened .navbar__threads div {
    background-color: #002c48;
    margin: 0;
    width: 100%;
    top: 0;
    position: relative;
    transition: 0.5s ease;
  }
  .navbar.navbar-opened .navbar__threads div:nth-child(3) {
    display: none;
  }
  .navbar.navbar-opened .navbar__threads div:nth-child(1) {
    transform: rotate(45deg);
  }
  .navbar.navbar-opened .navbar__threads div:nth-child(2) {
    transform: rotate(135deg);
    top: -0.2rem;
  }
  .navbar__threads {
    display: flex;
    width: 6rem;
    padding: 1rem;
    right: 0.5rem;
  }
  .navbar__threads .thread {
    transition: 0.5s ease;
  }
  .navbar .lang-selector {
    height: 4rem;
    top: 0;
    border: none;
    right: 8rem;
    position: fixed;
  }
}
.navbar.closed .navbar__threads {
  transition: 0.5s ease;
  transform: rotate(0);
  background-color: white;
}
.navbar.closed .navbar__threads div:nth-child(3) {
  display: flex;
}
.navbar.closed .navbar__threads div:nth-child(1) {
  transform: rotate(0deg);
  top: 0.25rem;
}
.navbar.closed .navbar__threads div:nth-child(2) {
  transform: rotate(0deg);
  top: -0.3rem;
}
.navbar.closed .navbar__threads .thread {
  transition: 0.5s ease;
}
@media screen and (max-width: 1200px) {
  .sticky {
    display: none !important;
  }
  .navbar-opened {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .navbar-opened .navbar__logo {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 4rem;
    background-color: #002c48;
  }
  .navbar-opened .navbar__threads {
    position: fixed;
    height: 4rem;
    z-index: 2;
  }
  .navbar-opened .navbar__routes {
    height: calc(96vh);
    background-color: #002c48;
    position: absolute;
    left: 0;
    top: 4rem;
    width: 100%;
  }
  .navbar-opened .navbar__routes .navbar-route-line {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: auto;
    min-height: 3rem;
  }
  .navbar-opened .navbar__routes .navbar-route-line .link {
    line-height: 1.4rem;
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    font-size: 1.5rem;
  }
  .navbar-opened .navbar__routes--top {
    margin-bottom: 0;
  }
  .navbar-opened .navbar__routes--top a::after,
  .navbar-opened .navbar__routes--top::after {
    display: none;
  }
  .navbar-opened .navbar__routes--bottom .sub-link {
    font-weight: 400;
    font-size: 1.2rem;
  }
  .navbar-opened .navbar__routes--bottom a::after,
  .navbar-opened .navbar__routes--bottom::after {
    display: none;
  }
  .navbar-opened .navbar__routes--side {
    width: 100%;
    margin-left: 0;
    padding-right: 0;
    background: white;
    font-weight: bold;
  }
  .navbar-opened .navbar__routes--side div {
    color: #002c48;
    font-family: SuzukiPROHeadline;
    padding: 0.7rem 0;
  }
  .navbar-opened .navbar__routes--side::before {
    display: none;
  }
}
@media screen and (max-width: 1200px) and screen and (max-width: 992px) {
  .navbar-opened .navbar__routes .navbar-route-line .link {
    line-height: 1.8rem;
  }
}
.sticky-wrapper {
  opacity: 0;
  transition: 0.2s linear;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  background: white;
  justify-content: flex-start;
  height: 3em;
}
.sticky-wrapper .navbar__logo {
  height: auto;
  padding-right: 3rem;
}
.sticky-wrapper:not(.sticky) {
  display: none;
}
.sticky-wrapper.opened .navbar__threads {
  background: #002c48;
  transform: rotate(0);
}
.sticky-wrapper.opened .navbar__threads div:nth-child(3) {
  display: none;
}
.sticky-wrapper.opened .navbar__threads div:nth-child(1) {
  transform: rotate(45deg);
  top: 0.25rem;
}
.sticky-wrapper.opened .navbar__threads div:nth-child(2) {
  transform: rotate(135deg);
  top: -0.3rem;
}
.sticky-wrapper.opened .navbar__threads .thread {
  background-color: white;
  top: 0;
  position: relative;
  transition: 0.6s ease;
}
.sticky-wrapper.closed .container {
  display: none;
}
.sticky-wrapper.closed .navbar__threads {
  transition: 0.5s ease;
  transform: rotate(0);
}
.sticky-wrapper.closed .navbar__threads div:nth-child(3) {
  display: flex;
}
.sticky-wrapper.closed .navbar__threads div:nth-child(1) {
  transform: rotate(0deg);
  top: 0.25rem;
}
.sticky-wrapper.closed .navbar__threads div:nth-child(2) {
  transform: rotate(0deg);
  top: -0.3rem;
}
.sticky-wrapper.closed .navbar__threads .thread {
  transition: 0.5s ease;
}
.sticky-inner {
  width: 100%;
  display: flex;
  height: 3rem;
  background-color: white;
  flex-direction: row;
  align-items: center;
  -webkit-box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
}
.sticky-inner .navbar__logo img {
  height: 3rem;
}
.sticky-inner .lang-selector {
  right: 6rem;
  position: relative;
  border: 1px solid #002c48;
  padding: 0 0.5rem;
  cursor: pointer;
}
.sticky-inner .navbar__threads {
  display: flex;
  width: 3.5rem;
  right: 0rem;
  padding: 0.7rem;
}
.sticky-inner .navbar__threads .thread {
  background-color: #002c48;
}
.sticky-inner .navbar__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.sticky-inner .navbar__wrapper--routes {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  height: 100%;
  position: relative;
}
.sticky-inner .navbar__wrapper--routes .link {
  color: #002c48;
  font-family: SuzukiPROHeadline;
  font-size: 1.111rem;
  cursor: pointer;
}
.sticky-inner .navbar__wrapper--routes .link a {
  margin: 0;
  text-decoration: none;
}
.sticky-inner .navbar__wrapper--routes .sub-link {
  z-index: 2;
  margin-right: 3rem;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.sticky-inner .navbar__wrapper--routes .sub-link a {
  margin: 0;
  text-decoration: none;
}
.sticky-inner .navbar__wrapper--routes .sub-link:before,
.sticky-inner .navbar__wrapper--routes .sub-link:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #e40012;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}
.sticky-inner .navbar__wrapper--routes .sub-link:hover:before,
.sticky-inner .navbar__wrapper--routes .sub-link:focus:before,
.sticky-inner .navbar__wrapper--routes .sub-link.active:before,
.sticky-inner .navbar__wrapper--routes .sub-link.active:after {
  visibility: visible;
  transform: scaleX(1);
}
.sticky-inner .navbar__wrapper--routes .sub-link > * {
  font-size: 1.111rem;
}
.sticky-inner .navbar__wrapper--routes__main {
  overflow-y: overlay;
  position: relative;
  z-index: 9999;
}
.sticky-inner .navbar__wrapper--routes__main.closed {
  position: absolute;
  height: 0;
  top: 4rem;
  right: 0;
  left: 0;
  opacity: 0;
}
.sticky-inner .navbar__wrapper--routes__main.opened {
  transition: 0.5s ease;
  height: calc(97vh);
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  top: 3rem;
  right: 0;
  left: 0;
  align-items: center;
  background: rgba(0, 44, 72, 0.9);
  color: white;
}
.sticky-inner .navbar__wrapper--routes__main.opened .main-link a {
  font-size: 3rem;
  font-family: SuzukiPROHeadline;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  display: flex;
  cursor: pointer;
  color: white !important;
  text-decoration: none !important;
}
.sticky-inner .navbar__wrapper--routes__main.opened .main-link a:before,
.sticky-inner .navbar__wrapper--routes__main.opened .main-link a:after {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #e40012;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}
.sticky-inner .navbar__wrapper--routes__main.opened .main-link a:hover:before,
.sticky-inner .navbar__wrapper--routes__main.opened .main-link a:focus:before,
.sticky-inner .navbar__wrapper--routes__main.opened .main-link a:hover:after,
.sticky-inner .navbar__wrapper--routes__main.opened .main-link a:focus:after {
  width: 350px;
  bottom: 50%;
  transform: scaleX(1);
  visibility: visible;
}
.sticky-inner .navbar__wrapper--routes__main.opened .main-link a:before {
  width: 350px;
  bottom: 50%;
}
.sticky-inner .navbar__wrapper--routes__main.opened .main-link a:after {
  width: 350px;
  bottom: 50%;
  right: 0;
  left: auto;
}
.sticky-inner .navbar__wrapper--routes__main.opened .main-link a.active {
  font-size: 3.5rem;
}
.sticky-inner .navbar__wrapper--routes__main.opened .main-link a.active:before,
.sticky-inner .navbar__wrapper--routes__main.opened .main-link a.active:after {
  transform: scaleX(1);
  visibility: visible;
}
.sticky-inner .navbar__wrapper--routes__side {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  right: 8rem;
}
.sticky-inner .navbar__wrapper--routes__side div {
  margin: 0 1rem;
}
.sticky-inner .navbar__wrapper--routes__side div img {
  filter: invert(90%) sepia(50%) saturate(3000%) hue-rotate(200deg) brightness(60%) contrast(40%);
}
.sticky {
  opacity: 1 !important;
  z-index: 1000001;
  width: 100%;
  transition: 0.2s linear;
}
