.il-text-input-wrapper {
  margin-bottom: 2rem;
}
.il-text-input-wrapper .input-label {
  color: #004d9f;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 3px;
  padding-bottom: 10px;
}
@media (max-width: 992px) {
  .il-text-input-wrapper .input-label {
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .il-text-input-wrapper .input-label {
    font-size: 10px;
  }
}
.il-text-input-wrapper .il-text-input {
  width: 100%;
  border: none;
  outline: none;
  background: #f0f0f0;
  border: 1px solid #d5d5d5;
  font-size: 18px;
  height: 50px;
  color: #002c48;
  padding: 0 15px;
}
.il-text-input-wrapper .il-text-input:hover {
  border-color: #004d9f;
}
