.intro-section-component {
  background-position: left top;
}
.intro-section-component .intro-section-logo-container {
  margin-top: 3rem;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 3rem;
  margin-bottom: -60px;
}
.intro-section-component .intro-section-logo-container__logo {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  padding: 0 1rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.intro-section-component .bike-inner-background {
  background-repeat: no-repeat;
  padding-top: 60px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.intro-section-component .intro-section-main-bike-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1200px;
}
.intro-section-component .intro-section-main-bike-container__main-bike-image-container {
  margin: 0 auto;
}
.intro-section-component .intro-section-main-bike-container__main-bike-image {
  max-width: 100%;
  height: auto;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.intro-section-component .intro-section-main-bike-container__bike-slogan {
  color: #002c48;
  font-weight: 1000;
  font-size: 2.5rem;
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 2rem;
  font-family: SuzukiPROHeadline;
}
.intro-section-component .intro-section-main-bike-container__bike-introduction {
  padding: 0 2rem;
  min-height: 450px;
}
.intro-section-component .intro-section-main-bike-container__bike-introduction p {
  color: #002c48;
  font-size: 1.1rem;
  line-height: 1.3rem;
}
.intro-section-component .intro-section-main-bike-container__left {
  flex: 0 0 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.intro-section-component .intro-section-main-bike-container__right {
  flex: 0 0 35%;
  padding-top: 4rem;
}
.intro-section-component .bike-color-picker {
  display: flex;
  align-items: center;
}
.intro-section-component .bike-color-picker__inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #002c48;
  flex-direction: column;
  margin-right: 0.8rem;
}
.intro-section-component .bike-color-picker__title {
  color: #002c48;
  text-transform: uppercase;
  font-size: 0.9rem;
  margin-right: 2rem;
  letter-spacing: 2px;
}
.intro-section-component .bike-color-picker__dot {
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  height: 50px;
  width: 50px;
  margin-bottom: 5px;
  cursor: pointer;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
  transition: all 0.1s linear;
}
.intro-section-component .bike-color-picker__dot:hover {
  box-shadow: 0 0 5px 7px rgba(0, 0, 0, 0.2);
}
.intro-section-component .intro-section-licence-category__title {
  color: #002c48;
  text-transform: uppercase;
  font-size: 0.9rem;
  text-align: center;
  padding-top: 1.5rem;
  letter-spacing: 2px;
}
.intro-section-component .intro-section-licence-category__container {
  display: flex;
  justify-content: center;
}
.intro-section-component .intro-section-licence-category__item-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  height: 50px;
  width: 50px;
  color: #002c48;
  font-size: 1.5rem;
  font-weight: 900;
  margin: 0.5rem;
  border-radius: 7px;
  overflow: hidden;
  margin-bottom: 2rem;
}
.intro-section-component .intro-section-licence-category__no-licence {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #002c48;
  font-size: 20px;
  font-weight: 900;
  margin: 0 0.5rem 0.5rem 0.5rem;
  padding: 0 15px;
  border-radius: 7px;
  overflow: hidden;
  margin-bottom: 2rem;
}
.intro-section-component .intro-section-licence-category__item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: 1px solid #002c48;
  height: 50px;
  width: 50px;
  color: #002c48;
  font-size: 1.5rem;
  font-weight: 900;
  text-align: center;
}
.intro-section-component .intro-section-licence-category__item--small {
  font-size: 1.1rem;
}
.intro-section-component .intro-section-licence-category__item--extra-small {
  font-size: 0.6rem;
}
@media (max-width: 1700px) {
  .intro-section-component .bike-inner-background {
    background-position-x: -200px;
  }
}
@media (max-width: 1300px) {
  .intro-section-component .bike-inner-background {
    background-position-x: -300px;
  }
}
@media (max-width: 1200px) {
  .intro-section-component .bike-inner-background {
    background-position-x: -200px;
    background-position-y: -200px;
  }
  .intro-section-component .intro-section-main-bike-container__left {
    flex: 0 0 100%;
    justify-content: center;
    padding: 2rem;
  }
  .intro-section-component .intro-section-main-bike-container__right {
    flex: 0 0 100%;
  }
  .intro-section-component .intro-section-main-bike-container__bike-slogan {
    padding: 0 2rem 2rem;
    font-size: 3rem;
    max-width: 800px;
    margin: 0 auto;
  }
  .intro-section-component .intro-section-main-bike-container__bike-introduction {
    max-width: 800px;
    margin: 0 auto;
    min-height: 300px;
  }
  .intro-section-component .intro-section-main-bike-container__bike-introduction p {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
  .intro-section-component .intro-section-logo-container {
    max-width: 900px;
    margin: 0 auto -40px;
  }
  .intro-section-component .bike-color-picker {
    max-width: 900px;
    margin: 0 auto 2rem;
  }
  .intro-section-component .bike-color-picker__title {
    font-size: 1.5rem;
  }
  .intro-section-component .intro-section-licence-category__title {
    font-size: 1.5rem;
  }
  .intro-section-component .intro-section-licence-category__container {
    padding-bottom: 50px;
  }
  .intro-section-component .intro-section-licence-category__item {
    font-size: 30px;
  }
  .intro-section-component .intro-section-licence-category__item--small {
    font-size: 20px;
  }
  .intro-section-component .intro-section-licence-category__item--extra-small {
    font-size: 10px;
  }
}
@media (max-width: 700px) {
  .intro-section-component .bike-inner-background {
    padding-top: 10px;
    background-image: none !important;
  }
  .intro-section-component .intro-section-logo-container {
    margin: 0 auto -20px;
  }
  .intro-section-component .intro-section-licence-category__no-licence {
    font-size: 2rem;
  }
}
@media (max-width: 500px) {
  .intro-section-component .intro-section-main-bike-container__bike-slogan {
    padding: 0 2rem 2rem;
    font-size: 4rem;
    max-width: 800px;
    margin: 0 auto;
  }
  .intro-section-component .intro-section-main-bike-container__bike-introduction p {
    font-size: 2rem;
    line-height: 2.6rem;
  }
  .intro-section-component .intro-section-logo-container {
    margin: 0 auto -10px;
  }
  .intro-section-component .bike-color-picker {
    margin-top: 2rem;
  }
  .intro-section-component .bike-color-picker__title {
    font-size: 1.8rem;
    margin-right: 2rem;
  }
  .intro-section-component .bike-color-picker__dot {
    height: 35px;
    width: 35px;
  }
  .intro-section-component .intro-section-licence-category__title {
    font-size: 1.8rem;
  }
}
