.bike-properties-tab-section-component__content {
  max-width: 1200px;
  margin: 0 auto;
}
.bike-properties-tab-section-component .bike-page-tabs-container {
  display: flex;
  margin-bottom: 20px;
}
.bike-properties-tab-section-component .bike-page-tabs-container__placeholder {
  border-top: 1px solid #002c48;
  min-height: 1px;
  flex-grow: 1;
  margin-left: 10px;
}
.bike-properties-tab-section-component .bike-page-tabs-container__tab {
  border-right: 1px solid #002c48;
  border-bottom: 1px solid #002c48;
  transform: skewX(-25deg);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  margin-right: 15px;
  padding: 0 30px;
  transition: all 0.1s linear;
  cursor: pointer;
}
.bike-properties-tab-section-component .bike-page-tabs-container__tab.active {
  background-color: #002c48;
}
.bike-properties-tab-section-component .bike-page-tabs-container__tab.active .bike-page-tabs-container__tab--inside {
  color: white;
}
.bike-properties-tab-section-component .bike-page-tabs-container__tab--inside {
  transform: skewX(25deg);
  color: #002c48;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
}
.bike-properties-tab-section-component .bike-page-tabs-container__tab:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bike-properties-tab-section-component .bike-page-tabs-container__tab:hover .bike-page-tabs-container__tab--inside {
  color: #002c48;
}
.bike-properties-tab-section-component .bike-page-tabs-container__tab:hover.active {
  background-color: #003c62;
  background-image: none;
}
.bike-properties-tab-section-component .bike-page-tabs-container__tab:hover.active .bike-page-tabs-container__tab--inside {
  color: #ffffff;
}
.bike-properties-tab-section-component .info-toggle-container {
  overflow: hidden;
  transition: all 0.2s linear;
  color: #002c48;
  opacity: 0;
  min-height: 0;
  height: 0;
}
.bike-properties-tab-section-component .info-toggle-container.open {
  margin-bottom: 30px;
  opacity: 1;
  min-height: 100px;
  height: auto;
}
.bike-properties-tab-section-component .bike-additional-properties-container {
  background: #ebebeb;
}
.bike-properties-tab-section-component .bike-additional-properties-container--download {
  padding-top: 40px;
  padding-bottom: 40px;
}
.bike-properties-tab-section-component .bike-additional-prop-row {
  padding: 20px 40px 20px 40px;
  font-size: 14px;
  position: relative;
  display: inline-flex;
}
.bike-properties-tab-section-component .bike-additional-prop-row:nth-child(2n) {
  background: #f7f7f7;
}
.bike-properties-tab-section-component .bike-additional-prop-row:nth-child(2n + 1) {
  background: #ebebeb;
}
.bike-properties-tab-section-component .bike-additional-prop-row__image {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: auto;
}
.bike-properties-tab-section-component .bike-additional-prop-row__left {
  width: 50%;
  display: inline-block;
  font-family: SuzukiPROHeadline;
  font-size: 19px;
}
.bike-properties-tab-section-component .bike-additional-prop-row__left sup,
.bike-properties-tab-section-component .bike-additional-prop-row__left sub {
  font-family: SuzukiPROHeadline;
}
.bike-properties-tab-section-component .bike-additional-prop-row__right {
  width: 50%;
  padding-left: 10px;
  display: inline-block;
  font-size: 21px;
}
.bike-properties-tab-section-component .bike-additional-prop-row__right--comment {
  font-size: 15px;
}
.bike-properties-tab-section-component .download-document {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 40px;
}
.bike-properties-tab-section-component .download-document__image {
  max-width: 40px;
  width: 100%;
  margin-right: 20px;
  height: auto;
}
.bike-properties-tab-section-component .download-document__name {
  font-size: 20px;
  font-family: SuzukiHeadline;
}
@media (max-width: 1200px) {
  .bike-properties-tab-section-component .bike-page-tabs-container {
    padding: 0 20px;
  }
  .bike-properties-tab-section-component .bike-page-tabs-container__tab:hover {
    background: transparent;
  }
}
@media (max-width: 1100px) {
  .bike-properties-tab-section-component .bike-page-tabs-container {
    padding: 0 40px;
  }
}
@media (max-width: 800px) {
  .bike-properties-tab-section-component .bike-page-tabs-container {
    padding: 0 20px;
  }
}
@media (max-width: 700px) {
  .bike-properties-tab-section-component .bike-page-tabs-container {
    flex-direction: column;
  }
  .bike-properties-tab-section-component .bike-page-tabs-container__placeholder {
    display: none;
  }
  .bike-properties-tab-section-component .bike-page-tabs-container__tab {
    border-right: none;
    transform: none;
    margin-right: 0;
  }
  .bike-properties-tab-section-component .bike-page-tabs-container__tab--inside {
    transform: none;
  }
  .bike-properties-tab-section-component .bike-page-tabs-container__tab:hover {
    background-image: none;
  }
}
