.checkbox-container {
  position: relative;
  padding-left: 3rem;
  margin-bottom: 0;
  height: auto;
  min-height: 2em;
  display: inline;
  align-items: center;
  cursor: pointer;
  font-size: 1.3em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #002c48;
  -webkit-transition: color 0.5s linear;
  -moz-transition: color 0.5s linear;
  -ms-transition: color 0.5s linear;
  -o-transition: color 0.5s linear;
  transition: color 0.5s linear;
}
@media (max-width: 992px) {
  .checkbox-container {
    font-size: 12px;
  }
}
.checkbox-container a {
  margin: 0 5px;
  color: #002c48 !important;
  text-decoration: underline;
  font-weight: 900;
}
.checkbox-container .checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-container .check-mark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 1.5rem;
  width: 1.5rem;
  border: 2px solid #002c48;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.checkbox-container .check-mark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-container .checkbox-input:checked ~ .check-mark:after {
  display: block;
}
.checkbox-container .checkbox-input:checked ~ .check-mark {
  background-color: #e40012;
  border-color: #002c48;
}
.checkbox-container .check-mark:after {
  left: 0.5rem;
  top: 0.1rem;
  width: 0.4rem;
  height: 0.9rem;
  border-style: solid;
  border-width: 0 0.3rem 0.3rem 0;
  color: white;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.checkbox-container:hover .check-mark {
  border-color: #002c48;
}
.disabled-checkbox {
  cursor: not-allowed;
}
.disabled-checkbox .checkbox-container {
  cursor: not-allowed;
}
