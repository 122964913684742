.owners-view {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5rem 1rem;
}
.owners-view > * {
  color: #002c48;
}
.owners-view__item {
  max-width: 30%;
  margin: 1rem 0;
}
.owners-view__item:nth-child(2) {
  margin: 0 1rem;
}
.owners-view__item.show.i0.hi0 .cont,
.owners-view__item.show.i1.hi1 .cont,
.owners-view__item.show.i2.hi2 .cont {
  z-index: 99;
  position: relative;
}
.owners-view__item.show.i0.hi0 .cont img,
.owners-view__item.show.i1.hi1 .cont img,
.owners-view__item.show.i2.hi2 .cont img {
  filter: brightness(50%);
  -webkit-transition: 0.25s all ease;
  -moz-transition: 0.25s all ease;
  -ms-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
.owners-view__item--container {
  position: relative;
  transition: all 0.25s ease;
  padding: 0 0;
}
.owners-view__item--container.cont:hover {
  transform: scale(1.03);
  -webkit-transition: 0.25s all ease;
  -moz-transition: 0.25s all ease;
  -ms-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
.owners-view__item--container.cont:hover .number {
  top: 1.5rem;
  bottom: auto;
}
.owners-view__item--container.cont:hover .title {
  top: 7rem;
  bottom: auto;
}
.owners-view__item--container.cont:hover .desc {
  display: flex;
  max-width: 70%;
}
.owners-view__item--container.cont:hover .button {
  display: flex;
  max-width: 50%;
  margin-top: 1rem;
}
.owners-view__item--container.cont:hover .button a {
  color: white;
  border: 2px solid white;
  padding: 0.5rem 3rem;
}
.owners-view__item--container .image img {
  max-width: 100%;
  width: 100%;
}
.owners-view__item--container .number,
.owners-view__item--container .title {
  position: absolute;
  width: 90%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  color: white;
}
.owners-view__item--container .number {
  bottom: 6rem;
  font-size: 7rem;
  font-family: SuzukiPROHeadline;
  line-height: 5rem;
}
.owners-view__item--container .title {
  border-top: 3px solid rgba(255, 255, 255, 0.3);
  bottom: 1.5rem;
  font-family: SuzukiPROHeadline;
  font-size: 2rem;
}
.owners-view__item--container .desc,
.owners-view__item--container .button {
  display: none;
  font-size: 1rem;
}
@media screen and (max-width: 1200px) {
  .owners-view .cont img {
    filter: brightness(0.5);
  }
  .owners-view .cont .number {
    top: 2rem;
    bottom: auto;
    transition: 0.25s ease;
  }
  .owners-view .cont .title {
    top: 8rem;
    bottom: auto;
  }
  .owners-view .cont .desc {
    display: flex;
    max-width: 70%;
  }
  .owners-view .cont .button {
    display: flex;
    max-width: 50%;
    margin-top: 1rem;
  }
  .owners-view .cont .button a {
    color: white;
    border: 2px solid white;
    padding: 0.5rem 3rem;
  }
  .owners-view__item .overlay.show {
    display: none !important;
  }
  .owners-view__item:nth-child(2) {
    margin: 2rem 0;
  }
  .owners-view__item--container .title {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .owners-view__item--container .desc {
    font-size: 1.5rem !important;
    line-height: 1.5rem !important;
  }
}
@media screen and (max-width: 992px) {
  .owners-view .cont .title {
    top: 12rem !important;
  }
  .owners-view__item {
    max-width: 90%;
  }
  .owners-view__item--container .number {
    margin-top: 2rem;
    font-size: 11rem;
  }
  .owners-view__item--container .title {
    font-size: 4.5rem;
    line-height: 5.5rem;
  }
  .owners-view__item--container .desc {
    font-size: 3.5rem !important;
    line-height: 3.5rem !important;
  }
  .owners-view__item--container .button a {
    font-size: 2rem;
  }
}
