.bike-main-slider-component {
  position: relative;
}
.bike-main-slider-component .main-slider-bg-line {
  display: block;
  content: " ";
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 20;
}
.bike-main-slider-component .top-bg-line {
  height: 12.2%;
  top: 0;
}
.bike-main-slider-component .bottom-bg-line {
  bottom: 55px;
  height: 15%;
}
.bike-main-slider-component .bms-img {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.bike-main-slider-component .bike-main-slider-frame {
  position: relative;
  height: 0;
  padding-top: 41.67%;
  overflow: hidden;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  background-color: #002c48;
}
.bike-main-slider-component .bike-mainslider-iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}
.bike-main-slider-component .bike-mainslider-iframe-container__iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.bike-main-slider-component .bike-main-slider-item {
  grid-area: 1 / 1;
  overflow: hidden;
  position: relative;
  margin-bottom: 5rem;
  width: 100%;
}
.bike-main-slider-component .bike-main-slider-item__image {
  width: 100%;
  height: auto;
  z-index: 22;
}
.bike-main-slider-component .bike-main-slider-item__image--video {
  width: 60%;
  display: block;
  margin: 0 auto;
  opacity: 0.5;
}
.bike-main-slider-component .bike-main-slider-item__image--play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  cursor: pointer !important;
  -webkit-transition: opacity 0.5s linear;
  -moz-transition: opacity 0.5s linear;
  -ms-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}
.bike-main-slider-component .bike-main-slider-item__image--play-button::before {
  -webkit-transition: transform 0.3s linear;
  -moz-transition: transform 0.3s linear;
  -ms-transition: transform 0.3s linear;
  -o-transition: transform 0.3s linear;
  transition: transform 0.3s linear;
  font-family: FontAwesome;
  content: "\f144";
  display: block;
  font-size: 5rem;
}
.bike-main-slider-component .bike-main-slider-item__image--play-button:hover::before {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
}
.bike-main-slider-component .bike-main-slider-item .iframe {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
}
.bike-main-slider-component .video-container {
  z-index: 10;
}
.bike-main-slider-component .bike-main-slider-navigation {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 70px;
  margin-top: -60px;
  position: relative;
  z-index: 25;
}
.bike-main-slider-component .bike-main-slider-navigation--modal-open {
  display: none;
}
.bike-main-slider-component .bike-main-slider-navigation__container {
  display: inline-flex;
  justify-content: center;
  width: auto;
  position: relative;
}
.bike-main-slider-component .bike-main-slider-navigation__back-arrow-button {
  height: 100px;
  width: 50px;
  display: inline-block;
  background-color: #005aab;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: skew(-16deg, 0);
  overflow: hidden;
  cursor: pointer;
  transition: all 100ms linear;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.bike-main-slider-component .bike-main-slider-navigation__back-arrow-button:hover {
  background-color: #0075de;
  margin-top: -5px;
}
.bike-main-slider-component .bike-main-slider-navigation__back-arrow-button--next {
  margin-left: 15px;
}
.bike-main-slider-component .bike-main-slider-navigation__back-arrow-button--prev {
  margin-right: 7px;
}
.bike-main-slider-component .bike-main-slider-navigation__back-arrow-image {
  transform: skew(16deg, 0);
  -webkit-transform: skew(16deg, 0);
  -moz-transform: skew(16deg, 0);
  -ms-transform: skew(16deg, 0);
  -o-transform: skew(16deg, 0);
}
.bike-main-slider-component .bike-main-slider-navigation__back-arrow-video {
  transform: skew(16deg, 0);
  -webkit-transform: skew(16deg, 0);
  -moz-transform: skew(16deg, 0);
  -ms-transform: skew(16deg, 0);
  -o-transform: skew(16deg, 0);
}
.bike-main-slider-component .bike-main-slider-navigation__indicator {
  transform: skew(-16deg, 0);
  -webkit-transform: skew(-16deg, 0);
  -moz-transform: skew(-16deg, 0);
  -ms-transform: skew(-16deg, 0);
  -o-transform: skew(-16deg, 0);
  overflow: hidden;
  width: 100px;
  height: 100px;
  margin-top: 15px;
  background-color: #002c48;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.bike-main-slider-component .bike-main-slider-navigation__indicator:hover .bike-main-slider-navigation__indicator-image {
  transform: scale(1.2, 1.2) skew(16deg, 0);
  -webkit-transform: scale(1.2, 1.2) skew(16deg, 0);
  -moz-transform: scale(1.2, 1.2) skew(16deg, 0);
  -ms-transform: scale(1.2, 1.2) skew(16deg, 0);
  -o-transform: scale(1.2, 1.2) skew(16deg, 0);
}
.bike-main-slider-component .bike-main-slider-navigation__indicator:hover .bike-main-slider-navigation__indicator-image--play-button {
  transform: scale(1.2, 1.2) skew(16deg, 0);
  -webkit-transform: scale(1.2, 1.2) skew(16deg, 0);
  -moz-transform: scale(1.2, 1.2) skew(16deg, 0);
  -ms-transform: scale(1.2, 1.2) skew(16deg, 0);
  -o-transform: scale(1.2, 1.2) skew(16deg, 0);
}
.bike-main-slider-component .bike-main-slider-navigation__indicator-image {
  transform: skew(16deg, 0);
  -webkit-transform: skew(16deg, 0);
  -moz-transform: skew(16deg, 0);
  -ms-transform: skew(16deg, 0);
  -o-transform: skew(16deg, 0);
  width: auto;
  height: 100%;
  -webkit-transition: all 100ms linear;
  -moz-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  -o-transition: all 100ms linear;
  transition: all 100ms linear;
  cursor: pointer;
}
.bike-main-slider-component .bike-main-slider-navigation__indicator-image--play-button {
  position: absolute;
  top: 35px;
  left: 40%;
  height: auto;
  width: 30px;
  transform: skew(16deg, 0);
  -webkit-transform: skew(16deg, 0);
  -moz-transform: skew(16deg, 0);
  -ms-transform: skew(16deg, 0);
  -o-transform: skew(16deg, 0);
  -webkit-transition: all 100ms linear;
  -moz-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  -o-transition: all 100ms linear;
  transition: all 100ms linear;
  z-index: 2;
}
.bike-main-slider-component .bike-main-slider-navigation__indicator-video {
  transform: skew(16deg, 0);
  -webkit-transform: skew(16deg, 0);
  -moz-transform: skew(16deg, 0);
  -ms-transform: skew(16deg, 0);
  -o-transform: skew(16deg, 0);
  width: auto;
  height: 100%;
  -webkit-transition: all 100ms linear;
  -moz-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  -o-transition: all 100ms linear;
  transition: all 100ms linear;
  cursor: pointer;
}
.bike-main-slider-component .bike-main-slider-navigation__indicator-video:hover {
  transform: scale(1.2, 1.2) skew(16deg, 0);
  -webkit-transform: scale(1.2, 1.2) skew(16deg, 0);
  -moz-transform: scale(1.2, 1.2) skew(16deg, 0);
  -ms-transform: scale(1.2, 1.2) skew(16deg, 0);
  -o-transform: scale(1.2, 1.2) skew(16deg, 0);
}
.bike-main-slider-component .bike-main-slider-navigation__shadow {
  display: block;
  position: absolute;
  bottom: -30px;
  width: 100%;
  height: 33px;
  margin-left: -15px;
}
.bike-main-slider-component .main-slider-trasition-group {
  display: grid;
  grid: "center" 1fr;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.bike-main-slider-component .main-slider-trasition-group.main-slider-forward .slide-enter {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  opacity: 0;
}
.bike-main-slider-component .main-slider-trasition-group.main-slider-forward .slide-enter-active {
  -webkit-transition: transform 0.3s linear, opacity 0.3s linear;
  -moz-transition: transform 0.3s linear, opacity 0.3s linear;
  -ms-transition: transform 0.3s linear, opacity 0.3s linear;
  -o-transition: transform 0.3s linear, opacity 0.3s linear;
  transition: transform 0.3s linear, opacity 0.3s linear;
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  opacity: 1;
}
.bike-main-slider-component .main-slider-trasition-group.main-slider-forward .slide-exit {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  opacity: 1;
}
.bike-main-slider-component .main-slider-trasition-group.main-slider-forward .slide-exit-active {
  opacity: 0;
  -webkit-transition: transform 0.3s linear, opacity 0.3s linear;
  -moz-transition: transform 0.3s linear, opacity 0.3s linear;
  -ms-transition: transform 0.3s linear, opacity 0.3s linear;
  -o-transition: transform 0.3s linear, opacity 0.3s linear;
  transition: transform 0.3s linear, opacity 0.3s linear;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
}
.bike-main-slider-component .main-slider-trasition-group.main-slider-backward .slide-enter {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  opacity: 0;
}
.bike-main-slider-component .main-slider-trasition-group.main-slider-backward .slide-enter-active {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  -webkit-transition: transform 0.3s linear, opacity 0.3s linear;
  -moz-transition: transform 0.3s linear, opacity 0.3s linear;
  -ms-transition: transform 0.3s linear, opacity 0.3s linear;
  -o-transition: transform 0.3s linear, opacity 0.3s linear;
  transition: transform 0.3s linear, opacity 0.3s linear;
  opacity: 1;
}
.bike-main-slider-component .main-slider-trasition-group.main-slider-backward .slide-exit {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  opacity: 1;
}
.bike-main-slider-component .main-slider-trasition-group.main-slider-backward .slide-exit-active {
  opacity: 0;
  -webkit-transition: transform 0.3s linear, opacity 0.3s linear;
  -moz-transition: transform 0.3s linear, opacity 0.3s linear;
  -ms-transition: transform 0.3s linear, opacity 0.3s linear;
  -o-transition: transform 0.3s linear, opacity 0.3s linear;
  transition: transform 0.3s linear, opacity 0.3s linear;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
}
@media (max-width: 1000px) {
  .bike-main-slider-component .bike-main-slider-item__image--play-button {
    max-width: 150px;
    height: auto;
  }
}
@media (max-width: 700px) {
  .bike-main-slider-component .bike-main-slider-item__image--play-button {
    max-width: 100px;
    height: auto;
  }
  .bike-main-slider-component .bike-main-slider-navigation__back-arrow-button:hover {
    background-color: #005aab;
    margin-top: 0;
  }
}
@media (max-width: 500px) {
  .bike-main-slider-component .bottom-bg-line {
    bottom: 30px;
  }
  .bike-main-slider-component .bike-main-slider-frame {
    padding-top: 66%;
  }
  .bike-main-slider-component .bike-mainslider-iframe-container {
    padding-top: 66%;
  }
  .bike-main-slider-component .bike-main-slider-item__image {
    width: 160%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }
  .bike-main-slider-component .bike-main-slider-item__image--play-button {
    max-width: 100px;
    height: auto;
  }
  .bike-main-slider-component .bike-main-slider-navigation {
    margin-top: -30px;
    margin-bottom: 20px;
  }
  .bike-main-slider-component .bike-main-slider-navigation__back-arrow-button {
    height: 60px;
  }
  .bike-main-slider-component .bike-main-slider-navigation__indicator {
    display: none;
  }
}
