@font-face {
  font-family: SuzukiPRORegular;
  src: url('/assets/SuzukiPRORegular.otf');
}
@font-face {
  font-family: SuzukiRegular;
  src: url('/assets/SuzukiPRORegular.otf');
}
@font-face {
  font-family: SuzukiPROHeadline;
  src: url('/assets/SuzukiPROHeadline.otf');
}
@font-face {
  font-family: SuzukiHeadline;
  src: url('/assets/SuzukiPROHeadline.otf');
}
@font-face {
  font-family: SuzukiBold;
  src: url('/assets/SuzukiPROBold.otf');
}
@font-face {
  font-family: SuzukiBold;
  src: url('/assets/SuzukiPROBold.otf');
}
body {
  background-image: url('../../../../Assets/BG.png');
}
.offer-view {
  width: 100%;
}
.offer {
  color: white;
}
.offer__container {
  position: relative;
  height: 100vh;
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.offer__container .filter {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.95;
}
.offer__container--prev-page {
  color: white;
  position: absolute;
  left: 0rem;
  transform: rotate(270deg);
  top: 10rem;
  width: 150px;
  text-align: left;
  z-index: 2;
}
.offer__container--prev-page div {
  color: white !important;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
.offer__container--next-page {
  color: white;
  position: absolute;
  z-index: 2;
  left: 0rem;
  transform: rotate(270deg);
  bottom: 10rem;
  width: 150px;
  text-align: right;
}
.offer__container--next-page div {
  color: white !important;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
.offer__container--row {
  display: flex;
}
.offer__container--row:before,
.offer__container--row:after {
  display: flex !important;
}
.offer__container--row__image img {
  width: 100%;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.offer__container--row__image img:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.offer__container--row.no-image .offer__container--row__desc--short span {
  font-family: SuzukiPROHeadline !important;
  font-size: 4rem !important;
}
.offer__container--row__desc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.offer__container--row__desc--quote span {
  pointer-events: none;
  font-size: 150rem;
  letter-spacing: -10rem;
  position: absolute;
  left: -5rem;
  top: -150rem;
  color: rgba(255, 255, 255, 0.1);
}
.offer__container--row__desc p {
  margin: 0;
}
.offer__container--row__desc--logo {
  margin-bottom: 4rem;
}
.offer__container--row__desc--logo,
.offer__container--row__desc--logo img {
  max-width: 100%;
}
.offer__container--row__desc--title {
  font-size: 4.444rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 5rem;
  text-align: center;
  font-family: SuzukiPROHeadline;
  color: rgba(255, 255, 255, 0.8) !important;
  margin-bottom: 3rem;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
  display: block;
}
.offer__container--row__desc--title::hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.offer__container--row__desc--short {
  text-align: center;
}
.offer__container--row__desc--button {
  padding: 10px 120px;
  background: #005aab;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  margin-top: 3rem;
  position: relative;
  font-size: 1.111rem;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  -webkit-box-shadow: 5px 5px 0px 1px rgba(218, 41, 28, 0.2);
  -moz-box-shadow: 5px 5px 0px 1px rgba(218, 41, 28, 0.2);
  box-shadow: 5px 5px 0px 1px rgba(218, 41, 28, 0.2);
  -webkit-transition: linear 0.3s;
  -moz-transition: linear 0.3s;
  -ms-transition: linear 0.3s;
  -o-transition: linear 0.3s;
  transition: linear 0.3s;
}
.offer__container--row__desc--button:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: #da291c;
  position: relative;
  top: 5px;
  left: 5px;
}
@media only screen and (max-width: 1200px) and (min-width: 376px) {
  .offer__container--item {
    padding-top: 5rem;
    max-width: 100%;
  }
  .offer__container--row {
    display: inherit;
  }
  .offer__container--row__image {
    text-align: center;
  }
  .offer__container--row__image img {
    width: auto;
    max-height: 400px;
    height: auto;
    max-width: 100%;
  }
  .offer__container--row__desc {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .offer__container--row__desc--quote span {
    font-size: 100rem;
    top: -100rem;
  }
  .offer__container--row__desc--title {
    margin-bottom: 1rem;
  }
  .offer__container--row__desc--logo {
    margin-bottom: 1rem;
  }
  .offer__container--row__desc--button {
    margin-top: 1rem;
    width: 100%;
  }
}
@media only screen and (max-width: 375px) {
  .offer__container {
    height: 90vh;
  }
  .offer__container--item {
    padding-top: 2rem !important;
    max-width: 100%;
  }
  .offer__container--row {
    display: inherit;
  }
  .offer__container--row.right img {
    padding-top: 3rem;
  }
  .offer__container--row__image {
    text-align: center;
  }
  .offer__container--row__image img {
    width: 80%;
  }
  .offer__container--row__desc {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .offer__container--row__desc--quote span {
    font-size: 100rem;
    top: -100rem;
  }
  .offer__container--row__desc--title {
    font-size: 2em;
    margin-bottom: 1rem;
  }
  .offer__container--row__desc--logo {
    margin-bottom: 1rem;
  }
  .offer__container--row__desc--button {
    margin-top: 1rem;
    width: 85%;
    font-size: 1.5rem;
  }
}
