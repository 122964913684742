.reseller-promo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 500px;
}
.reseller-promo__border {
  display: block;
  width: 100%;
  height: 10px;
  background-color: rgba(99, 97, 97, 0.66);
  border: none !important;
}
.reseller-promo .content-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  border-left: 10px solid rgba(99, 97, 97, 0.66);
  border-right: 10px solid rgba(99, 97, 97, 0.66);
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
}
.reseller-promo__background {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  max-height: 500px;
}
.reseller-promo__background--image {
  width: 100%;
  height: auto;
}
.reseller-promo__logo {
  margin-top: -32px;
  display: flex;
  align-items: center;
  width: 100%;
}
.reseller-promo__logo img {
  height: 74px;
  width: auto;
  padding: 0 20px;
}
.reseller-promo__title {
  padding-top: 20px;
  font-size: 60px;
  margin-bottom: 10px;
  font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
}
.reseller-promo__sub-title {
  margin-top: 0;
  padding-bottom: 40px;
  font-size: 20px;
  text-align: center;
}
.reseller-promo__button-container {
  margin-bottom: -37px;
  display: flex;
  align-items: center;
  width: 100%;
}
.reseller-promo__button-container .xa-button {
  min-width: 450px;
  margin: 0 20px;
}
@media screen and (max-width: 1250px) {
  .reseller-promo .content-container {
    max-width: 968px;
  }
  .reseller-promo__button-container {
    margin-bottom: -37px;
  }
}
@media screen and (max-width: 1170px) {
  .reseller-promo__button-container {
    margin-bottom: -28px;
  }
}
@media screen and (max-width: 992px) {
  .reseller-promo .content-container {
    max-width: 560px;
  }
  .reseller-promo__title {
    padding-top: 0;
    margin-top: 10px;
    font-size: 30px;
  }
  .reseller-promo__sub-title {
    margin-bottom: 0;
    padding-bottom: 10px;
    font-size: 16px;
  }
  .reseller-promo__button-container {
    margin-bottom: -13px;
  }
  .reseller-promo__button-container .xa-button {
    margin: 0 20px;
    padding: 10px 20px;
    font-size: 12px;
    min-width: 250px;
  }
}
@media screen and (max-width: 730px) {
  .reseller-promo {
    overflow-x: hidden;
  }
  .reseller-promo .content-container {
    max-width: 314px;
    border-right: 3px solid rgba(99, 97, 97, 0.66);
    border-left: 3px solid rgba(99, 97, 97, 0.66);
  }
  .reseller-promo__border {
    height: 3px;
  }
  .reseller-promo__background {
    min-height: 300px;
    height: auto;
    max-height: 300px;
    max-width: unset;
    width: auto;
  }
  .reseller-promo__background--image {
    min-height: 300px;
    max-height: 300px;
    max-width: unset;
    height: auto;
    width: auto;
  }
  .reseller-promo__logo {
    margin-top: -18.5px;
  }
  .reseller-promo__logo img {
    height: 39px;
    width: auto;
    margin: 0 auto;
  }
  .reseller-promo__title {
    font-size: 35px;
    line-height: 40px;
    padding-top: 10px;
  }
  .reseller-promo__sub-title {
    font-size: 16px;
    line-height: 25px;
    padding: 10px 0 30px 0;
  }
  .reseller-promo__button-container {
    margin-bottom: -17px;
  }
  .reseller-promo__button-container .xa-button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 12px;
    min-width: 250px;
  }
}
