.button-wrapper {
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
}
.button-wrapper--right {
  justify-content: flex-end;
}
.button-wrapper--left {
  justify-content: flex-start;
}
.xa-button {
  font-size: 1.2rem;
  outline: none !important;
  padding: 1.5rem 2rem;
  color: white;
  background-color: #da291c;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
  cursor: pointer;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}
@media (max-width: 992px) {
  .xa-button {
    font-size: 1.5rem;
  }
}
.xa-button:focus {
  outline: none !important;
}
.xa-button:hover {
  outline: none !important;
  background-color: #c32519;
}
.xa-button__wide-button {
  padding: 1rem 4rem;
}
.xa-button__alt-color {
  background-color: #005aab;
}
.xa-button__alt-color:hover {
  background-color: #163040;
}
.xa-button__dark-color {
  font-family: SuzukiPROHeadline;
  background-color: #002c48;
}
.xa-button__dark-color:hover {
  background-color: #004d9f;
}
.xa-button__text-type {
  padding: 0;
  background-color: transparent;
  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
.xa-button__text-type:hover {
  background-color: transparent;
}
.xa-button__link-button {
  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  background-color: transparent;
  color: #005aab;
}
.xa-button__link-button:hover {
  background-color: transparent;
  color: #003f78;
}
.xa-button__full-width {
  width: 100%;
  display: block;
}
.xa-button__small {
  padding: 10px 20px;
}
.xa-button:disabled {
  background-color: #666;
  cursor: not-allowed;
}
