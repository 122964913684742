.position-LEFT-TOP {
  top: 0;
  justify-content: flex-start;
  text-align: left;
}
.position-LEFT-CENTER {
  top: 0;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}
.position-LEFT-BOTTOM {
  top: 0;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: left;
}
.position-CENTER-TOP {
  top: 0;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.position-CENTER-CENTER {
  top: 0;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.position-CENTER-BOTTOM {
  top: 0;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}
.position-RIGHT-TOP {
  top: 0;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
}
.position-RIGHT-CENTER {
  top: 0;
  justify-content: center;
  text-align: right;
  align-items: flex-end;
}
.position-RIGHT-BOTTOM {
  top: 0;
  justify-content: flex-end;
  text-align: right;
  align-items: flex-end;
}
