.tech-slider-section-compoenent {
  min-height: 750px;
  display: flex;
  padding-top: 15px;
  overflow: hidden;
}
.tech-slider-section-compoenent .tech-slider-left-side {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  margin-left: -100px;
  background-color: #002c48;
  background-image: url('../../../../Assets/BikePage/bluePatter.png');
  transform: skewX(15deg);
  -webkit-transform: skewX(15deg);
  -moz-transform: skewX(15deg);
  -ms-transform: skewX(15deg);
  -o-transform: skewX(15deg);
  display: flex;
  height: 100%;
}
.tech-slider-section-compoenent .tech-slider-left-side__content-container {
  transform: skewX(-15deg);
  -webkit-transform: skewX(-15deg);
  -moz-transform: skewX(-15deg);
  -ms-transform: skewX(-15deg);
  -o-transform: skewX(-15deg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 60px;
  padding-bottom: 30px;
  padding-left: 250px;
  padding-right: 160px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  cursor: pointer;
}
.tech-slider-section-compoenent .tech-slider-right-side {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 60px;
}
.tech-slider-section-compoenent .tech-slider-title {
  color: white;
  padding-left: 0;
  margin-bottom: 50px;
}
.tech-slider-section-compoenent .tech-slider-title span,
.tech-slider-section-compoenent .tech-slider-title h1,
.tech-slider-section-compoenent .tech-slider-title h2,
.tech-slider-section-compoenent .tech-slider-title h3,
.tech-slider-section-compoenent .tech-slider-title h4,
.tech-slider-section-compoenent .tech-slider-title h5,
.tech-slider-section-compoenent .tech-slider-title h6,
.tech-slider-section-compoenent .tech-slider-title p {
  text-transform: uppercase;
}
.tech-slider-section-compoenent .tech-slider-desc {
  color: white;
}
.tech-slider-section-compoenent .tech-slider-section-navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.tech-slider-section-compoenent .tech-slider-section-navigation-left-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: all 0.2s linear;
}
.tech-slider-section-compoenent .tech-slider-section-navigation-left-button:hover {
  margin-left: -10px;
  margin-right: 10px;
}
.tech-slider-section-compoenent .tech-slider-section-navigation-left-button__image {
  margin-right: 20px;
}
.tech-slider-section-compoenent .tech-slider-section-navigation-left-button__text {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: SuzukiPROHeadline;
  letter-spacing: 2px;
}
.tech-slider-section-compoenent .tech-slider-section-navigation-right-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: all 0.2s linear;
}
.tech-slider-section-compoenent .tech-slider-section-navigation-right-button:hover {
  margin-right: -10px;
  margin-left: 10px;
}
.tech-slider-section-compoenent .tech-slider-section-navigation-right-button__image {
  margin-left: 20px;
}
.tech-slider-section-compoenent .tech-slider-section-navigation-right-button__text {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}
.tech-slider-section-compoenent .tech-slider-left-side-transition-group {
  display: grid;
  grid: 'center' 1fr;
}
.tech-slider-section-compoenent .tech-slider-left-side-transition-group .tech-illustartion-mobile {
  display: none;
}
.tech-slider-section-compoenent .tech-slider-left-side-transition-group.tech-slider-forward .slide-enter {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  opacity: 0;
}
.tech-slider-section-compoenent .tech-slider-left-side-transition-group.tech-slider-forward .slide-enter-active {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  opacity: 100%;
}
.tech-slider-section-compoenent .tech-slider-left-side-transition-group.tech-slider-forward .slide-exit {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  opacity: 0;
}
.tech-slider-section-compoenent .tech-slider-left-side-transition-group.tech-slider-backward .slide-enter {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  opacity: 0;
}
.tech-slider-section-compoenent .tech-slider-left-side-transition-group.tech-slider-backward .slide-enter-active {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  opacity: 100%;
}
.tech-slider-section-compoenent .tech-slider-left-side-transition-group.tech-slider-backward .slide-exit {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  opacity: 0;
}
.tech-slider-section-compoenent .tech-slider-left-side-transition-group__item {
  grid-area: 1 / 1;
  transition: all 0.3s linear;
}
.tech-slider-section-compoenent .tech-slider-right-side-transition-group {
  display: grid;
  grid: 'center' 1fr;
}
.tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-forward .fade-enter {
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  opacity: 0;
}
.tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-forward .fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  -webkit-transition: transform 0.3s linear, opacity 0.3s linear;
  -moz-transition: transform 0.3s linear, opacity 0.3s linear;
  -ms-transition: transform 0.3s linear, opacity 0.3s linear;
  -o-transition: transform 0.3s linear, opacity 0.3s linear;
  transition: transform 0.3s linear, opacity 0.3s linear;
}
.tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-forward .fade-exit {
  opacity: 1;
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
}
.tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-forward .fade-exit-active {
  opacity: 1;
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -webkit-transition: transform 0.3s linear, opacity 0.3s linear;
  -moz-transition: transform 0.3s linear, opacity 0.3s linear;
  -ms-transition: transform 0.3s linear, opacity 0.3s linear;
  -o-transition: transform 0.3s linear, opacity 0.3s linear;
  transition: transform 0.3s linear, opacity 0.3s linear;
}
.tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-backward .fade-enter {
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  opacity: 0;
}
.tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-backward .fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  -webkit-transition: transform 0.3s linear, opacity 0.3s linear;
  -moz-transition: transform 0.3s linear, opacity 0.3s linear;
  -ms-transition: transform 0.3s linear, opacity 0.3s linear;
  -o-transition: transform 0.3s linear, opacity 0.3s linear;
  transition: transform 0.3s linear, opacity 0.3s linear;
}
.tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-backward .fade-exit {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}
.tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-backward .fade-exit-active {
  -webkit-transition: transform 0.3s linear, opacity 0.3s linear;
  -moz-transition: transform 0.3s linear, opacity 0.3s linear;
  -ms-transition: transform 0.3s linear, opacity 0.3s linear;
  -o-transition: transform 0.3s linear, opacity 0.3s linear;
  transition: transform 0.3s linear, opacity 0.3s linear;
  opacity: 0;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
}
.tech-slider-section-compoenent .tech-slider-right-side-transition-group .tech-illustartion {
  grid-area: 1 / 1;
  width: 100%;
  max-width: 700px;
  height: auto;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.tech-slider-section-compoenent .tech-slider-section-navigation-indicators {
  display: flex;
  padding: 0 30px;
  flex-grow: 1;
  align-items: center;
}
.tech-slider-section-compoenent .tech-slider-section-navigation-indicators__item {
  height: 2px;
  background-color: white;
  display: block;
  margin: 0 3px;
  border-radius: 3px;
  -webkit-transition: height 0.1s linear;
  -moz-transition: height 0.1s linear;
  -ms-transition: height 0.1s linear;
  -o-transition: height 0.1s linear;
  transition: height 0.1s linear;
  cursor: pointer;
}
.tech-slider-section-compoenent .tech-slider-section-navigation-indicators__item:hover {
  height: 3px;
}
.tech-slider-section-compoenent .tech-slider-section-navigation-indicators__item--active {
  background-color: red;
  height: 5px;
}
@media (max-width: 1600px) {
  .tech-slider-section-compoenent .tech-slider-left-side {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    margin-left: -100px;
  }
  .tech-slider-section-compoenent .tech-slider-left-side__content-container {
    padding-top: 60px;
    padding-bottom: 30px;
    padding-left: 250px;
    padding-right: 160px;
  }
  .tech-slider-section-compoenent .tech-slider-right-side {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
}
@media (max-width: 1200px) {
  .tech-slider-section-compoenent .tech-slider-desc {
    color: white;
  }
  .tech-slider-section-compoenent .tech-slider-desc span,
  .tech-slider-section-compoenent .tech-slider-desc h1,
  .tech-slider-section-compoenent .tech-slider-desc h2,
  .tech-slider-section-compoenent .tech-slider-desc h3,
  .tech-slider-section-compoenent .tech-slider-desc h4,
  .tech-slider-section-compoenent .tech-slider-desc h5,
  .tech-slider-section-compoenent .tech-slider-desc h6,
  .tech-slider-section-compoenent .tech-slider-desc p {
    font-size: 16px !important;
    line-height: 25px !important;
  }
  .tech-slider-section-compoenent .tech-slider-left-side {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    margin-left: -100px;
  }
  .tech-slider-section-compoenent .tech-slider-left-side__content-container {
    padding-top: 60px;
    padding-bottom: 30px;
    padding-left: 175px;
    padding-right: 100px;
  }
  .tech-slider-section-compoenent .tech-slider-right-side {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }
}
@media (max-width: 1000px) {
  .tech-slider-section-compoenent {
    flex-wrap: wrap;
    padding-top: 0;
  }
  .tech-slider-section-compoenent .tech-slider-left-side {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-left: -100px;
    transform: skewX(0);
    -webkit-transform: skewX(0);
    -moz-transform: skewX(0);
    -ms-transform: skewX(0);
    -o-transform: skewX(0);
    margin-left: 0;
  }
  .tech-slider-section-compoenent .tech-slider-left-side__content-container {
    padding-top: 60px;
    padding-bottom: 30px;
    padding-left: 100px;
    padding-right: 100px;
    transform: skewX(0);
    -webkit-transform: skewX(0);
    -moz-transform: skewX(0);
    -ms-transform: skewX(0);
    -o-transform: skewX(0);
  }
  .tech-slider-section-compoenent .tech-slider-left-side .tech-illustartion-mobile {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
  .tech-slider-section-compoenent .tech-slider-right-side {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: none;
  }
  .tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-forward .fade-enter {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    opacity: 0;
  }
  .tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-forward .fade-enter-active {
    opacity: 100%;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
  .tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-forward .fade-exit {
    opacity: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
  }
  .tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-backward .fade-enter {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    opacity: 0;
  }
  .tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-backward .fade-enter-active {
    opacity: 100%;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
  .tech-slider-section-compoenent .tech-slider-right-side-transition-group.tech-slider-backward .fade-exit {
    opacity: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
  }
}
@media (max-width: 600px) {
  .tech-slider-section-compoenent .tech-slider-left-side__content-container {
    padding: 30px;
  }
  .tech-slider-section-compoenent .tech-slider-title span,
  .tech-slider-section-compoenent .tech-slider-title h1,
  .tech-slider-section-compoenent .tech-slider-title h2,
  .tech-slider-section-compoenent .tech-slider-title h3,
  .tech-slider-section-compoenent .tech-slider-title h4,
  .tech-slider-section-compoenent .tech-slider-title h5,
  .tech-slider-section-compoenent .tech-slider-title h6,
  .tech-slider-section-compoenent .tech-slider-title p {
    font-size: 40px !important;
    line-height: 45px !important;
    font-family: SuzukiPROHeadline !important;
    font-weight: 600;
  }
  .tech-slider-section-compoenent .tech-slider-section-navigation-left-button__text {
    display: none;
  }
  .tech-slider-section-compoenent .tech-slider-section-navigation-right-button__text {
    display: none;
  }
}
