@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-4px);
  }
  60% {
    -webkit-transform: translateY(-2px);
  }
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-4px);
  }
  60% {
    -moz-transform: translateY(-2px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-4px);
    -moz-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    -o-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  60% {
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px);
  }
}
.main-view .slider {
  max-height: 100vh;
  height: 100%;
  overflow: hidden;
}
.main-view .slider a {
  color: white !important;
}
.main-view .slider .separator {
  height: 1vh;
  display: flex;
  justify-content: center;
  width: 100%;
  background: #e40012;
  position: relative;
  background: linear-gradient(90deg, #e40012 0%, #004d9f 100%);
  z-index: 1;
}
.main-view .slider .separator img {
  top: -2rem;
  position: relative;
  cursor: pointer;
  z-index: 3;
  height: 45px;
  width: 30px;
  background-color: #70275a;
  padding: 0.5rem;
  border-radius: 10px;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
.main-view .slider .owl-carousel {
  max-height: 99vh;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
}
.main-view .slider .owl-carousel .owl-stage-outer {
  max-height: 99vh;
}
.main-view .slider .owl-carousel .active.owl-video-playing .filter,
.main-view .slider .owl-carousel .active.owl-video-playing .content {
  display: none;
}
.main-view .slider .owl-carousel .owl-dots {
  position: absolute;
  left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.main-view .slider .owl-carousel .owl-dots .owl-dot {
  width: 2rem;
  height: 2.5rem;
}
.main-view .slider .owl-carousel .owl-dots .owl-dot button {
  background: white;
  width: 2rem;
  height: 0.2rem;
  outline: none;
  border: none;
  transform: rotate(90deg);
}
.main-view .slider .owl-carousel .owl-dots .owl-dot.active button {
  background: #e40012;
}
.main-view .slider .owl-carousel .owl-nav .owl-prev:before {
  top: 5rem;
  transform: rotate(90deg);
  position: absolute;
  content: '\f177';
  display: block;
  color: white;
  font-family: FontAwesome;
  font-size: 1.5rem;
}
.main-view .slider .owl-carousel .owl-nav .owl-next:after {
  bottom: 5rem;
  transform: rotate(90deg);
  position: absolute;
  content: '\f178';
  display: block;
  color: white;
  font-family: FontAwesome;
  font-size: 1.5rem;
}
.main-view .slider .owl-carousel .content {
  position: absolute;
  bottom: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  left: 0;
  z-index: 4;
  right: 0;
  max-width: 1170px;
}
.main-view .slider .owl-carousel .content > * {
  text-shadow: 0 0 5px #000000;
}
.main-view .slider .owl-carousel .content .desc {
  font-size: 2rem;
  font-style: italic;
}
.main-view .slider .owl-carousel .content .title {
  font-family: SuzukiPROHeadline;
  font-size: 7.5rem;
  text-transform: uppercase;
  padding-left: 3rem;
  border-left: 5px solid #e40012;
  line-height: 8rem;
}
.main-view .slider .owl-carousel .item {
  height: 100vh;
  display: flex;
  align-items: center;
}
.main-view .slider .owl-carousel .item .filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
}
.main-view .slider .owl-carousel .item-video {
  height: 100vh;
}
.main-view .slider .owl-carousel .item-video .filter {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 44, 72, 0.7);
  z-index: 2;
}
.main-view .slider .owl-carousel .item-video .owl-video-play-icon {
  z-index: 3;
}
.main-view .slider .owl-carousel .item-video .owl-video-play-icon:before {
  font-family: FontAwesome;
  content: '\f144';
  display: block;
  font-size: 5rem;
}
@media screen and (max-width: 1600px) {
  .main-view .slider {
    max-height: calc(57.27462015vw);
    height: 100%;
  }
  .main-view .slider .owl-carousel .item {
    align-items: flex-start;
    max-height: calc(56.27462015vw);
  }
  .main-view .slider .owl-carousel .item-video {
    height: calc(56.27462015vw);
  }
}
@media screen and (max-width: 1200px) {
  .main-view .slider {
    margin-top: 4rem;
  }
  .main-view .slider .separator {
    display: none;
  }
  .main-view .slider .owl-carousel .content {
    max-width: 80%;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 992px) {
  .main-view .slider .owl-carousel .content .title {
    font-size: 5rem;
    line-height: 5.5rem;
    padding-left: 1rem;
  }
  .main-view .slider .owl-carousel .content .desc {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }
}
.main-view .slider .owl-carousel .owl-dots {
  flex-direction: row;
  bottom: 0.3rem;
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  top: auto;
  left: 50%;
  right: auto;
}
.main-view .slider .owl-carousel .owl-dots .owl-dot {
  margin: 0 0.5rem;
}
.main-view .slider .owl-carousel .owl-dots .owl-dot button {
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  background: white;
}
.main-view .slider .owl-carousel .owl-nav .owl-prev:before {
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  top: 50% !important;
  left: 1rem;
  color: white;
  text-shadow: 0 0 3px #e40012;
  height: 1rem;
}
.main-view .slider .owl-carousel .owl-nav .owl-next:after {
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  top: 50% !important;
  right: 1rem;
  color: white;
  text-shadow: 0 0 3px #e40012;
  height: 1rem;
}
@media screen and (max-width: 768px) {
  .main-view .slider .owl-carousel .content {
    bottom: 2rem;
  }
  .main-view .slider .owl-carousel .content .title {
    font-size: 3rem;
    line-height: 3.5rem;
  }
  .main-view .slider .owl-carousel .content .desc {
    font-size: 1.5rem !important;
  }
}
@media screen and (max-width: 500px) {
  .main-view .slider .owl-carousel .owl-nav .owl-prev:before {
    top: 1rem;
  }
  .main-view .slider .owl-carousel .owl-nav .owl-next:after {
    bottom: 1rem;
  }
}
