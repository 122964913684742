html {
  scroll-padding-top: 70px;
}
.export-financing-view {
  padding: 2rem 0 5rem 0;
}
.export-financing-view .recaptcha {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.export-financing-view p {
  color: #002c48;
  font-size: 16px;
}
@media (max-width: 992px) {
  .export-financing-view p {
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .export-financing-view p {
    font-size: 12px;
  }
}
.export-financing-view__top {
  color: #002c48;
}
.export-financing-view__top > * {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.export-financing-view__top .title {
  text-transform: uppercase;
  font-family: SuzukiPROHeadline;
  font-size: 60px;
}
@media (max-width: 992px) {
  .export-financing-view__top .title {
    font-size: 30px;
  }
}
.export-financing-view__top .content {
  font-size: 20px;
  line-height: 30px;
}
@media (max-width: 992px) {
  .export-financing-view__top .content {
    font-size: 16px;
  }
}
.export-financing-view__top img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
@media (max-width: 992px) {
  .export-financing-view__top {
    padding: 30px 15px;
  }
}
@media (max-width: 992px) {
  .export-financing-view__form-wrapper {
    padding: 30px 15px;
  }
}
.export-financing-view__form-wrapper .row {
  position: relative;
}
.export-financing-view__form-wrapper .headline {
  font-family: SuzukiPROHeadline;
  font-family: 30px;
  text-transform: uppercase;
  color: #002c48;
  position: relative;
  margin: 1rem 0;
}
.export-financing-view__form-wrapper .headline h3 {
  padding-left: 80px;
}
.export-financing-view__form-wrapper .headline:before {
  content: '';
  width: 60px;
  height: 3px;
  background: #e40012;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.export-financing-view__form-wrapper .error-message {
  color: #e40012;
  font-size: 16px;
}
