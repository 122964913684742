.cookie-button-right {
  justify-content: flex-end;
}
@media screen and (max-width: 992px) {
  .cookie-button-right {
    justify-content: center;
  }
}
.cookie-button-left {
  justify-content: flex-start;
}
@media screen and (max-width: 992px) {
  .cookie-button-left {
    justify-content: center;
  }
}
.cookie-check {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media screen and (max-width: 576px) {
  .cookie-check {
    justify-content: space-between;
  }
}
.cookie-check-label {
  font-family: SuzukiPROHeadline;
  color: #002c48;
  text-transform: uppercase;
  margin: 0 1rem 0 0;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  background-image: linear-gradient(#e40012, #e40012);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 0% 3px;
  transition: background-size 0.25s linear;
}
@media screen and (min-width: 1171px) {
  .cookie-check-label {
    font-size: 1.38888889rem;
  }
}
@media screen and (max-width: 1170px) {
  .cookie-check-label {
    font-size: 1.57142857rem;
  }
}
@media screen and (max-width: 992px) {
  .cookie-check-label {
    font-size: 1.66666667rem;
  }
}
@media screen and (max-width: 550px) {
  .cookie-check-label {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 350px) {
  .cookie-check-label {
    font-size: 2rem;
  }
}
@media screen and (max-width: 300px) {
  .cookie-check-label {
    font-size: 2.28571429rem;
  }
}
.cookie-check-label:hover {
  background-size: 100% 3px;
}
.cookie-check-label.active {
  background-image: linear-gradient(#e40012, #e40012);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% 3px;
}
.cookie-check-input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  outline: 1px solid #002c48 !important;
  border: 3px solid white;
  border-radius: 0;
  cursor: pointer;
}
.cookie-check-input:checked {
  outline: 1px solid #002c48;
  border: 3px solid white;
  border-radius: 0;
  background: #002c48;
}
.cookie-check-input:focus {
  outline-offset: 0;
}
.cookie-settings-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
