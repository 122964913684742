.related-news {
  color: white;
}
.related-news .image-container {
  overflow: hidden;
}
.related-news .image-container:after {
  content: '';
  display: block;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.related-news .image-container img {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.related-news .image-container.hovering:after {
  background: rgba(0, 0, 0, 0.5);
}
.related-news .image-container.hovering img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
