.custompage-view {
  max-width: 100%;
  margin: 0 auto;
  color: #163040;
}
.custompage-view r h1 {
  font-size: 2.2rem;
}
.custompage-view h2 {
  font-size: 2rem;
}
.custompage-view p {
  font-size: 1rem;
  line-height: 1.5rem;
}
.custompage-view img {
  max-width: 100%;
  height: auto;
}
.custompage-view__content {
  max-width: 1170px;
  padding: 2rem 2rem 2rem 2rem;
  margin: 0 auto;
  background: white;
  -webkit-box-shadow: 0 0 100px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 100px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 100px 1px rgba(0, 0, 0, 0.1);
}
.custompage-view__content img {
  height: auto;
  max-width: 100%;
}
.custompage-view__content iframe {
  max-width: 100%;
  height: 450px;
}
@media screen and (max-width: 992px) {
  .custompage-view__content iframe {
    height: 350px;
  }
}
@media screen and (max-width: 500px) {
  .custompage-view__content iframe {
    height: 300px;
  }
}
@media screen and (max-width: 1200px) {
  .custompage-view p {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
  .custompage-view .head-img .date {
    font-size: 1.1rem !important;
  }
}
