.il-textarea-wrapper .input-label {
  color: #004d9f;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 3px;
  padding-bottom: 10px;
}
@media (max-width: 992px) {
  .il-textarea-wrapper .input-label {
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .il-textarea-wrapper .input-label {
    font-size: 10px;
  }
}
.il-textarea-wrapper textarea {
  width: 100%;
  min-height: 150px;
  border: 1px solid #d5d5d5;
  background-color: #f0f0f0;
  outline: none;
  color: #002c48;
  padding: 1rem;
  font-size: 20px;
}
