.contact-view {
  margin-top: 50px;
  position: relative;
}
.contact-view .recaptcha {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  position: absolute;
}
.contact-view .error-text {
  color: #e40012;
  margin-top: 20px;
  padding-left: 15px;
  font-size: 18px;
}
@media (max-width: 992px) {
  .contact-view .contact-wrapper .contact-image {
    display: none;
  }
}
.contact-view .contact-wrapper--right .contact-text--title {
  font-size: 20px;
  letter-spacing: 3px;
  color: #004d9f;
  text-transform: uppercase;
  font-variant: small-caps;
}
.contact-view .contact-wrapper--right .contact-text--highlight {
  font-size: 60px;
  color: #002c48;
  font-family: SuzukiProHeadline;
  text-transform: uppercase;
}
@media (max-width: 500px) {
  .contact-view .contact-wrapper--right .contact-text--highlight {
    font-size: 40px;
  }
}
.contact-view .contact-wrapper--right .contact-text--long {
  color: #002c48;
  font-size: 20px;
}
.contact-view .contact-form {
  padding-bottom: 40px;
}
.contact-view .contact-image {
  position: absolute;
  bottom: 0;
}
.contact-view .contact-image img {
  margin: 0 auto;
  max-width: 95%;
}
.contact-view .contact-image--mobile {
  display: none;
  max-width: 100%;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .contact-view .contact-image--mobile {
    display: block;
  }
}
.contact-view .contact-image--mobile img {
  max-width: 100%;
}
.contact-view .form_sent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.contact-view .form_sent .contact-image {
  position: relative;
}
