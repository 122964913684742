.bike-prop-tab-section-mobile-component .bike-prop-drop-down-container {
  background-color: #002c48;
  margin: 0 10px;
  padding: 15px 0 15px 0;
  display: flex;
  min-height: 60px;
  align-items: center;
  justify-content: flex-end;
}
.bike-prop-tab-section-mobile-component .bike-prop-drop-down-container__text {
  color: white;
  text-transform: uppercase;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: right;
  padding-right: 30px;
  letter-spacing: 1px;
  font-size: 16px;
}
.bike-prop-tab-section-mobile-component .bike-prop-drop-down-container__arrow-container {
  flex: 0 0 30%;
  max-width: 50px;
}
.bike-prop-tab-section-mobile-component .bike-prop-drop-down-container__divider {
  align-self: stretch;
  border-left: 1px solid white;
}
.bike-prop-tab-section-mobile-component .bike-prop-drop-down-container__arrow {
  display: block;
  max-width: 20px;
  height: auto;
  margin: 0 auto;
  transform: rotate(180deg);
}
.bike-prop-tab-section-mobile-component .bike-prop-drop-down-container__arrow--spin {
  transform: rotate(0deg);
}
.bike-prop-tab-section-mobile-component .technical-details-content {
  height: 0;
  margin: 0 10px;
  overflow: hidden;
  margin-bottom: 10px;
}
.bike-prop-tab-section-mobile-component .technical-details-content--open {
  height: auto;
}
.bike-prop-tab-section-mobile-component .download-content {
  height: 0;
  margin: 0 10px;
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
  background-color: #efefef;
}
.bike-prop-tab-section-mobile-component .download-content--open {
  height: auto;
  padding-top: 0;
}
.bike-prop-tab-section-mobile-component .download-content-row {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 20px;
}
.bike-prop-tab-section-mobile-component .download-content-row__image-container {
  flex: 0 0 25%;
  max-width: 25%;
}
.bike-prop-tab-section-mobile-component .download-content-row__image {
  max-width: 40px;
  margin: 0 auto;
  display: block;
  height: auto;
}
.bike-prop-tab-section-mobile-component .download-content-row__name {
  color: #004d9f;
  text-transform: uppercase;
  flex: 0 0 75%;
  max-width: 75%;
  padding-left: 20px;
  font-size: 18px;
  font-family: SuzukiPROHeadline;
}
.bike-prop-tab-section-mobile-component .technical-details-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.bike-prop-tab-section-mobile-component .technical-details-row:nth-child(2n) {
  background: #ebebeb;
}
.bike-prop-tab-section-mobile-component .technical-details-row:nth-child(2n + 1) {
  background: #f7f7f7;
}
.bike-prop-tab-section-mobile-component .technical-details-row:last-child {
  margin-bottom: 30px;
}
.bike-prop-tab-section-mobile-component .technical-details-row .icon-cube {
  width: 16px;
  height: 16px;
  display: block;
  background-color: #aaa;
  opacity: 0.4;
  margin: 0 auto;
}
.bike-prop-tab-section-mobile-component .technical-details-row__label {
  color: #002c48;
  font-family: SuzukiPROHeadline;
  font-size: 18px;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 20px 10px 10px 65px;
  max-width: calc(100% - 56px);
}
.bike-prop-tab-section-mobile-component .technical-details-row__label sup,
.bike-prop-tab-section-mobile-component .technical-details-row__label sub {
  font-family: SuzukiPROHeadline;
}
.bike-prop-tab-section-mobile-component .technical-details-row__value {
  color: #002c48;
  flex: 0 0 100%;
  max-width: 100%;
  font-size: 20px;
  padding: 0 10px 20px 65px;
}
.bike-prop-tab-section-mobile-component .technical-details-row__image-wrapper {
  position: absolute;
  top: 20px;
  left: 12px;
}
.bike-prop-tab-section-mobile-component .technical-details-row__image {
  max-width: 30px;
  height: auto;
  display: block;
  margin: 0 auto;
}
.bike-prop-tab-section-mobile-component .fin-calc-mobile-button {
  min-height: 60px;
  background-color: #e40012;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  text-transform: uppercase;
  font-size: 14px;
}
