.warn-page {
  max-width: 100%;
  margin: 0 auto;
  padding-top: 5rem;
  color: #163040;
  color: black;
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: top center;
}
.warn-page__title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}
.warn-page__container--text {
  font-size: 1.6rem;
  font-weight: 400;
  text-align: justify;
  margin: 1rem auto;
  color: #888;
}
.warn-page__container--text span {
  font-weight: 600;
  font-size: 1.65rem;
}
.warn-page__container--buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.warn-page__container--buttons button {
  outline: none;
  border: none;
  background: transparent;
  border: 2px solid black;
  width: fit-content;
  padding: 5px 30px;
  text-transform: uppercase;
  font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-size: 2rem;
}
.warn-page__container--buttons p {
  font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  text-transform: uppercase;
}
@media screen and (max-width: 400px) {
  .warn-page__container--buttons {
    flex-direction: column;
  }
  .warn-page__container--buttons .xa-button:last-of-type {
    margin-left: 0;
    margin-top: 1rem;
  }
}
