.video-view {
  max-width: 1170px;
}
.video-view .videos__card-body {
  min-height: 14rem;
  color: #163040;
  padding: 0.5rem;
  padding-top: 2rem;
}
.video-view .videos__card-body--date {
  color: white;
  text-transform: uppercase;
  font-style: italic;
  padding: 0.1rem 0.5rem;
  display: inline;
  background: #004d9f;
  position: relative;
  font-size: 0.6667rem;
  line-height: 1.1rem;
}
.video-view .videos__card-body--date span:before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 19px 20px;
  border-color: transparent transparent #004d9f transparent;
}
.video-view .videos__card-body--title {
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  font-family: SuzukiPROHeadline;
  color: #002c48;
  line-height: 1em;
  margin: 0.5rem 0;
}
.video-view .videos__card-body--short {
  font-size: 1rem;
  line-height: 1rem;
  color: #002c48;
}
.video-view .videos__card-body--category {
  background: #e40012;
  font-size: 0.8889rem;
  line-height: 1.2rem;
  color: white;
  text-transform: uppercase;
  position: relative;
  display: inline-flex;
  padding-right: 10px;
  margin-right: 22px;
  padding-left: 10px;
  padding-top: 2px;
  font-weight: bold;
}
.video-view .videos__card-body--category span:after {
  content: '';
  position: absolute;
  right: -21px;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 22px 21px 0 0;
  border-color: #e40012 transparent transparent transparent;
}
.video-view .videos__card-header {
  -webkit-box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.2);
}
.video-view .videos__card-header img {
  position: relative;
  z-index: 2;
}
.video-view .videos__card-header--image {
  position: relative;
  cursor: pointer;
}
.video-view .videos__card-header--image__logo {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-view .videos__card-header--image:before {
  content: '';
  position: absolute;
  left: -0.5rem;
  top: -1.25rem;
  z-index: 1;
  width: 0;
  height: 120%;
  border-style: solid;
  border-width: 6.389rem 0 6.389rem 16.67rem;
  border-color: transparent transparent transparent rgba(0, 44, 72, 0.3);
}
.video-view .video.row {
  margin-top: 5rem;
}
.video-view .video__load-more {
  margin-bottom: 3rem;
  text-align: center;
}
.video-view .video__load-more--button--true {
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  color: #002c48;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 20px;
}
.video-view .video__load-more--button--true:before,
.video-view .video__load-more--button--true:after {
  content: '';
  position: absolute;
  top: 50%;
  width: calc(390px);
  margin-top: -2px;
  border-top: 2px solid #e40012;
  border-bottom: 2px solid #e40012;
  border-radius: 50%;
}
.video-view .video__load-more--button--true:before {
  right: 100%;
  margin-right: 15px;
}
.video-view .video__load-more--button--true:after {
  left: 100%;
  margin-right: 15px;
}
.video-view .video__load-more--button--false {
  display: none;
}
.video-view .popup-overlay {
  background: rgba(0, 0, 0, 0.82) !important;
}
.video-view .popup-content {
  -webkit-box-shadow: 0 0 50px 50px black;
  -moz-box-shadow: 0 0 50px 50px black;
  box-shadow: 0 0 50px 50px black;
  border: none !important;
  padding: 0 !important;
}
.video-view .popup-content iframe,
.video-view .popup-content div {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  line-height: 0;
}
@media only screen and (max-width: 1200px) {
  .video__load-more--button--true::after,
  .video__load-more--button--true::before {
    display: none;
  }
  .videos__card-header--image img {
    max-width: 100% !important;
  }
  .video-view .popup-content {
    width: 90% !important;
    max-height: 300px !important;
  }
  .video-view .popup-content iframe {
    max-height: 300px !important;
  }
}
@media only screen and (max-width: 1170px) {
  .video-view .videos__card-body--date span:before {
    border-width: 0 0 15px 16px;
    left: -16px;
  }
  .video-view .videos__card-body--category span:after {
    right: -17px;
    border-width: 18px 17px 0 0;
  }
}
@media only screen and (max-width: 992px) {
  .video-view .videos__card-body--date span:before {
    border-width: 0 0 12px 13px;
    left: -13px;
  }
  .video-view .videos__card-body--category span:after {
    right: -15px;
    border-width: 16px 15px 0 0;
  }
}
@media only screen and (max-width: 550px) {
  .video-view .videos__card-body--date span:before {
    border-width: 0 0 10px 11px;
    left: -11px;
  }
  .video-view .videos__card-body--category span:after {
    right: -13px;
    border-width: 14px 13px 0 0;
  }
}
@media only screen and (max-width: 350px) {
  .video-view .videos__card-body--date span:before {
    border-width: 0 0 8px 9px;
    left: -9px;
  }
  .video-view .videos__card-body--category span:after {
    right: -10px;
    border-width: 11px 10px 0 0;
  }
}
@media only screen and (max-width: 300px) {
  .video-view .videos__card-body--date span:before {
    border-width: 0 0 6px 7px;
    left: -7px;
  }
  .video-view .videos__card-body--category span:after {
    right: -9px;
    border-width: 10px 9px 0 0;
  }
}
@media only screen and (min-width: 445px) and (max-width: 1199px) {
  .video-view .videos__card-header--image:before {
    height: 110%;
  }
  .video-view .popup-content iframe {
    max-height: 400px !important;
  }
}
@media screen and (max-width: 992px) {
  .video-view .videos__card-body--category {
    margin-right: 15px;
  }
  .video-view .videos__card-body--title {
    font-size: 3rem;
  }
  .video-view .videos__card-body--short {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 768px) {
  .video-view .video-view__selector {
    top: 0;
  }
}
@media screen and (max-width: 350px) {
  .video-view .videos__card-body--category {
    margin-right: 10px;
  }
}
