.financing-view {
  background-position: top center;
}
@media screen and (max-width: 1200px) {
  .financing-view {
    background-position: right -50px !important;
  }
}
@media screen and (max-width: 992px) {
  .financing-view {
    background-position: right -150px !important;
  }
}
@media screen and (max-width: 768px) {
  .financing-view {
    background-position: right 0px !important;
  }
}
@media screen and (max-width: 550px) {
  .financing-view {
    background-position: right -50px !important;
  }
}
@media screen and (max-width: 500px) {
  .financing-view {
    background-position: right -100px !important;
  }
}
@media screen and (max-width: 400px) {
  .financing-view {
    background-position: right -150px !important;
  }
}
@media screen and (max-width: 340px) {
  .financing-view {
    background-position: right -200px !important;
  }
}
.financing-view .rangeslider__fill {
  background-color: #004d9f;
  border-radius: 0;
}
.financing-view .rangeslider__handle {
  border-radius: 0;
  background: transparent;
  background-image: url('../../../../Assets/suzuki_logo_mini.png');
  width: 3rem;
  height: 3rem;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.financing-view .rangeslider__handle-label {
  display: none;
}
.financing-view .rangeslider__handle:after {
  display: none;
}
.financing-view .rangeslider,
.financing-view .rangeslider__fill {
  border-radius: 0;
}
.financing-view .legal-text {
  font-size: 1rem;
  text-shadow: 0px 2px 1px rgba(26, 26, 27, 0.2);
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: justify;
}
.financing-view .status-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .financing-view .status-bar .item {
    justify-content: center !important;
    margin: 2rem 0 0 0;
  }
  .financing-view .status-bar .item .text {
    display: none;
  }
}
.financing-view .status-bar .item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.financing-view .status-bar .item .counter {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  padding: 1rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-family: SuzukiPROHeadline;
  color: rgba(255, 255, 255, 0.5);
}
.financing-view .status-bar .item.active .counter,
.financing-view .status-bar .item.active .text {
  color: white;
  border-color: white;
}
.financing-view .status-bar .item .text {
  white-space: nowrap;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: SuzukiPROHeadline;
  color: rgba(255, 255, 255, 0.5);
}
.financing-view select {
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  width: 100%;
  font-size: 1.6rem;
  color: white;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  padding: 0.3rem 2rem;
}
.financing-view select option {
  color: #002c48;
}
.financing-view input:not([type='checkbox']) {
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  font-size: 1.111rem;
  padding: 0.5rem 2rem;
  width: 100%;
  margin-bottom: 2rem;
  color: white;
}
.financing-view input:not([type='checkbox'])::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1;
  /* Firefox */
}
.financing-view input:not([type='checkbox']):-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}
.financing-view input:not([type='checkbox'])::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}
.financing-view__content--top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 0 0;
}
.financing-view__content--top-row:before,
.financing-view__content--top-row:after {
  display: flex !important;
}
.financing-view__content--top-row .bike-select {
  position: relative;
  max-width: 50%;
  width: 100%;
  margin-right: 2%;
}
.financing-view__content--top-row .bike-select:after {
  position: absolute;
  right: 2rem;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  bottom: 0;
  content: '\f078';
  width: 1rem;
  color: white;
  z-index: 0;
  font-family: FontAwesome;
  pointer-events: none;
}
.financing-view__content--top-row .bike-logo {
  max-width: 48%;
  width: 100%;
}
.financing-view__content--top-row .bike-logo img {
  max-width: 100%;
}
.financing-view__content--mid-row:before,
.financing-view__content--mid-row:after {
  display: flex !important;
}
.financing-view__content--mid-row input[type='radio'] {
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid white;
  padding: 9px;
  margin: 0 1rem;
}
.financing-view__content--mid-row input[type='radio']:checked {
  background-color: #e40012;
  color: #e40012;
}
.financing-view__content--mid-row label {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  white-space: nowrap;
}
.financing-view__content--mid-row .casco {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 1rem 0;
}
.financing-view__content--mid-row button {
  width: 40%;
  margin: 0 auto;
  padding: 1rem 3rem;
  background: #004d9f;
  border: none !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  position: relative;
  font-size: 1.111rem;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  box-shadow: 5px 5px 0px 1px rgba(218, 41, 28, 0.2);
  transition: linear 0.3s;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.financing-view__content--mid-row button:hover {
  background: #e40012;
  top: 5px;
  left: 5px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.financing-view__content--mid-row .bike-image {
  max-width: 50%;
  width: 100%;
}
.financing-view__content--mid-row .bike-image img {
  max-width: 100%;
  width: 100%;
}
.financing-view__content--mid-row .bike-price {
  display: flex;
  width: 100%;
  max-width: 50%;
  justify-content: center;
  align-items: center;
  font-family: SuzukiPROHeadline;
  font-size: 5rem;
}
.financing-view__content--mid-row__ranges {
  width: 100%;
  max-width: 95%;
  margin: 0 auto !important;
}
.financing-view__content--mid-row__ranges .before-rangeslider {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
.financing-view__content--mid-row__ranges .after-rangeslider {
  font-size: 1.3888rem;
  text-transform: uppercase;
}
.financing-view__content--mid-row__ranges .bike-own-part,
.financing-view__content--mid-row__ranges .bike-duration {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 2rem;
}
.financing-view__content--mid-row__ranges .bike-own-part .rangeslider,
.financing-view__content--mid-row__ranges .bike-duration .rangeslider {
  width: inherit;
  margin: 0 1rem;
}
.financing-view__content--mid-row__ranges .financed-amount {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.financing-view__content--mid-row__ranges .financed-amount__title {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-top: 1rem;
}
.financing-view__content--mid-row__ranges .financed-amount__price {
  font-size: 2rem;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .financing-view .rangeslider {
    margin: 3rem 0 !important;
  }
  .financing-view .rangeslider__handle {
    width: 5rem;
    height: 5rem;
  }
  .financing-view__content .status-bar .item:first-child {
    justify-content: flex-start !important;
  }
  .financing-view__content .status-bar .item:last-child {
    justify-content: flex-end !important;
  }
  .financing-view__content--top-row {
    flex-direction: column;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .financing-view__content--top-row .bike-select {
    max-width: 100%;
    margin-bottom: 2rem;
  }
  .financing-view__content--top-row .bike-logo {
    max-width: 95%;
  }
  .financing-view__content--mid-row {
    flex-direction: column;
  }
  .financing-view__content--mid-row__ranges {
    order: 3;
  }
  .financing-view__content--mid-row__ranges .bike-own-part {
    flex-direction: column;
  }
  .financing-view__content--mid-row__ranges .bike-own-part .rangeslider {
    order: 3;
  }
  .financing-view__content--mid-row__ranges .bike-own-part .before-rangeslider {
    order: 1;
    font-size: 2rem;
  }
  .financing-view__content--mid-row__ranges .bike-own-part .after-rangeslider {
    order: 2;
    font-size: 2.5rem;
    font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  }
  .financing-view__content--mid-row__ranges .bike-duration {
    flex-direction: column;
  }
  .financing-view__content--mid-row__ranges .bike-duration .rangeslider {
    order: 3;
  }
  .financing-view__content--mid-row__ranges .bike-duration .before-rangeslider {
    order: 1;
    margin-top: 2rem;
    font-size: 2rem;
  }
  .financing-view__content--mid-row__ranges .bike-duration .after-rangeslider {
    order: 2;
    font-size: 2.5rem;
    font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  }
  .financing-view__content--mid-row__ranges .financed-amount__title {
    font-size: 2rem;
  }
  .financing-view__content--mid-row__ranges .financed-amount__price {
    font-size: 3rem;
    font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  }
  .financing-view__content--mid-row .casco {
    order: 4;
  }
  .financing-view__content--mid-row .iline-blue-to-red-button {
    order: 5;
  }
  .financing-view__content--mid-row .bike-image {
    max-width: 95%;
    margin: 0 auto;
    order: 2;
  }
  .financing-view__content--mid-row .bike-price {
    order: 1;
    max-width: 100%;
  }
}
