.layout {
  min-height: 100%;
  max-width: 1920px;
  background-image: url('../../../../Assets/BG.png') !important;
}
.layout .navImage {
  position: relative;
  display: flex;
  justify-content: center;
}
.layout .navImage div {
  position: absolute;
  font-family: SuzukiPROHeadline;
  bottom: 0;
  max-width: 1170px;
  width: 100%;
  text-transform: uppercase;
  font-size: 7.5rem;
  padding: 1.5rem;
  padding-bottom: 3rem;
  padding-left: 5rem;
  font-style: italic;
  background: -webkit-linear-gradient(#c0c0c0, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 1200px) {
  .navImage img {
    margin-top: 4rem;
    height: 100%;
  }
}
