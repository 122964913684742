.resellers-view {
  padding-bottom: 5rem;
  max-width: 1170px;
  margin: 0 auto;
}
.resellers-view.single .type-selector {
  display: none;
}
.resellers-view.single .content .resellers-item-wrapper {
  padding-top: 5rem;
}
.resellers-view .popup-content {
  z-index: 9999999;
  width: max-content !important;
  color: #002c48;
  padding: 0 !important;
  background: transparent !important;
}
.resellers-view .resellers-popup-x {
  position: absolute;
  top: -5rem;
  right: -4rem;
  font-size: 4rem;
  height: 4rem;
  width: 4rem;
  font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  cursor: pointer;
  color: #ebebeb;
}
.resellers-view .resellers-popup-border {
  margin: 1rem;
}
.resellers-view .resellers-popup-border__top {
  width: 30%;
  height: 2px;
  top: -2px;
  position: absolute;
  background: white;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.resellers-view .resellers-popup-border__bottom {
  width: 30%;
  height: 2px;
  bottom: -2px;
  position: absolute;
  background: white;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.resellers-view .resellers-popup-content {
  background: #ebebeb;
  padding: 2rem;
  text-align: center;
}
.resellers-view .resellers-popup-content__border {
  height: 2px;
  width: 100%;
  background: rgba(0, 44, 72, 0.2);
  margin-bottom: 1.5rem;
}
.resellers-view .resellers-popup-content__border:after {
  content: '';
  display: flex;
  height: 2px;
  position: absolute;
  width: 30%;
  background: #002c48;
  right: 0;
  margin: 0 auto;
  left: 0;
}
.resellers-view .resellers-popup-content__head {
  font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin: 0 0 1.5rem 0;
}
.resellers-view .resellers-popup-content__opening-hours {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
.resellers-view .resellers-popup-content__links {
  color: #004d9f;
  display: flex;
  justify-content: space-around;
  margin-bottom: 1.5rem;
}
.resellers-view .resellers-popup-content__links div {
  padding: 0 0.5rem;
}
.resellers-view .resellers-popup-content__links div:hover {
  background: #004d9f;
}
.resellers-view .resellers-popup-content__links div:hover a {
  color: white;
}
.resellers-view .resellers-popup-content__links a {
  text-decoration: none !important;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #004d9f;
}
.resellers-view .resellers-popup-content__info .location {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}
.resellers-view .resellers-popup-content__info .location:before {
  content: '\f041';
  font-family: FontAwesome;
  display: block;
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  margin-right: 1rem;
  -webkit-box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.resellers-view .resellers-popup-content__info .tel {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}
.resellers-view .resellers-popup-content__info .tel:before {
  content: '\f10b';
  font-family: FontAwesome;
  display: block;
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  margin-right: 1rem;
  -webkit-box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.resellers-view .resellers-popup-content__info .email {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.resellers-view .resellers-popup-content__info .email:before {
  content: '\f0e0';
  font-family: FontAwesome;
  display: block;
  font-size: 1.2rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  margin-right: 1rem;
  -webkit-box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.resellers-view .type-selector {
  display: flex;
  padding-top: 5rem !important;
}
.resellers-view .type-selector__placeholder {
  border-top: 1px solid #002c48;
  flex-grow: 1;
  transform: skew(-25deg);
  -webkit-transform: skew(-25deg);
  -moz-transform: skew(-25deg);
  -ms-transform: skew(-25deg);
  -o-transform: skew(-25deg);
}
@media screen and (max-width: 772px) {
  .resellers-view .type-selector__placeholder {
    display: none;
  }
}
.resellers-view .type-selector div:not(.type-selector__placeholder) {
  border-right: 1px solid #002c48;
  border-bottom: 1px solid #002c48;
  transform: skew(-25deg);
  -webkit-transform: skew(-25deg);
  -moz-transform: skew(-25deg);
  -ms-transform: skew(-25deg);
  -o-transform: skew(-25deg);
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  padding: 0 50px;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
  cursor: pointer;
  margin-right: 10px;
}
@media screen and (max-width: 772px) {
  .resellers-view .type-selector div:not(.type-selector__placeholder) {
    max-width: 80%;
    margin: 0 auto;
  }
}
.resellers-view .type-selector div:not(.type-selector__placeholder):hover,
.resellers-view .type-selector div:not(.type-selector__placeholder).active {
  background-color: #002c48;
}
.resellers-view .type-selector div:not(.type-selector__placeholder):hover span,
.resellers-view .type-selector div:not(.type-selector__placeholder).active span {
  color: white;
}
.resellers-view .type-selector div:not(.type-selector__placeholder) span {
  transform: skew(25deg);
  -webkit-transform: skew(25deg);
  -moz-transform: skew(25deg);
  -ms-transform: skew(25deg);
  -o-transform: skew(25deg);
  font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  text-transform: uppercase;
  color: #002c48;
  font-size: 1.5rem;
}
.resellers-view .content {
  position: relative;
}
.resellers-view .content .table {
  z-index: 0;
  position: absolute;
  top: 0;
  margin: 0 auto;
  display: flex;
}
.resellers-view .content .table img {
  margin: 0 auto;
  max-width: 100%;
}
.resellers-view .content .table__bikes {
  position: absolute;
  top: 0;
  width: 100%;
}
.resellers-view .content .table__bikes .right {
  position: absolute;
  right: 1rem;
  top: -6.6rem;
}
@media screen and (max-width: 1170px) {
  .resellers-view .content .table__bikes .right {
    top: -8.5rem;
  }
}
.resellers-view .content .table__bikes .left {
  position: absolute;
  left: 1rem;
  top: -6.6rem;
}
@media screen and (max-width: 1170px) {
  .resellers-view .content .table__bikes .left {
    top: -8.5rem;
  }
}
.resellers-view .content .cz-text-addon {
  color: #002c48;
  text-align: center;
  padding-top: 5rem;
  margin-bottom: -5rem;
  padding-left: 20px;
  padding-right: 20px;
}
.resellers-view .content .resellers-item-wrapper {
  display: flex;
  position: relative;
  z-index: 2;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.resellers-view .content .resellers-item-wrapper .reseller-item {
  max-width: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  border-top: 1px solid #002c48;
  border-right: 1px solid #002c48;
  border-left: 1px solid #002c48;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
@media screen and (max-width: 992px) {
  .resellers-view .content .resellers-item-wrapper .reseller-item {
    max-width: 49%;
  }
}
@media screen and (max-width: 550px) {
  .resellers-view .content .resellers-item-wrapper .reseller-item {
    max-width: 100%;
  }
}
.resellers-view .content .resellers-item-wrapper .reseller-item__name {
  color: #002c48;
  font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-size: 2rem;
  text-transform: uppercase;
  margin-top: 1rem;
}
.resellers-view .content .resellers-item-wrapper .reseller-item__city {
  color: white;
  background-color: #002c48;
  padding: 0rem 0.5rem;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 1rem 0;
}
.resellers-view .content .resellers-item-wrapper .reseller-item__info {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: -25px;
}
@media screen and (max-width: 1170px) {
  .resellers-view .content .resellers-item-wrapper .reseller-item__info {
    margin-bottom: -1.4rem;
  }
}
.resellers-view .content .resellers-item-wrapper .reseller-item__info--border {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #002c48;
  border: none !important;
}
.resellers-view .content .resellers-item-wrapper .reseller-item__info .icon {
  max-width: max-content;
  height: auto;
  padding: 0 5px;
  width: 100%;
  justify-content: center;
  display: flex;
}
.resellers-view .content .resellers-item-wrapper .reseller-item__info .icon:after {
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
  content: '\f05a';
  display: block;
  font-family: FontAwesome;
  font-size: 2rem;
  color: #004d9f;
  min-width: 45px;
  display: flex;
  justify-content: center;
}
.resellers-view .content .resellers-item-wrapper .reseller-item:hover {
  border: none;
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  background: #d3d3d5;
  background: linear-gradient(105deg, white 50%, #d3d3d5 51%);
  -webkit-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.3);
}
.resellers-view .content .resellers-item-wrapper .reseller-item:hover:after,
.resellers-view .content .resellers-item-wrapper .reseller-item:hover:before {
  content: '';
  position: absolute;
  right: -1.5rem;
  top: -1.5rem;
  display: inline-block;
  width: 70px;
  height: 70px;
  background: #004d9f;
  background: linear-gradient(#004d9f, #004d9f), linear-gradient(#004d9f, #004d9f);
  background-position: center;
  background-size: 50% 2px, 2px 50%;
  background-repeat: no-repeat;
}
.resellers-view .content .resellers-item-wrapper .reseller-item:hover:before {
  left: -1.5rem;
}
.resellers-view .content .resellers-item-wrapper .reseller-item:hover .reseller-item__info:after,
.resellers-view .content .resellers-item-wrapper .reseller-item:hover .reseller-item__info:before {
  content: '';
  position: absolute;
  right: -1.5rem;
  bottom: -1.5rem;
  display: inline-block;
  width: 70px;
  height: 70px;
  background: #004d9f;
  background: linear-gradient(#004d9f, #004d9f), linear-gradient(#004d9f, #004d9f);
  background-position: center;
  background-size: 50% 2px, 2px 50%;
  background-repeat: no-repeat;
}
.resellers-view .content .resellers-item-wrapper .reseller-item:hover .reseller-item__info:before {
  left: -1.5rem;
}
.resellers-view .content .resellers-item-wrapper .reseller-item:hover .reseller-item__info--border {
  background: transparent;
}
.resellers-view .map {
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.2);
  max-width: 85%;
  margin: 0 auto;
}
.resellers-view .map .filter {
  background-color: rgba(0, 44, 72, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: SuzukiPROHeadline;
  text-transform: uppercase;
  font-size: 2rem;
  letter-spacing: 0.3rem;
}
@media screen and (max-width: 992px) {
  .resellers-view .map {
    max-width: 100%;
    margin: 0;
  }
  .resellers-view .resellers-popup-x {
    right: 0;
  }
  .resellers-view .resellers-popup-content__links {
    font-size: 1.3rem;
  }
  .resellers-view .resellers-popup-content__links a {
    font-size: 1.3rem;
  }
  .resellers-view .resellers-popup-content__head {
    font-size: 3rem;
  }
  .resellers-view .resellers-popup-content__opening-hours span {
    font-size: 2rem;
  }
  .resellers-view .resellers-popup-content__info div {
    font-size: 1.5rem;
  }
  .resellers-view .resellers-popup-content__info div:before {
    font-size: 2rem !important;
  }
  .resellers-view .content .resellers-item-wrapper .reseller-item__name {
    font-size: 3rem;
  }
  .resellers-view .content .resellers-item-wrapper .reseller-item__city {
    font-size: 2rem;
  }
  .resellers-view .content .table {
    display: none;
  }
}
@media screen and (max-width: 650px) {
  .resellers-view .type-selector {
    flex-direction: column;
  }
  .resellers-view .type-selector div {
    margin-bottom: 1rem !important;
  }
}
