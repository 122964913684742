.default-properties-section-component .bike-prop-container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  border-top: 1px solid #002c48;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
}
.default-properties-section-component .bike-prop-container__background-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 90;
  color: gray;
  display: flex;
  justify-content: space-between;
  width: 100%;
  transform: skewX(-15deg);
  padding: 0 10px;
  text-align: center;
}
.default-properties-section-component .bike-prop-container__background-text-char {
  font-size: 130px;
  text-transform: uppercase;
  font-family: SuzukiPROHeadline;
  font-weight: 800;
  opacity: 0.1;
}
.default-properties-section-component .bike-prop-container__title-mobile {
  display: none;
}
.default-properties-section-component .divider-default-props {
  border-bottom: 1px solid rgba(0, 77, 159, 0.2);
  margin: 0 20px;
  position: relative;
}
.default-properties-section-component .divider-default-props:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 33.33%;
  border-top: 1px solid #004d9f;
}
.default-properties-section-component .bike-prop-block {
  width: 16.66666667%;
  color: #002c48;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  position: relative;
  z-index: 100;
}
.default-properties-section-component .bike-prop-block__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  grid-area: 1 / 1 / 2 / 2;
}
.default-properties-section-component .bike-prop-block__image-container img {
  max-height: 35px;
  width: auto;
  margin: 0 auto 10px;
  display: block;
}
.default-properties-section-component .bike-prop-block__value-container {
  grid-area: 2 / 1 / 3 / 2;
  text-align: center;
}
.default-properties-section-component .bike-prop-block__value {
  font-weight: 900;
  font-size: 55px;
  line-height: 40px;
  letter-spacing: -2px;
  font-family: SuzukiPROHeadline;
}
.default-properties-section-component .bike-prop-block__unit {
  font-weight: 900;
  font-size: 20px;
  padding-left: 6px;
  letter-spacing: -1px;
  font-family: SuzukiPROHeadline;
}
.default-properties-section-component .bike-prop-block__prop-name {
  text-transform: uppercase;
  grid-area: 3 / 1 / 4 / 2;
  text-align: center;
  padding-top: 7px;
  letter-spacing: 2px;
}
.default-properties-section-component .divider-default-props {
  display: none;
}
@media (max-width: 1100px) {
  .default-properties-section-component .bike-prop-block {
    width: 32%;
    margin-bottom: 40px;
    padding: 20px 10px 10px;
    margin: 1% 0.5% 0 0.5%;
  }
  .default-properties-section-component .bike-prop-block:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .default-properties-section-component .bike-prop-block:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .default-properties-section-component .bike-prop-block__image-container {
    display: flex;
    justify-content: center;
    padding-right: 20px;
    align-items: center;
  }
  .default-properties-section-component .divider-default-props {
    border-bottom: 1px solid rgba(0, 77, 159, 0.2);
    margin: 0 20px;
    position: relative;
    display: block;
  }
  .default-properties-section-component .divider-default-props:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 33.33%;
    border-top: 1px solid #004d9f;
  }
  .default-properties-section-component .bike-prop-container {
    flex-wrap: wrap;
    border-top: none;
    padding: 0 20px 40px;
  }
  .default-properties-section-component .bike-prop-container__background-text {
    position: relative;
    padding: 30px 0;
  }
  .default-properties-section-component .bike-prop-container__background-text-char {
    font-size: 80px;
  }
  .default-properties-section-component .bike-prop-container__title-mobile {
    display: block;
    position: absolute;
    top: 50px;
    left: 0;
    text-align: center;
    color: #002c48;
    font-weight: 900;
    font-family: SuzukiPROHeadline;
    transform: skewX(-5deg);
    font-size: 50px;
    text-transform: uppercase;
    width: 100%;
  }
}
@media (min-width: 401px) and (max-width: 800px) {
  .default-properties-section-component .bike-prop-block {
    width: 48%;
  }
  .default-properties-section-component .bike-prop-block:nth-child(even) {
    background-color: transparent;
  }
  .default-properties-section-component .bike-prop-block:nth-child(odd) {
    background-color: transparent;
  }
  .default-properties-section-component .bike-prop-block:nth-child(3n) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .default-properties-section-component .bike-prop-block:nth-child(3n + 1) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .default-properties-section-component .bike-prop-block:nth-child(3n + 2) {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .default-properties-section-component .bike-prop-block:nth-child(3n + 3) {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .default-properties-section-component .bike-prop-block:nth-child(3) {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
@media (max-width: 600px) {
  .default-properties-section-component .bike-prop-container {
    padding: 0 10px 40px;
  }
  .default-properties-section-component .bike-prop-container__background-text-char {
    font-size: 36px;
  }
  .default-properties-section-component .bike-prop-container__title-mobile {
    top: 38px;
    font-size: 35px;
  }
}
@media (max-width: 400px) {
  .default-properties-section-component .bike-prop-block {
    width: 100%;
  }
}
