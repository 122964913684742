.single-offer-view {
  color: #002c48;
}
.single-offer-view iframe {
  max-width: 100%;
}
.single-offer-view .content {
  max-width: 1170px;
  padding: 2rem 2rem 2rem 2rem;
  background: white;
  -webkit-box-shadow: 0 0 100px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 100px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 100px 1px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}
.single-offer-view .content img {
  height: auto;
  max-width: 100%;
}
.single-offer-view .head-img,
.single-offer-view .head-img img {
  width: 100%;
  position: relative;
  min-height: 6rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.single-offer-view .lead {
  color: white;
  max-width: 1170px;
  width: 100%;
  font-size: 5.556rem;
  text-align: center;
  font-family: SuzukiPROHeadline;
  line-height: 6rem;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 0 25px black;
  text-transform: uppercase;
}
.single-offer-view .head-img .date {
  display: table;
  margin: 0 auto;
  position: absolute !important;
  color: white;
  background: #004d9f;
  line-height: 2rem !important;
  font-size: 0.8333rem !important;
  text-transform: uppercase;
  bottom: -1rem;
}
.single-offer-view .head-img .date .before::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: -2rem !important;
  top: 0;
  border-style: solid;
  border-width: 1rem 2rem 1rem 0 !important;
  border-color: transparent #004d9f transparent transparent;
}
.single-offer-view .head-img .date .after::after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  right: -2rem;
  top: 0;
  border-style: solid;
  border-width: 1rem 0 1rem 2rem;
  border-color: transparent transparent transparent #004d9f;
}
.single-offer-view h1,
.single-offer-view h1 span,
.single-offer-view h1 > *,
.single-offer-view h1 > * > * {
  font-family: SuzukiPROHeadline !important;
  font-size: 2.2rem !important;
}
.single-offer-view h2 {
  font-family: SuzukiPROHeadline;
  font-size: 2rem;
}
.single-offer-view h3 {
  font-family: SuzukiPROHeadline;
  font-size: 2rem;
}
.single-offer-view p,
.single-offer-view p span {
  font-size: 1rem !important;
  line-height: 1.2rem;
  margin: 0 !important;
}
.single-offer-view a strong {
  background-color: #e40012 !important;
  padding: 0.5rem 0.5rem;
  color: white !important;
  border-bottom: 2px solid transparent;
  transition: 0.3s linear;
}
.single-offer-view a strong:hover {
  background-color: transparent !important;
  transition: 0.3s linear;
  color: #002c48 !important;
  border-bottom: 2px solid #e40012;
}
.single-offer-view li,
.single-offer-view li,
.single-offer-view li > * {
  font-size: 1rem !important;
}
.single-offer-view .more-offers__single--container {
  height: 40rem;
  background-size: cover;
  background-position: center center;
}
.single-offer-view .more-offers .offer__container {
  max-height: 40rem !important;
  background-size: cover;
  background-position: center center;
}
.single-offer-view .more-offers .offer__container img {
  width: 100%;
  height: 100%;
}
.single-offer-view .filter {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.95;
}
@media screen and (max-width: 1200px) {
  .single-offer-view .lead {
    font-size: 4rem;
    line-height: 4.5rem;
  }
  .single-offer-view p,
  .single-offer-view p span,
  .single-offer-view p > *,
  .single-offer-view p span > *,
  .single-offer-view li,
  .single-offer-view li,
  .single-offer-view li > * {
    font-size: 1.5rem !important;
    line-height: 2rem;
  }
  .single-offer-view .head-img .date {
    font-size: 1.1rem !important;
  }
}
