.promotion-section-component {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.promotion-section-component .promotion-section-item {
  height: 200px;
  flex: 0 0 50%;
  color: white;
  display: flex;
  padding: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.promotion-section-component .promotion-section-item__left {
  flex: 0 0 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 30px;
}
.promotion-section-component .promotion-section-item__right {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.promotion-section-component .promotion-section-item__title {
  font-weight: 1000;
  font-size: 2rem;
  font-family: SuzukiHeadline;
}
.promotion-section-component .promotion-section-item__title span,
.promotion-section-component .promotion-section-item__title h1,
.promotion-section-component .promotion-section-item__title h2,
.promotion-section-component .promotion-section-item__title h3,
.promotion-section-component .promotion-section-item__title h4,
.promotion-section-component .promotion-section-item__title h5,
.promotion-section-component .promotion-section-item__title h6,
.promotion-section-component .promotion-section-item__title p {
  text-transform: uppercase;
}
.promotion-section-component .promotion-section-item__desc {
  font-size: 1.3rem;
}
.promotion-section-component .promotion-section-item__button {
  color: white;
  padding: 10px 40px;
  border: 1px solid white;
  text-align: center;
  width: 200px;
  font-size: 1rem;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}
.promotion-section-component .promotion-section-item__button:hover {
  background-color: #005aab;
}
@media (max-width: 1400px) {
  .promotion-section-component .promotion-section-item {
    flex: 0 0 100%;
    padding: 30px;
  }
}
@media (max-width: 1200px) {
  .promotion-section-component .promotion-section-item {
    flex: 0 0 100%;
    padding: 80px;
  }
}
@media (max-width: 900px) {
  .promotion-section-component .promotion-section-item {
    padding: 20px;
    flex-wrap: wrap;
    height: 250px;
  }
  .promotion-section-component .promotion-section-item__desc {
    font-size: 16px;
    text-align: center;
    padding-bottom: 10px;
  }
  .promotion-section-component .promotion-section-item__button {
    font-size: 18px;
  }
  .promotion-section-component .promotion-section-item__left {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .promotion-section-component .promotion-section-item__title {
    font-size: 40px;
    line-height: 45px;
    text-align: center;
    padding-bottom: 10px;
  }
  .promotion-section-component .promotion-section-item__right {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}
