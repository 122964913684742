.bike-text-slider-component .bike-text-slider-item {
  overflow: hidden;
  position: relative;
  min-height: 800px;
}
.bike-text-slider-component .bike-text-slider-item__background-image {
  position: absolute;
  left: 50%;
  width: unset !important;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
}
.bike-text-slider-component .bike-text-slider-item-navigation {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 4;
}
.bike-text-slider-component .bike-text-slider-item-navigation__arrow-button {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.1s linear;
}
.bike-text-slider-component
  .bike-text-slider-item-navigation__arrow-button:hover {
  background-color: #ffffff;
  height: 60px;
  width: 60px;
}
.bike-text-slider-component .bike-text-wrapper {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 150px 200px;
}
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container {
  max-width: 70%;
  flex-wrap: wrap;
  z-index: 3;
}
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container
  p,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container
  span,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container
  h1,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container
  h2,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container
  h3,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container
  h4,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container
  h5,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container
  h6 {
  text-shadow: 0px 0px 40px #000000;
}
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container__title {
  width: 100%;
}
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container__title
  span,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container__title
  h1,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container__title
  h2,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container__title
  h3,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container__title
  h4,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container__title
  h5,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container__title
  h6,
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container__title
  p {
  text-transform: uppercase;
}
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container__desc {
  width: 100%;
}
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container__button {
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container__button
  button {
  border: none;
  font-size: 22px;
  padding: 7px 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: transform 0.3s linear;
  -moz-transition: transform 0.3s linear;
  -ms-transition: transform 0.3s linear;
  -o-transition: transform 0.3s linear;
  transition: transform 0.3s linear;
}
.bike-text-slider-component
  .bike-text-wrapper
  .bike-text-slider-content-container__button
  button:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}
.bike-text-slider-component .owl-nav {
  position: absolute;
  display: flex;
  align-items: flex-start;
  top: 0;
  right: 0;
}
.bike-text-slider-component .owl-nav .owl-prev,
.bike-text-slider-component .owl-nav .owl-next {
  background-color: #dbe0e4;
  padding: 10px 15px;
  justify-content: flex-end;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  z-index: 2;
}
.bike-text-slider-component .owl-nav .owl-prev::after,
.bike-text-slider-component .owl-nav .owl-next::after {
  font-family: FontAwesome;
  color: #003145;
  font-size: 1rem;
}
.bike-text-slider-component .owl-nav .owl-prev::after {
  content: "\f177";
}
.bike-text-slider-component .owl-nav .owl-prev:hover {
  background-color: white;
  padding: 15px 20px;
}
.bike-text-slider-component .owl-nav .owl-next::after {
  content: "\f178";
}
.bike-text-slider-component .owl-nav .owl-next:hover {
  background-color: white;
  padding: 15px 20px;
}
@media screen and (max-width: 1200px) {
  .bike-text-slider-component .bike-text-wrapper {
    padding: 50px 100px;
  }
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container {
    max-width: 80%;
  }
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__title {
    max-width: 100%;
  }
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__desc {
    max-width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__title {
    max-width: 100%;
  }
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__title
    span,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__title
    h1,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__title
    h2,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__title
    h3,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__title
    h4,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__title
    h5,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__title
    h6,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__title
    p {
    font-size: 40px !important;
    line-height: 45px !important;
    font-family: SuzukiPROHeadline;
    text-shadow: none;
  }
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__desc {
    max-width: 100%;
  }
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__desc
    span,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__desc
    h1,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__desc
    h2,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__desc
    h3,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__desc
    h4,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__desc
    h5,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__desc
    h6,
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container__desc
    p {
    font-size: 20px !important;
    line-height: 25px !important;
    text-shadow: none;
  }
}
@media screen and (max-width: 768px) {
  .bike-text-slider-component .bike-text-wrapper {
    padding: 25px;
  }
  .bike-text-slider-component
    .bike-text-wrapper
    .bike-text-slider-content-container {
    max-width: 100%;
  }
  .bike-text-slider-component .bike-text-slider-item {
    min-height: 600px;
  }
  .bike-text-slider-component .owl-nav .owl-prev::after {
    content: "\f177";
  }
  .bike-text-slider-component .owl-nav .owl-prev:hover {
    background-color: #dbe0e4;
    padding: 10px 15px;
  }
  .bike-text-slider-component .owl-nav .owl-next::after {
    content: "\f178";
  }
  .bike-text-slider-component .owl-nav .owl-next:hover {
    background-color: #dbe0e4;
    padding: 10px 15px;
  }
}
