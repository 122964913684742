@keyframes stickySelector {
  from {
    top: 0;
  }
  to {
    bottom: 0;
  }
}
@-moz-keyframes stickySelector {
  from {
    top: 0;
  }
  to {
    bottom: 0;
  }
}
@-webkit-keyframes stickySelector {
  from {
    top: 0;
  }
  to {
    bottom: 0;
  }
}
.pricelist-view {
  position: relative;
}
.pricelist-view .type-lead {
  font-size: 7rem;
  line-height: 7rem;
  font-family: SuzukiPROHeadline;
  color: rgba(0, 44, 72, 0.3);
}
.pricelist-view .pdf-true {
  display: block;
}
.pricelist-view .pdf-false {
  display: none;
}
.pricelist-view .pdf-download {
  background: #fff;
  border-radius: 50%;
  padding: 0.5rem 0.8rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  position: fixed;
  right: 2rem;
  bottom: 14.2rem;
  z-index: 9999;
  -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
}
.pricelist-view .pdf-download span {
  display: none;
}
.pricelist-view .pdf-download:after {
  content: '\f019';
  display: inline-flex;
  font-size: 1rem;
  font-family: FontAwesome;
}
.pricelist-view > * {
  color: #002c48;
}
.pricelist-view .container {
  padding-top: 0;
}
.pricelist-view__type-selector {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 0 auto;
  left: 0;
  right: 0;
  padding: 0;
  -webkit-transition: 0.5s linear;
  -moz-transition: 0.5s linear;
  -ms-transition: 0.5s linear;
  -o-transition: 0.5s linear;
  transition: 0.5s linear;
  position: absolute;
}
.pricelist-view__type-selector.sticky {
  transition: all 0.5s linear;
  z-index: 10000;
  position: fixed;
  justify-content: space-evenly;
  bottom: 0;
  margin: 0 auto;
  padding: 0rem 2rem;
  left: 0;
  right: 0;
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 0rem;
  width: 100%;
  max-width: 100%;
  background: #002c48;
}
.pricelist-view__type-selector.sticky .type {
  border: none;
}
.pricelist-view__type-selector.sticky .type.active,
.pricelist-view__type-selector.sticky .type:hover {
  background: transparent;
}
.pricelist-view__type-selector.sticky .type.active div,
.pricelist-view__type-selector.sticky .type:hover div {
  font-family: SuzukiPROHeadline;
  cursor: pointer;
}
.pricelist-view__type-selector.sticky .type.active div:after,
.pricelist-view__type-selector.sticky .type:hover div:after {
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
  width: 100%;
  content: '';
  display: block;
  margin: 0 auto;
  height: 3px;
  background: #e40012;
  position: absolute;
  bottom: 0.3rem;
  z-index: -1;
}
.pricelist-view__type-selector.sticky .type div {
  color: white;
  font-family: SuzukiPROHeadline;
  opacity: 1;
}
.pricelist-view__type-selector.sticky .type div:after {
  width: 0;
  content: '';
  display: block;
  margin: 0 auto;
  height: 3px;
  background: #e40012;
  position: absolute;
  bottom: 0.3rem;
  z-index: -1;
}
.pricelist-view__type-selector .type {
  transform: skew(-30deg);
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  padding: 1rem 1.5rem;
  border-right: 1px solid #004d9f;
  border-bottom: 1px solid #004d9f;
  margin-right: 0.2rem;
}
.pricelist-view__type-selector .type.active,
.pricelist-view__type-selector .type:hover {
  background: #004d9f;
  border: none;
}
.pricelist-view__type-selector .type.active div,
.pricelist-view__type-selector .type:hover div {
  opacity: 1;
  text-decoration: none;
  color: white;
  z-index: 2;
}
.pricelist-view__type-selector .type.active div:after,
.pricelist-view__type-selector .type:hover div:after {
  content: '';
  display: block;
  width: 100%;
  margin: 0 auto;
  height: 3px;
  background: #e40012;
  position: absolute;
  bottom: 0.3rem;
  z-index: 1;
  display: none;
}
.pricelist-view__type-selector .type div {
  transform: skew(30deg);
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -ms-transform: skew(30deg);
  -o-transform: skew(30deg);
  display: flex;
  font-size: 1rem;
  text-decoration: none;
  position: relative;
  color: #002c48;
  z-index: 2;
}
.pricelist-view__type-selector .type div:hover {
  text-decoration: none;
  z-index: 2;
}
.pricelist-view__type-selector .type div:hover:after {
  content: '';
  display: block;
  width: 100%;
  margin: 0 auto;
  height: 3px;
  background: #e40012;
  position: absolute;
  bottom: 0.3rem;
  z-index: 1;
  display: none;
}
.pricelist-view__item {
  margin: 6rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pricelist-view__item.pricelist-mt {
  padding-top: 10rem;
}
.pricelist-view__item.pricelist-mt:first-child {
  padding-top: 6rem;
}
.pricelist-view__item--motor {
  max-height: 9rem;
  position: relative;
}
.pricelist-view__item--motor img {
  max-height: 9rem;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.pricelist-view__item--motor img:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.pricelist-view__item--motor .datas {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0;
}
.pricelist-view__item--motor .datas__year {
  position: relative;
  font-family: SuzukiPROHeadline;
  color: white;
  line-height: 1rem;
  padding: 0.2rem 0.5rem;
  background: #004d9f;
  top: -2rem;
  bottom: auto;
  margin-right: 0.1rem;
}
.pricelist-view__item--motor .datas__year:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.8rem 0.8rem 0;
  border-color: transparent #004d9f transparent transparent;
}
.pricelist-view__item--motor .datas__new,
.pricelist-view__item--motor .datas__discount,
.pricelist-view__item--motor .datas__colors {
  position: relative;
  font-family: SuzukiPROHeadline;
  color: white;
  line-height: 1rem;
  padding: 0.2rem 0.5rem;
  text-transform: uppercase;
  top: -2rem;
}
.pricelist-view__item--motor .datas__new {
  background: #002c48;
}
.pricelist-view__item--motor .datas__new:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.8rem 0.8rem 0 0;
  border-color: #002c48 transparent transparent transparent;
}
.pricelist-view__item--motor .datas__discount {
  background: #e40012;
}
.pricelist-view__item--motor .datas__discount:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.8rem 0.8rem 0 0;
  border-color: #e40012 transparent transparent transparent;
}
.pricelist-view__item--motor .datas__colors {
  display: flex;
  position: relative;
  top: -2.3rem;
}
.pricelist-view__item--motor .datas__colors .color {
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  margin-right: 0.2rem;
}
.pricelist-view__item--apr {
  display: flex;
  align-items: baseline;
}
.pricelist-view__item--apr > * {
  font-size: 0.778rem;
  text-transform: uppercase;
  cursor: pointer;
}
.pricelist-view__item--apr .text {
  opacity: 0.8;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.pricelist-view__item--apr .text:hover {
  color: #004d9f;
}
.pricelist-view__item--apr .text span {
  cursor: pointer;
  font-size: 0.888rem;
  font-family: SuzukiPROHeadline;
}
.pricelist-view__item--apr .button {
  margin-left: 1rem;
  padding: 0.1rem 1rem;
  background: #004d9f;
  color: white;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.pricelist-view__item--apr .button:hover {
  background: white;
  color: #004d9f;
}
.pricelist-view__item--motordata {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pricelist-view__item--motordata .slogan {
  font-size: 1.111rem;
  margin-bottom: 1rem;
}
.pricelist-view__item--motordata .name {
  font-family: SuzukiPROHeadline;
  font-size: 3rem;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
  color: #002c48;
  text-decoration: none;
}
.pricelist-view__item--motordata .name:hover {
  color: #004d9f;
}
.pricelist-view__item--motordata .name .abs {
  font-size: 1.777rem;
  position: relative;
  bottom: 0.8rem;
  padding: 0 0.3rem;
  color: #e40012;
  font-family: SuzukiPROHeadline;
}
.pricelist-view__item--price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pricelist-view__item--price .text {
  font-size: 1.111rem;
  font-family: SuzukiPROHeadline;
  text-transform: uppercase;
}
.pricelist-view__item--price .price.discounted > * {
  color: #e40012;
}
.pricelist-view__item--price .price > * {
  font-size: 3rem !important;
  text-transform: uppercase;
  line-height: 3rem;
  margin: 0;
  font-family: SuzukiPROHeadline;
  color: #004d9f;
}
.pricelist-view__item--price .discount-price {
  font-size: 1.111rem !important;
  text-transform: uppercase;
  color: #e40012;
}
.pricelist-view .pricelist-text {
  background: #002c48;
  position: relative;
}
.pricelist-view .pricelist-text .filter {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-blend-mode: multiply;
}
.pricelist-view .pricelist-text .container {
  color: white;
  z-index: 3;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .pricelist-view .sticky {
    display: flex !important;
  }
  .pricelist-view .pdf-false {
    display: block;
  }
  .pricelist-view .pdf-download {
    right: 1rem;
    bottom: 4rem;
    padding: 1rem 1.5rem;
  }
  .pricelist-view .pdf-download:after {
    font-size: 2rem;
  }
}
@media screen and (max-width: 770px) {
  .container {
    max-width: 100% !important;
    padding-top: 0 !important;
  }
  .pricelist-view__type-selector {
    overflow-x: scroll;
    justify-content: flex-start;
    padding: 0 1rem;
    background: transparent;
    padding-bottom: 10px;
    margin-top: 0;
    /* IE 11 */
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .pricelist-view__type-selector:not(.sticky) .type {
    transform: skew(-30deg);
    -webkit-transform: skew(-30deg);
    -moz-transform: skew(-30deg);
    -ms-transform: skew(-30deg);
    -o-transform: skew(-30deg);
    border-bottom: 1px solid #002c48;
    border-right: 1px solid #002c48;
    margin-right: 0.5rem !important;
  }
  .pricelist-view__type-selector:not(.sticky) .type div {
    transform: skew(30deg);
    -webkit-transform: skew(30deg);
    -moz-transform: skew(30deg);
    -ms-transform: skew(30deg);
    -o-transform: skew(30deg);
    color: #002c48 !important;
    display: inline-flex;
    opacity: 1;
    font-family: SuzukiPROHeadline;
    padding: 0rem 1.5rem;
  }
  .pricelist-view__type-selector:not(.sticky) .type div:after {
    display: none !important;
  }
  .pricelist-view__type-selector:not(.sticky) .type.active,
  .pricelist-view__type-selector:not(.sticky) .type:hover {
    background: #004d9f;
  }
  .pricelist-view__type-selector:not(.sticky) .type.active div,
  .pricelist-view__type-selector:not(.sticky) .type:hover div {
    color: white !important;
    font-family: SuzukiPROHeadline;
  }
  .pricelist-view__type-selector.sticky {
    justify-content: flex-start;
    background: #002c48;
  }
  .pricelist-view__type-selector.sticky .type {
    padding-top: 1.5rem;
  }
  .pricelist-view__type-selector.sticky .type div {
    color: white !important;
    opacity: 1;
    font-family: SuzukiPROHeadline;
  }
  .pricelist-view__type-selector.sticky .type.active,
  .pricelist-view__type-selector.sticky .type:hover {
    background: transparent;
  }
  .pricelist-view__type-selector::-webkit-scrollbar {
    display: none;
  }
  .pricelist-view__type-selector > * {
    white-space: nowrap;
  }
  .pricelist-view__type-selector .type:last-child {
    padding-right: 1rem;
  }
  .pricelist-view__item {
    padding-top: 5rem;
    flex-direction: column;
    align-items: center;
  }
  .pricelist-view__item:not(:first-child):after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    border-top: 2px solid rgba(0, 44, 72, 0.3);
    transform: rotate(-5deg);
    margin-top: -6rem;
  }
  .pricelist-view__item--shape {
    display: none;
  }
  .pricelist-view__item--motor,
  .pricelist-view__item--motordata {
    margin-bottom: 2rem;
  }
  .pricelist-view__item--motor {
    max-height: fit-content;
  }
  .pricelist-view__item--motor img {
    max-width: 100%;
    max-height: fit-content;
  }
  .pricelist-view__item--motor .datas {
    width: 100%;
  }
  .pricelist-view__item--motor .datas > * {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .pricelist-view__item--motor .datas__colors {
    right: 0;
    position: absolute;
  }
  .pricelist-view__item--motor .datas__colors .color {
    height: 2.5rem;
    width: 2.5rem;
  }
  .pricelist-view__item--motordata .name {
    font-size: 4rem;
  }
  .pricelist-view__item--motordata .slogan {
    font-size: 1.5rem;
  }
  .pricelist-view__item--price .text {
    font-size: 1.4rem;
  }
  .pricelist-view__item--price .price {
    margin-top: 2rem;
  }
  .pricelist-view__item--price .price p {
    font-size: 4rem !important;
  }
  .pricelist-view__item--price .discount-price p {
    font-size: 2rem;
  }
  .pricelist-view__item--apr {
    order: 2;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  .pricelist-view__item--apr .text,
  .pricelist-view__item--apr .text span {
    font-size: 1.4rem;
  }
  .pricelist-view__item--apr .button {
    margin: 0;
    margin-left: 2rem;
    font-size: 1.2rem;
  }
}
