.more-offers-view {
  min-height: 80vh;
  height: 100%;
}
.more-offers-view .owl-item {
  min-height: 80vh;
  max-height: 80vh;
}
.more-offers-view .owl-dots {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  bottom: 0;
  width: 100%;
  max-width: 100%;
}
.more-offers-view .owl-dots .owl-dot {
  width: 3rem;
  height: 2.5rem;
  margin: 0 0.3rem;
}
.more-offers-view .owl-dots .owl-dot.active button {
  background: #e40012;
}
.more-offers-view .owl-dots .owl-dot button {
  background: white;
  width: 3rem;
  height: 0.2rem;
  outline: none;
  border: none;
}
.more-offers-view .owl-nav .owl-prev:before {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: 2rem;
  position: absolute;
  content: '\f177';
  display: block;
  color: white;
  font-family: FontAwesome;
  font-size: 1.5rem;
}
.more-offers-view .owl-nav .owl-next:after {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 2rem;
  position: absolute;
  content: '\f178 ';
  display: block;
  color: white;
  font-family: FontAwesome;
  font-size: 1.5rem;
}
.more-offers-view__single {
  height: 100%;
  max-height: 80vh;
}
.more-offers-view__single.no-image .more-offers-view__single--container__row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.more-offers-view__single.no-image .more-offers-view__single--container__row--image {
  display: none;
}
.more-offers-view__single.no-image .more-offers-view__single--container__row--desc {
  height: 80vh;
}
.more-offers-view__single.no-image .more-offers-view__single--container__row--desc__short > *,
.more-offers-view__single.no-image .more-offers-view__single--container__row--desc__short p > *,
.more-offers-view__single.no-image .more-offers-view__single--container__row--desc__short span > * {
  font-size: 4rem !important;
  line-height: 4.5rem !important;
  font-family: SuzukiPROHeadline !important;
}
.more-offers-view__single--container {
  height: 100%;
  min-height: 80vh;
  max-height: 80vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.more-offers-view__single--container .filter {
  height: 100%;
  width: 100%;
  position: absolute;
}
.more-offers-view__single--container__row {
  max-width: 1170px;
  margin: 0 auto !important;
}
.more-offers-view__single--container__row:before,
.more-offers-view__single--container__row:after {
  display: flex !important;
}
.more-offers-view__single--container__row--image {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh !important;
  max-height: 70vh;
  flex-direction: column;
}
.more-offers-view__single--container__row--image .static-image img.img {
  max-width: 50%;
  margin: 0 auto !important;
}
.more-offers-view__single--container__row--image .static-image img.image {
  max-height: 50vh;
  max-width: 100%;
  width: auto;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
  cursor: pointer;
}
.more-offers-view__single--container__row--image .static-image img.image:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.more-offers-view__single--container__row--desc {
  min-height: 70vh;
  max-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.more-offers-view__single--container__row--desc__title {
  font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-size: 3.889rem;
  text-align: center;
  padding: 1rem;
  color: rgba(255, 255, 255, 0.8) !important;
  line-height: 4rem;
  text-transform: uppercase;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
  cursor: pointer;
}
.more-offers-view__single--container__row--desc__title:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.more-offers-view__single--container__row--desc__short {
  color: white;
  text-align: center;
}
.more-offers-view__single--container__row--desc__short p,
.more-offers-view__single--container__row--desc__short p span,
.more-offers-view__single--container__row--desc__short span p {
  line-height: 1.4rem;
  margin: 0;
}
.more-offers-view__single--container__row--desc__logo {
  padding-bottom: 1rem;
}
@media screen and (max-width: 1170px) {
  .more-offers-view__single--container__row {
    max-width: 90%;
    margin: 0 auto !important;
  }
  .more-offers-view__single--container__row--desc__short p,
  .more-offers-view__single--container__row--desc__short p span,
  .more-offers-view__single--container__row--desc__short span p {
    line-height: 20px;
  }
}
@media screen and (max-width: 767px) {
  .more-offers-view__single.no-image .more-offers-view__single--container__row {
    min-height: 80vh;
  }
  .more-offers-view__single--container__row--image,
  .more-offers-view__single--container__row--desc {
    min-height: 40vh !important;
    max-height: 40vh !important;
  }
  .more-offers-view__single--container__row--image {
    max-height: 40vh;
  }
  .more-offers-view__single--container__row--image .static-image {
    max-height: 40vh;
  }
  .more-offers-view__single--container__row--image .static-image img.image {
    max-width: 80%;
    margin: 0 auto;
  }
  .more-offers-view__single--container__row--image .static-image img.img {
    max-width: 30%;
  }
  .more-offers-view__single--container__row--desc__logo {
    padding-bottom: 0;
  }
  .more-offers-view__single--container__row--desc__logo img {
    max-width: 60%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 550px) {
  .more-offers-view {
    min-height: 60vh;
    height: 100%;
  }
  .more-offers-view .owl-item {
    min-height: 60vh;
    max-height: 60vh;
  }
  .more-offers-view__single.no-image .more-offers-view__single--container__row {
    min-height: 60vh;
  }
  .more-offers-view__single--container__row--image .static-image {
    max-height: 30vh;
  }
  .more-offers-view__single--container__row--image .static-image img {
    max-height: 30vh !important;
  }
  .more-offers-view__single--container__row--image,
  .more-offers-view__single--container__row--desc {
    min-height: 30vh !important;
    max-height: 30vh !important;
  }
}
@media screen and (max-width: 450px) {
  .more-offers-view {
    min-height: 80vh;
  }
  .more-offers-view .owl-item {
    min-height: 80vh;
    max-height: 80vh;
  }
  .more-offers-view__single.no-image .more-offers-view__single--container__row {
    min-height: 80vh;
  }
  .more-offers-view__single--container__row--image,
  .more-offers-view__single--container__row--desc {
    min-height: 40vh !important;
    max-height: 40vh !important;
  }
  .more-offers-view__single--container__row--image {
    max-height: 40vh;
  }
  .more-offers-view__single--container__row--image .static-image .image {
    max-width: 100% !important;
  }
  .more-offers-view__single--container__row--desc__logo {
    padding-bottom: 0;
  }
  .more-offers-view__single--container__row--desc__logo img {
    max-width: 60%;
    margin: 0 auto;
  }
}
