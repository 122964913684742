.testdrive-view {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}
.testdrive-view select {
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  width: 100%;
  font-size: 1.6rem;
  color: white;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  padding: 0.3rem 2rem;
}
.testdrive-view select option {
  color: #002c48;
}
.testdrive-view input:not([type='checkbox']) {
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  font-size: 1.111rem;
  padding: 0.5rem 2rem;
  width: 100%;
  margin-bottom: 2rem;
  color: white;
}
.testdrive-view input:not([type='checkbox'])::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1;
  /* Firefox */
}
.testdrive-view input:not([type='checkbox']):-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}
.testdrive-view input:not([type='checkbox'])::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}
.testdrive-view input[type='checkbox'] {
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid white;
  padding: 9px;
  margin: 1rem;
}
.testdrive-view input[type='checkbox']:checked {
  background-color: #e40012;
  color: #e40012;
}
.testdrive-view label {
  display: flex;
  flex-direction: row-reverse;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.testdrive-view .checkbox {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.testdrive-view .checkbox span {
  color: white !important;
  font-family: SuzukiPROHeadline;
  font-size: 1.111rem;
}
.testdrive-view .checkbox a {
  text-decoration: underline;
  color: white !important;
  font-family: SuzukiPROHeadline;
  font-size: 1.111rem;
}
.testdrive-view .recaptcha {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.testdrive-view .submit {
  width: 40%;
  margin: 0 auto;
  padding: 1rem 3rem;
  background: #004d9f;
  border: none !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  position: relative;
  font-size: 1.111rem;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  box-shadow: 5px 5px 0px 1px rgba(218, 41, 28, 0.2);
  transition: linear 0.3s;
}
.testdrive-view .submit:hover {
  background: #e40012;
  top: 5px;
  left: 5px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.testdrive-view .select-option {
  position: relative;
  margin-bottom: 2rem;
}
.testdrive-view .select-option:after {
  position: absolute;
  right: 2rem;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  bottom: 0;
  content: '\f078';
  width: 1rem;
  color: white;
  z-index: 0;
  font-family: FontAwesome;
  pointer-events: none;
}
.testdrive-view__start-text {
  font-size: 1.111rem;
  text-align: justify;
  margin-bottom: 5rem;
  margin-top: 2rem;
}
.testdrive-view__form-top--text,
.testdrive-view__form-bottom--text {
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  margin-bottom: 3rem;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .testdrive-view__form-top--text,
  .testdrive-view__form-bottom--text {
    letter-spacing: 0rem;
  }
  .testdrive-view__form-top--text:after,
  .testdrive-view__form-bottom--text:after {
    display: none !important;
  }
}
.testdrive-view__form-top--text:after,
.testdrive-view__form-bottom--text:after {
  content: '';
  width: 300%;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  display: block;
  position: relative;
}
.testdrive-view__form-bottom {
  margin: 3rem 0;
}
.testdrive-view .test-drive-form-sent-view {
  text-align: center;
  padding: 10rem 0;
}
.testdrive-view .test-drive-form-sent-view h3 {
  text-transform: uppercase;
  font-size: 5rem;
  font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
}
.testdrive-view .test-drive-form-sent-view h4 {
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}
.testdrive-view .test-drive-form-sent-view p {
  text-transform: uppercase;
  font-size: 1rem;
}
.testdrive-view .test-drive-form-sent-view__buttons {
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
}
