.bikes-view {
  overflow: hidden;
}
.bikes-view .owl-carousel .owl-dots.disabled {
  display: flex;
}
.bikes-view .owl-nav.disabled div {
  display: none;
}
.bikes-view .content {
  padding-bottom: 5rem;
}
.bikes-view .filter {
  background: #004d9f;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.bikes-view .slash {
  width: 1px !important;
  height: 70px;
}
.bikes-view .motor-image {
  position: absolute;
  max-width: 75% !important;
  bottom: -8rem;
  width: 100%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
@media screen and (max-width: 767px) {
  .bikes-view .motor-image {
    max-width: 85% !important;
  }
}
.bikes-view .motor-image:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.bikes-view .motor-datas {
  position: absolute;
  top: 10rem;
  color: white;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  width: 92%;
}
.bikes-view .motor-datas .motor-data {
  display: inline;
  text-align: center;
}
.bikes-view .motor-datas .motor-data img {
  width: auto;
  max-height: 25px;
  display: inline-block;
}
.bikes-view .motor-datas .motor-data .desc {
  font-size: 0.8999rem;
  text-transform: uppercase;
  font-weight: bold;
}
.bikes-view .motor-datas .motor-data .data {
  font-weight: bold;
  display: inline-block;
  height: 42px;
  line-height: 2rem;
  width: 100%;
  font-size: 2rem;
  font-family: SuzukiPROHeadline;
}
.bikes-view .motor-datas .motor-data .data div:not(.sup) {
  font-size: 1rem;
  align-items: flex-end;
  position: relative;
}
.bikes-view .motor-datas .motor-data div {
  background: -webkit-linear-gradient(#c0c0c0, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  pointer-events: none;
}
.bikes-view .motor-datas .motor-data div .sup {
  margin-top: -0.3rem;
  position: absolute;
  font-size: 0.8rem;
  right: -0.5rem;
}
.bikes-view .motor-datas span {
  position: relative;
  z-index: 2;
}
.bikes-view .bg-image {
  border-top: 1rem solid rgba(0, 44, 72, 0.1);
  border-bottom: 1rem solid rgba(0, 44, 72, 0.1);
  border-left: 2rem solid rgba(0, 44, 72, 0.1);
  border-right: 2rem solid rgba(0, 44, 72, 0.1);
  position: relative;
}
.bikes-view .tipus {
  position: absolute;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  font-family: SuzukiPROHeadline;
  font-size: 7rem;
  z-index: 1;
  top: 1rem;
}
.bikes-view .motortype {
  height: auto;
  display: flex;
  justify-content: center;
  overflow: visible;
  width: 100%;
  position: relative;
  cursor: pointer;
}
.bikes-view .owl-carousel {
  max-width: 1170px;
  touch-action: manipulation;
  padding: 5rem 0;
}
.bikes-view .owl-carousel .owl-nav {
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  top: 35%;
}
.bikes-view .owl-carousel .owl-nav .owl-prev::after {
  content: '';
  background-image: url('data:image/svg+xml;utf8,<svg enable-background="new 0 0 551.13 551.13" height="512" viewBox="0 0 551.13 551.13" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m361.679 275.565-223.896 223.897v51.668l275.565-275.565-275.565-275.565v51.668z"/></svg>');
  width: 80px;
  height: 80px;
  display: block;
  background-size: cover;
  position: absolute;
  left: -5rem;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
@media screen and (max-width: 992px) {
  .bikes-view .owl-carousel .owl-nav .owl-prev::after {
    display: none;
  }
}
@media screen and (max-width: 1050px) {
  .bikes-view .owl-carousel .owl-nav .owl-prev::after {
    left: -3rem;
  }
}
.bikes-view .owl-carousel .owl-nav .owl-next::after {
  content: '';
  background-image: url('data:image/svg+xml;utf8,<svg enable-background="new 0 0 551.13 551.13" height="512" viewBox="0 0 551.13 551.13" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m361.679 275.565-223.896 223.897v51.668l275.565-275.565-275.565-275.565v51.668z"/></svg>');
  width: 80px;
  height: 80px;
  display: block;
  background-size: cover;
  position: absolute;
  right: -5rem;
}
@media screen and (max-width: 1050px) {
  .bikes-view .owl-carousel .owl-nav .owl-next::after {
    right: -3rem;
  }
}
@media screen and (max-width: 992px) {
  .bikes-view .owl-carousel .owl-nav .owl-next::after {
    display: none;
  }
}
.bikes-view .owl-carousel .owl-nav div {
  color: #002c48;
}
.bikes-view .owl-carousel .owl-nav div:hover {
  color: #e40012;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.bikes-view .owl-carousel .owl-dots {
  margin: 10rem auto 0;
  display: flex;
  width: 90%;
  height: 3rem;
  justify-content: center;
  align-items: center;
  z-index: 2;
  flex-wrap: wrap;
  position: relative;
}
.bikes-view .owl-carousel .owl-dots .owl-dot {
  padding: 0 0.5rem;
  transform: skew(-20deg);
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -ms-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  border-right: 1px solid rgba(99, 97, 97, 0.66);
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.bikes-view .owl-carousel .owl-dots .owl-dot:first-child {
  border-left: 1px solid rgba(99, 97, 97, 0.66);
}
.bikes-view .owl-carousel .owl-dots .owl-dot button {
  white-space: nowrap;
  transform: skew(20deg);
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -ms-transform: skew(20deg);
  -o-transform: skew(20deg);
}
.bikes-view .owl-carousel .owl-dots .owl-dot:hover,
.bikes-view .owl-carousel .owl-dots .owl-dot.active {
  background-color: #002c48;
}
.bikes-view .owl-carousel .owl-dots .owl-dot:hover button,
.bikes-view .owl-carousel .owl-dots .owl-dot.active button {
  color: white;
}
.bikes-view .owl-carousel .owl-dots button {
  background: none;
  border: none;
  color: #002c48;
  font-family: SuzukiPROHeadline;
  font-size: 1.4rem;
}
.bikes-view .owl-carousel .owl-stage-outer {
  overflow: visible;
}
.bikes-view .owl-carousel .owl-stage-outer .owl-stage {
  left: 0px;
  display: block;
  transition: all 300ms ease 0s;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.bikes-view .owl-carousel .owl-stage-outer .owl-item {
  justify-content: center;
  display: flex;
  max-width: 1170px;
  transition: transform 0.75s ease, opacity 0.75s ease !important;
  transform: scale(0.75) translateX(0) translateY(0%);
  opacity: 0.5;
  transform-origin: center center;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.bikes-view .owl-carousel .owl-stage-outer .owl-item.active {
  transition: transform 0s ease, opacity 0s ease;
  transform: scale(1) translateX(0) translateY(0);
  opacity: 1;
  -webkit-filter: grayscale(0%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
}
.bikes-view .owl-carousel img {
  max-width: 1170px;
}
@media screen and (max-width: 992px) {
  .bikes-view .motor-datas {
    display: none;
  }
  .bikes-view .tipus {
    font-size: 7rem;
  }
  .bikes-view .motor-image {
    bottom: -5rem;
  }
}
@media screen and (max-width: 750px) {
  .bikes-view .tipus {
    font-size: 5rem;
  }
}
@media screen and (max-width: 450px) {
  .bikes-view .tipus {
    font-size: 4rem;
    top: 2rem;
  }
}
