.single-news-view {
  max-width: 100%;
  margin: 0 auto;
  color: #163040;
}
.single-news-view r h1 {
  font-size: 2.2rem;
}
.single-news-view h2 {
  font-size: 2rem;
}
.single-news-view p {
  font-size: 1rem;
  line-height: 1.5rem;
}
.single-news-view img {
  max-width: 100%;
  height: auto;
}
.single-news-view .content {
  max-width: 1170px;
  padding: 2rem 2rem 2rem 2rem;
  margin: 0 auto;
  background: white;
  -webkit-box-shadow: 0 0 100px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 100px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 100px 1px rgba(0, 0, 0, 0.1);
}
.single-news-view .content img {
  height: auto;
  max-width: 100%;
}
.single-news-view .content iframe {
  max-width: 100%;
  height: 450px;
}
@media screen and (max-width: 992px) {
  .single-news-view .content iframe {
    height: 350px;
  }
}
@media screen and (max-width: 500px) {
  .single-news-view .content iframe {
    height: 300px;
  }
}
.single-news-view .head-img {
  width: 100%;
  position: relative;
  min-height: 6rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.single-news-view .head-img .date {
  display: table;
  margin: 0 auto;
  position: absolute !important;
  color: white;
  background: #004d9f;
  line-height: 2rem !important;
  font-size: 0.8333rem !important;
  text-transform: uppercase;
  bottom: -1rem;
}
.single-news-view .head-img .date .before::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: -2rem !important;
  top: 0;
  border-style: solid;
  border-width: 1rem 2rem 1rem 0 !important;
  border-color: transparent #004d9f transparent transparent;
}
.single-news-view .head-img .date .after::after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  right: -2rem;
  top: 0;
  border-style: solid;
  border-width: 1rem 0 1rem 2rem;
  border-color: transparent transparent transparent #004d9f;
}
.single-news-view .lead,
.single-news-view .lead > * {
  color: white;
  max-width: 1170px;
  width: 100%;
  font-size: 4rem;
  padding: 2rem 0;
  text-align: center;
  font-family: SuzukiPROHeadline !important;
  line-height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 0 25px black;
  text-transform: uppercase;
}
@media screen and (max-width: 1200px) {
  .single-news-view p {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
  .single-news-view .head-img .date {
    font-size: 1.1rem !important;
  }
}
