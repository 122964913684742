.technology-section-mobile-component {
  color: #002c48;
}
.technology-section-mobile-component .tech-mobile-indicator-container {
  margin-top: 30px;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
}
.technology-section-mobile-component .tech-mobile-indicator-container__image-wrapper {
  padding: 20px;
}
.technology-section-mobile-component .tech-mobile-indicator-container__image-wrapper--active {
  border-bottom: 3px solid #e40012;
}
.technology-section-mobile-component .tech-mobile-indicator-container__image {
  max-width: 40px;
  height: auto;
  display: block;
  margin: 0 auto;
}
.technology-section-mobile-component .tech-mobile-content-container {
  background-image: url("../../../../Assets/BikePage/bluePatter.png");
  padding: 20px;
  overflow: hidden;
  color: white;
  font-size: 16px;
  line-height: 25px;
}
.technology-section-mobile-component .tech-mobile-indicator-container__arrows {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  min-width: 60px;
}
.technology-section-mobile-component .tech-mobile-indicator-container__arrows--right {
  height: 30px;
  width: auto;
  display: inline-block;
}
.technology-section-mobile-component .tech-mobile-indicator-container__arrows--left {
  height: 30px;
  width: auto;
  display: inline-block;
}
.technology-section-mobile-component .technology-content-mobile-slider {
  display: grid;
}
.technology-section-mobile-component .technology-content-mobile-slider__item {
  grid-area: 1 / 1;
  transition: all 0.3s ease-out;
}
.technology-section-mobile-component .technology-content-mobile-slider.technology-mobile-slider-forward .technology-content-mobile-slider__item.fade-enter {
  opacity: 0%;
  transform: translateX(100%);
}
.technology-section-mobile-component .technology-content-mobile-slider.technology-mobile-slider-forward .technology-content-mobile-slider__item.fade-enter-active {
  opacity: 100%;
  transform: translateX(0%);
}
.technology-section-mobile-component .technology-content-mobile-slider.technology-mobile-slider-forward .technology-content-mobile-slider__item.fade-exit {
  opacity: 0%;
  transform: translateX(-100%);
}
.technology-section-mobile-component .technology-content-mobile-slider.technology-mobile-slider-backward .technology-content-mobile-slider__item.fade-enter {
  opacity: 0%;
  transform: translateX(-100%);
}
.technology-section-mobile-component .technology-content-mobile-slider.technology-mobile-slider-backward .technology-content-mobile-slider__item.fade-enter-active {
  opacity: 100%;
  transform: translateX(0%);
}
.technology-section-mobile-component .technology-content-mobile-slider.technology-mobile-slider-backward .technology-content-mobile-slider__item.fade-exit {
  opacity: 0%;
  transform: translateX(100%);
}
