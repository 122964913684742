.new_dealer-form .headline {
  font-family: SuzukiPROHeadline;
  font-family: 30px;
  text-transform: uppercase;
  color: #002c48;
  position: relative;
  margin: 1rem 0;
}
.new_dealer-form .headline h3 {
  padding-left: 80px;
}
.new_dealer-form .headline:before {
  content: '';
  width: 60px;
  height: 3px;
  background: #e40012;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.new_dealer-form small {
  color: #002c48;
}
.new_dealer-form p {
  padding: 30px 15px 0 15px;
  color: #002c48;
  margin-bottom: 0;
}
.new_dealer-form__form-group {
  margin-bottom: 40px;
}
.new_dealer-form__form-group:nth-child(3) {
  margin-bottom: 0px;
}
.new_dealer-form__form-group .label {
  font-size: 14px;
  color: #004d9f;
  font-variant: small-caps;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.new_dealer-form__form-group input,
.new_dealer-form__form-group textarea {
  background: transparent !important;
  outline: none !important;
  border: none;
  border-bottom: 1px solid #002c48;
  width: 100%;
  cursor: text;
  font-size: 16px;
  color: #002c48;
}
.new_dealer-form__tooltip {
  font-size: 12px;
  color: #004d9f;
  margin-bottom: 10px;
}
.new_dealer-form__finish {
  display: flex;
  flex-direction: column;
}
.new_dealer-form__finish button {
  margin-top: 20px;
}
.new_dealer-form label {
  display: inline;
  align-items: center;
  color: #002c48;
  font-size: 16px;
}
.new_dealer-form label a {
  margin: 0 5px;
  color: #002c48 !important;
  text-decoration: underline;
  font-weight: 600;
}
.new_dealer-form input[type='checkbox'] {
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #002c48;
  padding: 9px;
  margin: 0 1rem 0 0;
}
.new_dealer-form input[type='checkbox']:checked {
  background-color: #e40012;
  color: #e40012;
}
@media (max-width: 992px) {
  .new_dealer-form button {
    width: 100%;
    margin: 0 auto;
    margin: 30px 0;
  }
}
