.formsent-view {
  text-align: center;
  padding: 4rem 0;
}
.formsent-view h3 {
  text-transform: uppercase;
  font-size: 5rem;
  font-family: SuzukiPROHeadline, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
}
.formsent-view h4 {
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}
.formsent-view p {
  text-transform: uppercase;
  font-size: 1rem;
}
