.new_dealer-view {
  margin-top: 50px;
  position: relative;
}
.new_dealer-view .recaptcha {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  position: absolute;
}
.new_dealer-view .error-text {
  color: #e40012;
  margin-top: 20px;
  padding-left: 15px;
  font-size: 18px;
}
@media (max-width: 992px) {
  .new_dealer-view .new_dealer-wrapper .new_dealer-image {
    display: none;
  }
}
.new_dealer-view .new_dealer-wrapper .new_dealer-text {
  padding: 15px;
}
.new_dealer-view .new_dealer-wrapper .new_dealer-text--title {
  font-size: 60px;
  color: #002c48;
  font-family: SuzukiProHeadline;
  text-transform: uppercase;
}
@media (max-width: 500px) {
  .new_dealer-view .new_dealer-wrapper .new_dealer-text--title {
    font-size: 40px;
  }
}
.new_dealer-view .new_dealer-wrapper .new_dealer-text--highlight {
  color: #002c48;
  font-size: 20px;
  line-height: 30px;
}
.new_dealer-view .new_dealer-wrapper .new_dealer-text--italic {
  font-weight: bold;
  font-style: italic;
  color: #002c48;
  font-size: 20px;
  line-height: 30px;
}
.new_dealer-view .new_dealer-wrapper .new_dealer-text--long {
  color: #002c48;
  font-size: 20px;
  line-height: 30px;
}
.new_dealer-view .new_dealer-form {
  padding-bottom: 40px;
}
.new_dealer-view .new_dealer-image {
  position: absolute;
  bottom: 0;
}
.new_dealer-view .new_dealer-image img {
  margin: 0 auto;
  max-width: 95%;
}
.new_dealer-view .new_dealer-image--mobile {
  display: none;
  max-width: 100%;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .new_dealer-view .new_dealer-image--mobile {
    display: block;
  }
}
.new_dealer-view .new_dealer-image--mobile img {
  max-width: 100%;
}
.new_dealer-view .form_sent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.new_dealer-view .form_sent .new_dealer-image {
  position: relative;
}
