.wrapper {
  color: #004d9f;
}
.wrapper span label {
  color: #004d9f;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 3px;
  padding-bottom: 10px;
}
@media (max-width: 992px) {
  .wrapper span label {
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .wrapper span label {
    font-size: 10px;
  }
}
.wrapper .file_list span {
  color: #e40012 !important;
  cursor: pointer;
  font-weight: 900;
}
.file-upload {
  cursor: pointer;
  display: inline-block;
  width: 4rem;
  min-height: 50px;
  display: flex;
  background-color: #f0f0f0;
  border: 1px solid #d5d5d5;
  color: #002c48;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.2rem;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.file-upload__label {
  padding: 15px 0;
  margin: 0 !important;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.file-upload__label .spinner {
  z-index: 10;
}
.file-upload:hover {
  border-color: #004d9f;
}
.file-upload__icon {
  cursor: pointer;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -ms-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
}
.file-upload__icon::before {
  font-size: 1.4rem !important;
  margin: 0 !important;
  padding-right: 0.5rem;
}
.file-upload__input {
  overflow: hidden;
  width: 0;
  height: 0;
  border: none;
  background-color: transparent;
  color: transparent;
}
