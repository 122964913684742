.iline-blue-to-red-button {
  padding: 10px 120px;
  background: #004d9f;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  margin-top: 3rem;
  position: relative;
  font-size: 1.111rem;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  color: white;
  -webkit-box-shadow: 5px 5px 0px 1px rgba(228, 0, 18, 0.2);
  -moz-box-shadow: 5px 5px 0px 1px rgba(228, 0, 18, 0.2);
  box-shadow: 5px 5px 0px 1px rgba(228, 0, 18, 0.2);
  -webkit-transition: linear 0.3s;
  -moz-transition: linear 0.3s;
  -ms-transition: linear 0.3s;
  -o-transition: linear 0.3s;
  transition: linear 0.3s;
  width: max-content;
  margin: 0 auto;
}
.iline-blue-to-red-button.single {
  white-space: nowrap;
}
.iline-blue-to-red-button:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: #e40012;
  position: relative;
  top: 5px;
  left: 5px;
}
.iline-blue-to-red-button.inverse {
  background: #e40012;
  -webkit-box-shadow: 5px 5px 0px 1px rgba(0, 77, 159, 0.2);
  -moz-box-shadow: 5px 5px 0px 1px rgba(0, 77, 159, 0.2);
  box-shadow: 5px 5px 0px 1px rgba(0, 77, 159, 0.2);
}
.iline-blue-to-red-button.inverse:hover {
  background: #004d9f;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.iline-blue-to-red-button p {
  margin: 0;
}
@media only screen and (max-width: 1200px) and (min-width: 376px) {
  .iline-blue-to-red-button {
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 375px) {
  .iline-blue-to-red-button {
    margin-top: 1rem;
    width: 85%;
    font-size: 1.5rem;
  }
}
