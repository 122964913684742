.main-view {
  position: relative;
}
.main-view .news .image-container {
  overflow: hidden;
}
.main-view .news .image-container:after {
  content: '';
  display: block;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.main-view .news .image-container img {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.main-view .news .image-container.hovering:after {
  background: rgba(0, 0, 0, 0.5);
}
.main-view .news .image-container.hovering img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.main-view .news .news-lead {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: fit-content;
  padding: 5rem;
  margin: 0 auto;
}
.main-view .news .news-lead .title {
  color: #002c48;
  font-size: 4.1667rem;
  line-height: 3.2rem;
  font-family: SuzukiPROHeadline;
  text-transform: uppercase;
  position: relative;
  font-style: italic;
}
.main-view .news .news-lead .title:before,
.main-view .news .news-lead .title:after {
  height: 1.5rem;
  content: '';
  display: block;
  border-left: 3px solid #e40012;
  position: relative;
  transform: skew(-15deg);
}
.main-view .news .news-lead .title:before {
  left: 1.7rem;
  margin-bottom: 0.5rem;
}
.main-view .news .news-lead .title:after {
  position: absolute;
  bottom: -3rem;
}
.main-view .news .news-lead .desc {
  color: #002c48;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 1.05rem;
  font-style: italic;
}
.main-view .news .news-lead .desc span {
  color: #e40012;
}
.main-view .news .more-news-button {
  text-align: center;
  margin: 2rem 0;
}
.main-view .news .more-news-button .button {
  text-transform: uppercase;
  font-size: 0.777rem;
  letter-spacing: 0.05rem;
  color: #002c48;
  position: relative;
  display: inline-block;
  padding: 0 0.5rem 0 0;
  cursor: pointer;
}
.main-view .news .more-news-button .button:before,
.main-view .news .more-news-button .button:after {
  content: '';
  position: absolute;
  top: 50%;
  width: calc(390px);
  margin-top: -2px;
  border-top: 2px solid #e40012;
  border-bottom: 2px solid #e40012;
  border-radius: 50%;
}
.main-view .news .more-news-button .button:before {
  right: 100%;
  margin-right: 15px;
}
.main-view .news .more-news-button .button:after {
  left: 100%;
  margin-right: 15px;
}
@media screen and (max-width: 1200px) {
  .main-view .news .more-news-button {
    overflow: hidden;
  }
  .main-view .news .more-news-button .button {
    font-size: 1.5rem;
    padding: 0 1rem 0 0;
  }
}
@media screen and (max-width: 700px) {
  .main-view .news .news-lead .title:after {
    bottom: -3.5rem;
  }
}
@media screen and (max-width: 500px) {
  .main-view .news .news-lead .title:after {
    bottom: -3.2rem;
  }
}
