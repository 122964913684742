.spinner {
  z-index: 10000;
  width: 100%;
  text-align: center;
}
.spinner .lds-container {
  position: relative;
}
.spinner .lds-container .loading-suzuki-logo {
  position: absolute;
  max-width: 7rem;
  left: 2rem;
  height: auto;
}
.spinner .spinner-child {
  font-size: 1.2rem;
  max-width: 30rem;
  margin: 1rem auto 0 auto;
  color: #163040;
}
.spinner .lds-dual-ring,
.spinner .lds-dual-ring-2 {
  display: inline-block;
  width: 4.6rem;
  height: 4.6rem;
}
.spinner .lds-dual-ring::after,
.spinner .lds-dual-ring-2::after {
  content: ' ';
  display: block;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 50%;
  border: 0.2rem solid #005aab;
  border-color: #005aab transparent #005aab transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
  -moz-animation: lds-dual-ring 1.2s linear infinite;
  -ms-animation: lds-dual-ring 1.2s linear infinite;
  -o-animation: lds-dual-ring 1.2s linear infinite;
  animation: lds-dual-ring 1.2s linear infinite;
}
.spinner .lds-dual-ring {
  position: relative;
  background-image: url('../../../Assets/s_logo.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem auto;
}
.spinner .lds-dual-ring .lds-dual-ring-2 {
  position: absolute;
  -webkit-animation: lds-dual-ring-2 0.7s linear infinite;
  -moz-animation: lds-dual-ring-2 0.7s linear infinite;
  -ms-animation: lds-dual-ring-2 0.7s linear infinite;
  -o-animation: lds-dual-ring-2 0.7s linear infinite;
  animation: lds-dual-ring-2 0.7s linear infinite;
  opacity: 0.2;
  left: 0.5rem;
  top: 0.5rem;
  width: 3.6rem;
  height: 3.6rem;
}
.spinner .lds-dual-ring .lds-dual-ring-2::after {
  width: 3.6rem;
  height: 3.6rem;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-dual-ring-2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.tiny-spinner {
  width: 100%;
}
.tiny-spinner .spinner-child {
  display: none;
}
.tiny-spinner .lds-dual-ring {
  background-size: 0.8rem auto;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: -0.1rem;
  margin-bottom: -0.2rem;
  background-image: none;
}
.tiny-spinner .lds-dual-ring::after {
  width: 1.4rem;
  height: 1.4rem;
  border-width: 0.3rem;
}
.tiny-spinner .lds-dual-ring .lds-dual-ring-2 {
  width: 1.4rem;
  height: 1.4rem;
  left: 0;
  top: 0;
}
.tiny-spinner .lds-dual-ring .lds-dual-ring-2::after {
  width: 1.4rem;
  height: 1.4rem;
  border-width: 0.3rem;
}
.large-spinner .spinner-child {
  font-size: 2rem;
}
.large-spinner .lds-dual-ring,
.large-spinner .lds-dual-ring-2 {
  width: 16rem;
  height: 16rem;
}
.large-spinner .lds-dual-ring::after,
.large-spinner .lds-dual-ring-2::after {
  width: 16rem;
  height: 16rem;
  border-width: 0.5rem;
}
.large-spinner .lds-dual-ring {
  background-size: 7rem auto;
}
.large-spinner .lds-dual-ring .lds-dual-ring-2 {
  width: 14rem;
  height: 14rem;
  left: 1rem;
  top: 1rem;
}
.large-spinner .lds-dual-ring .lds-dual-ring-2::after {
  width: 14rem;
  height: 14rem;
}
.absolute-spinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 4rem;
  background-color: rgba(255, 255, 255, 0.4);
}
.negated-spinner .spinner-child {
  color: white;
}
.negated-spinner .lds-dual-ring::after {
  border-color: #da291c transparent #da291c transparent;
}
.negated-spinner .lds-dual-ring .lds-dual-ring-2::after {
  border-color: #da291c transparent #da291c transparent;
}
