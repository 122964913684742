.news-view {
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  padding-top: 1rem;
}
.news-view > .row {
  max-width: 100%;
}
.news-view .newsimage {
  cursor: pointer;
}
.news-view .newsimage .image-container {
  overflow: hidden;
}
.news-view .newsimage .image-container:after {
  content: "";
  display: block;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.news-view .newsimage .image-container img {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.news-view .newsimage .image-container.hovering:after {
  background: rgba(0, 0, 0, 0.5);
}
.news-view .newsimage .image-container.hovering img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.news-view .newsimage .filter {
  position: absolute;
  opacity: 0.6;
}
.news-view .newsimage .filter:hover {
  display: none !important;
}
@media screen and (min-width: 1170px) {
  .video-view__selector {
    display: flex;
    justify-content: center;
  }
}
.news__overimage {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  text-shadow: 4px 4px 8px #000000;
}
.news__overimage .typeAndDate {
  display: flex;
  color: white;
}
.news__overimage .typeAndDate .type {
  background: #004d9f;
  position: relative;
  display: inline-flex;
  padding-right: 10px;
  margin-right: 2.3rem;
  font-size: 1.111rem;
  padding-left: 10px;
  line-height: 2.222rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  height: fit-content;
}
.news__overimage .typeAndDate .type span::after {
  content: "";
  position: absolute;
  right: -2.111rem;
  top: 0;
  width: 0;
  height: 0;
  border-color: #004d9f transparent transparent;
  border-style: solid;
  border-width: 2.222rem 2.222rem 0 0;
}
.news__overimage .typeAndDate .date {
  background: #e40012;
  position: relative;
  display: inline-table;
  padding-right: 10px;
  padding-left: 1rem;
  line-height: 1.5rem;
  font-size: 0.7778rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
  height: fit-content;
}
.news__overimage .typeAndDate .date span::before {
  content: "";
  position: absolute;
  left: -1.5rem;
  top: 0;
  width: 0;
  height: 0;
  border-color: transparent transparent #e40012;
  border-style: solid;
  border-width: 0 0 1.5rem 1.5rem;
}
.news__overimage .title {
  font-family: SuzukiPROHeadline;
  margin: 1rem 0;
  color: white;
}
.news__overimage .desc {
  font-style: italic;
  width: 50%;
  color: white;
}
.news__overimage .desc p {
  margin: 0;
}
.news__overimage.NORMAL .title {
  font-size: 2.222rem;
  line-height: 2.5rem;
  width: 80%;
}
.news__overimage.NORMAL .desc {
  font-size: 1.111rem;
  line-height: 1.3rem;
  width: 70%;
}
.news__overimage.WIDE .title {
  font-size: 1.667rem;
  line-height: 2rem;
  width: 40%;
}
.news__overimage.WIDE .desc {
  font-size: 1.111rem;
  line-height: 1.5rem;
  width: 50%;
}
.news__overimage.position-LEFT-TOP .title {
  padding-left: 2rem;
  border-left: 3px solid #e40012;
}
.news__overimage.position-LEFT-BOTTOM .title {
  padding-left: 2rem;
  border-left: 3px solid #e40012;
}
.news__overimage.position-RIGHT-TOP .title {
  padding-right: 2rem;
  border-right: 3px solid #e40012;
}
.news__overimage.position-RIGHT-BOTTOM .title {
  padding-right: 2rem;
  border-right: 3px solid #e40012;
}
.news-view__load-more {
  margin-bottom: 3rem;
  text-align: center;
}
.news-view__load-more--button--true {
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  color: #002c48;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 20px;
}
.news-view__load-more--button--true:before,
.news-view__load-more--button--true:after {
  content: "";
  position: absolute;
  top: 50%;
  width: calc(390px);
  margin-top: -2px;
  border-top: 2px solid #e40012;
  border-bottom: 2px solid #e40012;
  border-radius: 50%;
}
.news-view__load-more--button--true:before {
  right: 100%;
  margin-right: 15px;
}
.news-view__load-more--button--true:after {
  left: 100%;
  margin-right: 15px;
}
.news-view__load-more--button--false {
  display: none;
}
.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -0.25rem;
  /* gutter size offset */
  width: 100%;
}
.my-masonry-grid_column {
  padding-left: 0.5rem;
  /* gutter size */
  background-clip: padding-box;
}
/* Style your items */
.my-masonry-grid_column > .newsimage {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 0.5rem;
  position: relative;
}
.my-masonry-grid_column > .newsimage.WIDE img {
  width: 100%;
  max-height: 395px;
}
.my-masonry-grid_column > .newsimage.NORMAL img {
  width: 100%;
  max-height: 800px;
}
@media screen and (max-width: 1500px) {
  .position-LEFT-TOP .title,
  .position-LEFT-CENTER .title,
  .position-LEFT-BOTTOM .title {
    width: 100% !important;
  }
  .position-LEFT-TOP .desc,
  .position-LEFT-CENTER .desc,
  .position-LEFT-BOTTOM .desc {
    width: 100% !important;
  }
  .position-CENTER-TOP .title,
  .position-CENTER-CENTER .title,
  .position-CENTER-BOTTOM .title {
    width: 100% !important;
  }
  .position-CENTER-TOP .desc,
  .position-CENTER-CENTER .desc,
  .position-CENTER-BOTTOM .desc {
    width: 100% !important;
  }
  .position-RIGHT-TOP .title,
  .position-RIGHT-CENTER .title,
  .position-RIGHT-BOTTOM .title {
    width: 100% !important;
  }
  .position-RIGHT-TOP .desc,
  .position-RIGHT-CENTER .desc,
  .position-RIGHT-BOTTOM .desc {
    width: 100% !important;
  }
}
@media screen and (max-width: 1100px) {
  .news__overimage {
    padding-bottom: 1rem;
  }
  .news-view__load-more--button--true::after,
  .news-view__load-more--button--true::before {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .news__overimage {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .news__overimage .title {
    font-size: 1.7rem !important;
    line-height: 2rem !important;
  }
  .desc,
  .type {
    font-size: 1.2rem !important;
    line-height: 1.5rem !important;
  }
  .desc span::after,
  .type span::after {
    right: -1.4rem !important;
    border-width: 1.5rem 1.5rem 0 0 !important;
  }
  .type {
    margin-right: 1.5rem !important;
  }
  .date {
    font-size: 1rem !important;
    line-height: 1.3rem !important;
  }
  .date span::before {
    left: -1.3rem !important;
    border-width: 0 0 1.3rem 1.3rem !important;
  }
}
@media screen and (max-width: 700px) {
  .news__overimage {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 5rem;
  }
  .news__overimage .title {
    font-size: 2.5rem !important;
    line-height: 3rem !important;
  }
  .desc,
  .type {
    font-size: 1.7rem !important;
    line-height: 2rem !important;
    align-items: center;
  }
  .desc span::after,
  .type span::after {
    right: -2rem !important;
    border-width: 2rem 2rem 0 0 !important;
  }
  .type {
    margin-right: 2rem !important;
  }
  .date {
    font-size: 1.3rem !important;
    line-height: 1.5rem !important;
  }
  .date span::before {
    left: -1.5rem !important;
    border-width: 0 0 1.5rem 1.5rem !important;
  }
}
@media screen and (max-width: 500px) {
  .news__overimage {
    padding-bottom: 2rem;
  }
  .news__overimage .title {
    font-size: 2.2rem !important;
    line-height: 2.5rem !important;
  }
  .desc,
  .desc p,
  .desc span,
  .desc p span {
    font-size: 1.5rem !important;
    line-height: 1.5rem !important;
  }
  .type {
    margin-right: 1.2rem !important;
  }
  .type {
    font-size: 1rem !important;
    line-height: 1.2rem !important;
    align-items: center;
  }
  .type span::after {
    right: -1.4rem !important;
    border-width: 1.4rem 1.4rem 0 0 !important;
  }
  .date {
    font-size: 0.8rem !important;
    line-height: 1rem !important;
  }
  .date span::before {
    left: -1rem !important;
    border-width: 0 0 1rem 1rem !important;
  }
}
