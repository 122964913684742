/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 3rem;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #004d9f;
  top: -3rem;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}
.timeline::before {
  content: '';
  left: 0;
  margin: 0 auto;
  right: 0;
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  background-color: white;
  background-image: url('../../../../Assets/timeline-logo.png');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  z-index: 1;
}

/* Container around content */
.timeline-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  margin-top: 5rem;
}

/* The circles on the timeline */
.timeline-container::after {
  content: attr(data-year);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 50px;
  font-size: 1.111rem;
  font-family: SuzukiPROHeadline;
  height: 50px;
  right: -25px;
  background-color: white;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.timeline-container.left {
  left: 0;
}
.timeline-container.left::after {
  background-color: #004d9f;
  opacity: 0.9;
}
/* Place the container to the right */
.timeline-container.right {
  left: 50%;
}

/* Fix the circle for containers on the right side */
.timeline-container.right::after {
  left: -25px;
  background-color: #e40012;
  opacity: 0.9;
}

/* The actual content */
.timeline-container .item {
  background-color: white;
  position: relative;
  border-radius: 0;
  background-color: #e0e0e0;
  background-blend-mode: multiply;
  background-image: url('../../../../Assets/BG2.png');
}
.timeline-container .item img {
  max-width: 100%;
  width: 100%;
  border-top: 5px solid #e40012;
}

.timeline-container .item p {
  color: #002c48;
  padding: 1rem;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  .timeline::before {
    right: auto;
    left: 5px;
  }

  /* Full-width containers */
  .timeline-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .timeline-container.left::after,
  .timeline-container.right::after {
    left: 5px;
  }

  /* Make all right containers behave like the left ones */
  .timeline-container.right {
    left: 0%;
  }
}
