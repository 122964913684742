.cookie-row {
  padding: 0.5rem 2rem;
  background-color: #eaeaea;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  word-break: break-all;
  /* @media screen and (max-width: 576px) {
    flex-direction: column;
  } */
}
.cookie-row-title {
  font-family: SuzukiPRORegular;
  color: #002c48;
  margin: 0;
}
@media screen and (min-width: 1171px) {
  .cookie-row-title {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 1170px) {
  .cookie-row-title {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 992px) {
  .cookie-row-title {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 550px) {
  .cookie-row-title {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 350px) {
  .cookie-row-title {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 300px) {
  .cookie-row-title {
    font-size: 1.8rem;
  }
}
.cookie-row-button {
  outline: none;
  border: none;
  background: none;
  text-transform: uppercase;
  font-family: SuzukiPRORegular;
  font-size: 0.77777778rem;
  color: #002c48;
}
.cookie-row-button:focus,
.cookie-row-button:active {
  border: none;
  outline: none;
}
@media screen and (min-width: 1171px) {
  .button-icon {
    font-size: 0.77777778rem;
  }
}
@media screen and (max-width: 1170px) {
  .button-icon {
    font-size: 1rem;
  }
}
@media screen and (max-width: 992px) {
  .button-icon {
    font-size: 1rem;
  }
}
@media screen and (max-width: 550px) {
  .button-icon {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 350px) {
  .button-icon {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 300px) {
  .button-icon {
    font-size: 1.42857143rem;
  }
}
@media screen and (min-width: 577px) {
  .button-icon {
    display: none;
  }
}
.button-icon > svg {
  transition: transform 0.3s linear;
}
.button-icon-rotate {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.button-text {
  font-family: SuzukiPRORegular;
  margin-right: 0.5rem;
}
@media screen and (min-width: 1171px) {
  .button-text {
    font-size: 0.77777778rem;
  }
}
@media screen and (max-width: 1170px) {
  .button-text {
    font-size: 1rem;
  }
}
@media screen and (max-width: 992px) {
  .button-text {
    font-size: 1rem;
  }
}
@media screen and (max-width: 550px) {
  .button-text {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 350px) {
  .button-text {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 300px) {
  .button-text {
    font-size: 1.42857143rem;
  }
}
@media screen and (max-width: 576px) {
  .button-text {
    display: none;
  }
}
.cookie-row-detail {
  background-color: #f1f1f1;
  text-align: center;
  color: #002c48;
  padding: 0.5rem 2rem;
}
@media screen and (min-width: 1171px) {
  .cookie-row-detail {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1170px) {
  .cookie-row-detail {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 992px) {
  .cookie-row-detail {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 550px) {
  .cookie-row-detail {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 350px) {
  .cookie-row-detail {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 300px) {
  .cookie-row-detail {
    font-size: 1.5rem;
  }
}
