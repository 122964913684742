.anchor-nav {
  display: flex;
  position: fixed;
  top: 54px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000002;
  background-color: #004d9f;
  color: #005aab;
  -webkit-box-shadow: 0px 20px 10px -10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 20px 10px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 20px 10px -10px rgba(0, 0, 0, 0.2);
}
.anchor-nav__logo {
  background-color: #002c48;
  color: white;
  text-align: center;
  padding: 10px 20px;
  position: relative;
  text-transform: uppercase;
  font-family: SuzukiPROHeadline;
  cursor: pointer;
  font-size: 1rem;
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  margin-top: 6.5px;
  padding-left: 40px;
}
.anchor-nav__logo::after {
  border-top: 45px solid #002c48;
  border-left: 0px solid transparent;
  border-right: 20px solid transparent;
  position: absolute;
  content: ' ';
  width: 0;
  height: 0;
  right: -19px;
  top: 0;
  bottom: 0;
}
.anchor-nav__logo::before {
  content: '';
  width: 0;
  height: 0;
  right: -20px;
  bottom: 0px;
  position: absolute;
  border-style: solid;
  border-width: 10px 20px 0 0;
  border-color: #004d9f transparent transparent transparent;
}
.anchor-nav__hash {
  padding: 10px 20px;
  font-size: 14px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  text-transform: uppercase;
  color: white;
  font-variant: small-caps;
  font-style: italic;
}
.anchor-nav__hash .text {
  font-family: SuzukiPROHeadline;
  font-size: 1rem;
}
.anchor-nav__hash:hover {
  color: #e40012;
}
.anchor-nav__hashes {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 35px;
}
@media screen and (max-width: 1200px) {
  .anchor-nav {
    display: none;
  }
}
