.bike-main-info-component {
  color: #002c48;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}
.bike-main-info-component__offer-block {
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;
}
.bike-main-info-component__year-block {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.bike-main-info-component__price-block {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 20px;
}
.bike-main-info-component .price-block-label {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  align-items: center;
  padding-right: 20px;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px;
  align-self: stretch;
}
.bike-main-info-component .price-block-value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media screen and (max-width: 1100px) {
  .bike-main-info-component .price-block-value span p {
    font-size: 15px !important;
    color: #e40012;
  }
}
.bike-main-info-component .price-block-value * {
  font-family: inherit;
}
.bike-main-info-component .price-block-value span,
.bike-main-info-component .price-block-value h1,
.bike-main-info-component .price-block-value h2,
.bike-main-info-component .price-block-value h3,
.bike-main-info-component .price-block-value h4,
.bike-main-info-component .price-block-value h5,
.bike-main-info-component .price-block-value h6,
.bike-main-info-component .price-block-value p {
  margin-bottom: 0;
}
.bike-main-info-component .year-block-main-year {
  font-size: 60px;
  letter-spacing: -2px;
  font-weight: 900;
  position: relative;
  cursor: pointer;
  font-family: SuzukiPROHeadline;
}
.bike-main-info-component .year-block-main-year__new {
  position: absolute;
  top: -35px;
  left: 0;
  font-size: 20px;
  background-color: #004d9f;
  color: white;
  padding: 5px 20px;
  text-transform: uppercase;
  min-width: 140px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0;
  font-family: SuzukiPROHeadline;
}
.bike-main-info-component .year-block-main-year__new:after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  border-left: 15px solid #002c48;
  border-bottom: 15px solid transparent;
  position: absolute;
  bottom: -15px;
  left: 0;
}
.bike-main-info-component .year-block-other-year {
  color: #c0c4c7;
  font-size: 26px;
  font-weight: 900;
  padding-left: 20px;
  cursor: pointer;
  font-family: SuzukiPROHeadline;
}
.bike-main-info-component .year-block-other-year:hover {
  color: #004d9f;
}
.bike-main-info-component .offer-block-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bike-main-info-component .offer-block-item__tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #004d9f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  right: 0;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.bike-main-info-component .offer-block-item__tooltip:after {
  content: '';
  position: absolute;
  top: 100%;
  right: 5px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.bike-main-info-component .offer-block-item__image {
  width: auto;
  height: auto;
  max-height: 80px;
  max-width: 80px;
  margin-left: 20px;
  cursor: pointer;
}
.bike-main-info-component .offer-block-item__tooltip-icon {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.bike-main-info-component .offer-block-item__tooltip-icon:hover + .offer-block-item__tooltip {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 1200px) {
  .bike-main-info-component {
    padding: 0 20px;
  }
}
@media (max-width: 1100px) {
  .bike-main-info-component {
    flex-direction: column;
  }
  .bike-main-info-component__offer-block {
    margin-bottom: 20px;
    display: grid;
    grid-auto-columns: 33.33% auto;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
  }
  .bike-main-info-component .offer-block-item {
    margin-bottom: 20px;
    border-top: 1px solid rgba(0, 77, 159, 0.2);
    align-self: stretch;
    padding: 20px 10px 0;
  }
  .bike-main-info-component .offer-block-item__image {
    margin-left: 0;
  }
  .bike-main-info-component .offer-block-item__tooltip-icon {
    right: 10px;
    top: 10px;
  }
  .bike-main-info-component__price-block {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    margin-bottom: 20px;
    padding: 0 0px;
  }
  @supports (grid-template-rows: min-content) {
    .bike-main-info-component__price-block {
      grid-template-rows: min-content;
    }
  }
  .bike-main-info-component .price-block-label {
    grid-area: 1 / 1 / 2 / 2;
    font-family: SuzukiPROHeadline;
    font-size: 20px;
    border-top: 1px solid #004d9f;
    width: 100%;
    padding-top: 20px;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bike-main-info-component .price-block-label--new-year {
    padding-top: 60px;
  }
  .bike-main-info-component .price-block-value {
    grid-area: 1 / 2 / 2 / 3;
    border-top: 1px solid rgba(0, 77, 159, 0.2);
    padding-top: 20px;
    padding-left: 20px;
  }
  .bike-main-info-component .price-block-value span,
  .bike-main-info-component .price-block-value h1,
  .bike-main-info-component .price-block-value h2,
  .bike-main-info-component .price-block-value h3,
  .bike-main-info-component .price-block-value h4,
  .bike-main-info-component .price-block-value h5,
  .bike-main-info-component .price-block-value h6,
  .bike-main-info-component .price-block-value p {
    font-size: 30px !important;
  }
  .bike-main-info-component__year-block {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .bike-main-info-component__year-block .price-block-label {
    width: 33.33%;
  }
  .bike-main-info-component .year-block-main-year {
    font-size: 35px;
    letter-spacing: -2px;
    font-weight: 900;
    font-family: SuzukiPROHeadline;
    border-top: 1px solid rgba(0, 77, 159, 0.2);
    padding-left: 20px;
    padding-top: 20px;
    height: 100%;
  }
  .bike-main-info-component .year-block-main-year--new-year {
    padding-top: 60px;
  }
  .bike-main-info-component .year-block-main-year--new-year .year-block-main-year__new {
    top: 23px;
    left: 20px;
  }
  .bike-main-info-component .year-block-main-year__new {
    font-size: 16px;
    min-width: 110px;
  }
  .bike-main-info-component .year-block-other-year {
    font-size: 20px;
    padding-left: 20px;
    border-top: 1px solid rgba(0, 77, 159, 0.2);
    height: 100%;
    padding-top: 30px;
    position: relative;
    margin-top: -11px;
    top: 0;
  }
  .bike-main-info-component .year-block-other-year--new-year {
    padding-top: 70px;
  }
  .bike-main-info-component .year-block-other-year:hover {
    color: #c0c4c7;
  }
}
@media (max-width: 800px) {
  .bike-main-info-component__offer-block {
    padding: 0;
  }
  .bike-main-info-component__year-block {
    padding: 0;
  }
  .bike-main-info-component__price-block {
    padding: 0;
  }
}
