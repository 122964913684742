.custompage-view-cookie {
  margin: 0 auto;
  color: #002c48;
  max-height: 800px;
  max-width: 800px;
  overflow-y: auto;
  overflow-x: hidden;
}
.custompage-view-cookie h1 {
  font-family: SuzukiPROHeadline !important;
}
@media screen and (min-width: 1171px) {
  .custompage-view-cookie h1 {
    font-size: 1.66666667rem;
  }
}
@media screen and (max-width: 1170px) {
  .custompage-view-cookie h1 {
    font-size: 1.85714286rem;
  }
}
@media screen and (max-width: 992px) {
  .custompage-view-cookie h1 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 550px) {
  .custompage-view-cookie h1 {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 350px) {
  .custompage-view-cookie h1 {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 300px) {
  .custompage-view-cookie h1 {
    font-size: 2.85714286rem;
  }
}
.custompage-view-cookie h2 {
  font-family: SuzukiPROHeadline !important;
}
@media screen and (min-width: 1171px) {
  .custompage-view-cookie h2 {
    font-size: 1.11111111rem;
  }
}
@media screen and (max-width: 1170px) {
  .custompage-view-cookie h2 {
    font-size: 1.28571429rem;
  }
}
@media screen and (max-width: 992px) {
  .custompage-view-cookie h2 {
    font-size: 1.33333333rem;
  }
}
@media screen and (max-width: 550px) {
  .custompage-view-cookie h2 {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 350px) {
  .custompage-view-cookie h2 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 300px) {
  .custompage-view-cookie h2 {
    font-size: 1.71428571rem;
  }
}
.custompage-view-cookie p {
  font-family: SuzukiPRORegular !important;
}
@media screen and (min-width: 1171px) {
  .custompage-view-cookie p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1170px) {
  .custompage-view-cookie p {
    font-size: 1.14285714rem;
  }
}
@media screen and (max-width: 992px) {
  .custompage-view-cookie p {
    font-size: 1.16666667rem;
  }
}
@media screen and (max-width: 550px) {
  .custompage-view-cookie p {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 350px) {
  .custompage-view-cookie p {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 300px) {
  .custompage-view-cookie p {
    font-size: 1.71428571rem;
  }
}
.custompage-view-cookie img {
  max-width: 100%;
  height: auto;
}
.custompage-view-cookie a {
  color: #002c48;
  text-decoration: none;
}
@media screen and (min-width: 1171px) {
  .custompage-view-cookie a {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1170px) {
  .custompage-view-cookie a {
    font-size: 1.14285714rem;
  }
}
@media screen and (max-width: 992px) {
  .custompage-view-cookie a {
    font-size: 1.16666667rem;
  }
}
@media screen and (max-width: 550px) {
  .custompage-view-cookie a {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 350px) {
  .custompage-view-cookie a {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 300px) {
  .custompage-view-cookie a {
    font-size: 1.71428571rem;
  }
}
.custompage-view-cookie a:active,
.custompage-view-cookie a:hover,
.custompage-view-cookie a:focus {
  text-decoration: none;
}
.custompage-view-cookie__content {
  padding: 2rem 2rem 2rem 2rem;
  margin: 0 auto;
}
.custompage-view-cookie__content img {
  height: auto;
  max-width: 100%;
}
.custompage-view-cookie__content iframe {
  max-width: 100%;
  height: 450px;
}
@media screen and (max-width: 992px) {
  .custompage-view-cookie__content iframe {
    height: 350px;
  }
}
@media screen and (max-width: 500px) {
  .custompage-view-cookie__content iframe {
    height: 300px;
  }
}
