.wrapper {
  text-align: center;
  height: auto;
  width: 100%;
  font-size: 1rem;
  position: relative;
  box-sizing: border-box;
}
.wrapper-sm {
  min-height: 2rem;
  text-align: left;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.wrapper .input-description {
  display: block;
  margin-top: 0.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: left;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.wrapper .input-description-logic {
  padding-left: 3rem;
}
.wrapper-hidden {
  display: none !important;
}
.common-input {
  display: block;
  margin: 0;
  width: 100%;
  border-width: 0.2rem;
  border-style: solid;
  border-color: rgba(153, 153, 153, 0.205);
  box-sizing: border-box;
  font-size: 1.2rem;
  padding: 1rem 1.5rem;
  outline: none;
  position: relative;
  max-height: 4rem;
}
.common-input:focus {
  border-width: 0.2rem;
  border-style: solid;
  border-color: #005aab;
}
.valid-input {
  border: 0.2rem solid #003145 !important;
  outline: none;
}
.invalid-input {
  border: 0.2rem solid #da291c !important;
  outline: none;
}
.common-input:read-only {
  color: #888;
  cursor: pointer;
}
.common-input:disabled {
  color: #888;
  background-color: rgba(153, 153, 153, 0.205);
  border: 0.2rem solid rgba(153, 153, 153, 0.205);
  cursor: not-allowed;
}
.input-label {
  font-size: 1.5em;
  font-weight: 400;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  text-align: left;
  color: #163040;
}
.input-warning {
  color: #da291c;
  font-size: 1.2em;
  font-weight: 400;
  padding: 0.2rem 0 0.2rem 1rem;
  margin: 0;
  text-align: left;
}
.input-warning::after {
  content: none;
}
