@media print {
  .pdf-view {
    display: none;
  }
}
.pdf-view-wrapper {
  max-width: 1170px;
  margin: auto;
  padding: 20px 20px;
  background: white;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  overflow-x: scroll;
}
@media (max-width: 1170px) {
  .pdf-view-wrapper {
    max-width: 100%;
  }
}
.pdf-view div,
.pdf-view canvas {
  user-select: none;
}
