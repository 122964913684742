.iline-button {
  position: relative;
  letter-spacing: 0.25em;
  margin: 0 auto;
  background: transparent;
  outline: none;
  font-size: 28px;
  color: white;
  padding: 1rem 2.5rem;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  max-width: max-content;
  width: 100%;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.iline-button::after,
.iline-button::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 50%;
  transform: skewX(30deg);
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  z-index: 2;
}
.iline-button p {
  z-index: 3;
  margin: 0;
  text-align: center;
}
.iline-button::before {
  background-color: #004d9f;
  top: -0.5rem;
  left: 0rem;
}
.iline-button::after {
  background-color: #002c48;
  top: 0.5rem;
  left: 50%;
}
.iline-button:hover::before,
.iline-button:hover::after {
  top: 0;
  transform: skewx(0deg);
}
.iline-button:hover::after {
  left: 0rem;
}
.iline-button:hover::before {
  left: 50%;
}
@media screen and (max-width: 1200px) {
  .iline-button {
    max-width: 80%;
    margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
@media screen and (max-width: 350px) {
  .iline-button:after {
    left: 10rem;
  }
}
